import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
} from "@mui/material";
import { FaFilePdf } from "react-icons/fa";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MyStubs = () => {
  const { user } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [tableTitle, setTableTitle] = useState("");
  const [selectedPayRoll, setSelectedPayRoll] = useState(null);
  const [value, setValue] = useState(0);
  const [stubsData, setStubsData] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "none",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch authenticated user's data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      const email = user.email;

      if (!email) return;

      // Fetch user details
      const empQuery = query(
        collection(db, "empRegister"),
        where("AdEmail", "==", email)
      );
      const empSnapshot = await getDocs(empQuery);
      if (!empSnapshot.empty) {
        const userData = empSnapshot.docs[0].data();
        setSelectedEmployeeId(userData.EmpID);

        // Fetch property codes associated with the user
        const propCodes = [];
        empSnapshot.docs.forEach((doc) => {
          const propCode = doc.data().PropID;
          if (propCode && !propCodes.includes(propCode)) {
            propCodes.push(propCode);
          }
        });
        setPropertyCodes(propCodes);
        if (propCodes.length === 1) {
          setSelectedPropertyCode(propCodes[0]);
        }
      }
    };

    fetchUserData();
  }, [user]);

  // Fetch employee ID based on selected property code
  useEffect(() => {
    const fetchEmployeeId = async () => {
      if (!selectedPropertyCode || !user) return;
      const email = user.email;

      const empQuery = query(
        collection(db, "empRegister"),
        where("AdEmail", "==", email),
        where("PropID", "==", selectedPropertyCode)
      );
      const empSnapshot = await getDocs(empQuery);
      if (!empSnapshot.empty) {
        const userData = empSnapshot.docs[0].data();
        setSelectedEmployeeId(userData.EmpID);
      }
    };

    fetchEmployeeId();
  }, [selectedPropertyCode, user]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "payRoll"),
        where("PropertyCode", "==", selectedPropertyCode),
        orderBy("PeriodEndDate", "desc")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPayRollData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setPayRollData(fetchedPayRollData);
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode]);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (!selectedPropertyCode) return;
      const docRef = doc(db, "Properties", selectedPropertyCode);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setPropertyDetails({
          propertyName: data.propertyName,
          propEntity: data.propEntity,
          Ad1: data.Ad1,
          city: data.city,
          state: data.state,
          AdZip: data.AdZip,
        });
      } else {
        console.log("No such property!");
        setPropertyDetails({});
      }
    };

    fetchPropertyDetails();
  }, [selectedPropertyCode]);

  const getUniqueDates = (data) => {
    const uniqueDates = new Set();
    data.forEach((item) => {
      let endDate = new Date(item.PeriodEndDate.toDate());
      endDate.setDate(endDate.getDate() - 1); // Subtract one day from the end date
      uniqueDates.add(endDate.toISOString().split("T")[0]);
    });
    return Array.from(uniqueDates).sort((a, b) => new Date(b) - new Date(a)); // Sort descending
  };

  const [selectedDate, setSelectedDate] = useState("");
  const uniqueDates = getUniqueDates(payRollData);

  useEffect(() => {
    const dates = getUniqueDates(payRollData);
    if (dates.length > 0) {
      setSelectedDate(dates[0]);
    }
  }, [payRollData]);

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  useEffect(() => {
    if (!selectedPropertyCode || !selectedEmployeeId) return;

    // Fetch stubs for the selected employee without filtering by date
    const stubsQuery = query(
      collection(db, "STUBS"),
      where("EmployeeId", "==", selectedEmployeeId),
      where("PropertyCode", "==", selectedPropertyCode),
      orderBy("Date", "desc") // Optionally, keep ordering by date
    );

    const unsubscribe = onSnapshot(stubsQuery, (snapshot) => {
      const fetchedStubs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        PeriodOrOther: doc.data().Date === "other" ? "other" : doc.data().Date,
        DocString: `${doc.data().EmployeeId}_${
          doc.data().Date === "other" ? "other" : doc.data().Date
        }_${doc.id.split("_").pop()}`,
      }));
      console.log("All Fetched Stubs for Employee:", fetchedStubs);
      setStubsData(fetchedStubs);
    });

    return () => unsubscribe();
  }, [selectedPropertyCode, selectedEmployeeId, db]);

  useEffect(() => {
    let formattedDate = "";
    if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
      let periodEndDate = new Date(
        payRollData[currentPayRollIndex].PeriodEndDate?.toDate()
      );
      periodEndDate.setDate(periodEndDate.getDate() - 1); // subtract one day
      formattedDate = periodEndDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
    setTableTitle(`${selectedPropertyCode} PAYROLL - ${formattedDate}`);
  }, [selectedPropertyCode, currentPayRollIndex, payRollData]);

  useEffect(() => {
    if (
      payRollData.length > 0 &&
      currentPayRollIndex >= 0 &&
      currentPayRollIndex < payRollData.length
    ) {
      setSelectedPayRoll(payRollData[currentPayRollIndex]);
    }
  }, [currentPayRollIndex, payRollData]);

  function parseDateString(dateStr) {
    const [month, day, year] = dateStr.split("/");
    // Adjust year to full year format
    const fullYear = parseInt(year, 10) + 2000; // Adjust based on your context (e.g., `+ 2000` or `+ 1900`)
    return new Date(fullYear, month - 1, day);
  }

  stubsData.sort((a, b) => parseDateString(b.Date) - parseDateString(a.Date));

  const sortedStubsData = useMemo(() => {
    const stubsCopy = [...stubsData]; // Create a copy to avoid mutating original

    stubsCopy.sort((a, b) => {
      const dateA = new Date(a.Date);
      const dateB = new Date(b.Date);

      // Sort by date descending by default, or use sortConfig if available
      return sortConfig.key === "Date"
        ? sortConfig.direction === "ascending"
          ? dateA - dateB
          : dateB - dateA
        : dateB - dateA;
    });

    return stubsCopy;
  }, [stubsData, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  function formatDate(dateString) {
    // Parse the date and add one day
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add one day

    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear().toString().slice(-2); // Get last two digits of year

    // Format month and day to be two digits
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

  // Create styles
  const styles = StyleSheet.create({
    page: {
      padding: 9,
    },
    section: {
      marginLeft: 9,
      marginRight: 9,
      marginTop: 9,
      padding: 9,
      // flexGrow: 1,
    },
    header: {
      fontSize: 12,
      textAlign: "left",
      marginBottom: 1.8,
    },

    row: {
      flexDirection: "row",
      borderBottomWidth: 0.5,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottomWidth: 1,
      borderBottomColor: "#cccccc",
      borderBottomStyle: "solid",
      paddingVertical: 2,
    },
    tableHead: {
      fontWeight: "bold",
      fontSize: 10,
    },
    tableCol: { width: "7%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 1, fontSize: 9 },
    amountColumn: {
      width: "25%",
      textAlign: "right",
    },
  });

  // Helper function to organize hours and amounts
  const organizePayDetails = (data) => {
    const hours = [];
    const deductions = [];

    const excludeKeys = [
      "id",
      "Employee",
      "Amount",
      "Date",
      "PeriodEndDate",
      "Masked SS No",
      "Reference",
      "Gross",
      "Employee ID",
      "EmployeeId",
      "Pay Type",
      "EntryDate",
      "PeriodOrOther",
      "DocString",
      "PropertyCode",
      "propertyName",
      "propEntity",
      "PropertyCode",
      "Ad1",
      "city",
      "state",
      "AdZip",
      // Exclude any field that ends with "_C"
      ...Object.keys(data).filter((key) => key.endsWith("_C")),
    ];

    Object.keys(data).forEach((key) => {
      if (key.includes("Hrs") && !excludeKeys.includes(key)) {
        const amtKey = key.replace("Hrs", "Amt");
        if (data[amtKey] !== undefined) {
          // Ensure there's a corresponding amount key and it's not in the exclude list
          hours.push({ label: key, value: data[key], amtValue: data[amtKey] });
        }
      } else if (!key.includes("Amt") && !excludeKeys.includes(key)) {
        deductions.push({ label: key, value: data[key] });
      }
    });

    return { hours, deductions };
  };

  // Create Document Component
  const PayStubPDF = ({ employeeData }) => {
    const { hours, deductions } = organizePayDetails(employeeData);

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.header}>{employeeData.propertyName}</Text>
                <Text style={styles.tableCell}>{employeeData.propEntity}</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableCell}>{employeeData.Ad1}</Text>
                <Text style={styles.tableCell}>{employeeData.city},</Text>
                <Text style={styles.tableCell}>
                  {employeeData.state} {employeeData.AdZip}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Employee Name:</Text>
                <Text style={styles.tableCell}>{employeeData.Employee}</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Masked SSN:</Text>
                <Text style={styles.tableCell}>
                  {employeeData["Masked SS No"]}
                </Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Check Date:</Text>
                <Text style={styles.tableCell}>{employeeData.Date}</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Check Number:</Text>
                <Text style={styles.tableCell}>{employeeData.Reference}</Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Period Ending:</Text>
                <Text style={styles.tableCell}>
                  {employeeData.PeriodEndDate}
                </Text>
              </View>
            </View>
            {/* Render hours and amounts */}
            {hours.map((item) => (
              <View style={styles.tableRow}>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableHead}>{item.label}:</Text>
                  <Text style={styles.tableCell}>{item.value}</Text>
                </View>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableHead}>
                    {item.label.replace("Hrs", "Amt")}:
                  </Text>
                  <Text style={styles.tableCell}>{item.amtValue}</Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>Gross Amount</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableCell}>{employeeData.Gross}</Text>
              </View>
            </View>

            {/* Render deductions */}
            {deductions.map((deduction) => (
              <View style={styles.tableRow} key={deduction.label}>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableHead}>{deduction.label}:</Text>
                </View>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableCell}>
                    {typeof deduction.value === "number"
                      ? deduction.value.toFixed(2)
                      : deduction.value}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={styles.tableColWider}>
                <Text style={styles.tableHead}>CHECK TOTAL</Text>
              </View>
              <View style={styles.tableColWider}>
                <Text style={styles.tableCell}>${employeeData.Amount}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <div>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            sx={{
              marginRight: 9,
              letterSpacing: "0.5rem",
              fontWeight: "200",
              fontSize: "1.2rem",
              color: "#101010",
            }}
            variant="body1"
            align="left"
          >
            STUBS
          </Typography>
        </Grid>
        <Grid item>
          <FormControl
            sx={{
              minWidth: "15rem",
              "@media (max-width: 600px)": { minWidth: "9rem" },
            }}
          >
            <InputLabel
              htmlFor="property-code"
              sx={{
                fontSize: "0.7rem",
                fontWeight: 400,
                color: "#020202",
                transition: "0.3s",
              }}
            >
              PROPERTY CODE
            </InputLabel>
            <Select
              value={selectedPropertyCode}
              onChange={(event) => setSelectedPropertyCode(event.target.value)}
              inputProps={{ name: "property-code", id: "property-code" }}
              sx={{
                fontSize: "0.9rem",
                backgroundColor: "#ffffff",
                fontWeight: 800,
                borderRadius: "27px",
                letterSpacing: "0.09rem",
              }}
            >
              {propertyCodes.map((code) => (
                <MenuItem key={code} value={code} sx={{ fontSize: "0.9rem" }}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box height={16} />

      <Card sx={{ borderRadius: "18px" }}>
        <Box>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
            padding={2}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={maintitle200header} align="left">
                        EMPLOYEE
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                        onClick={() => requestSort("Amount")}
                      >
                        AMOUNT{" "}
                        {sortConfig.key === "Amount"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                        onClick={() => requestSort("Date")}
                      >
                        CHECK DATE{" "}
                        {sortConfig.key === "Date"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                        onClick={() => requestSort("PeriodEndDate")}
                      >
                        PERIOD END DATE{" "}
                        {sortConfig.key === "PeriodEndDate"
                          ? sortConfig.direction === "ascending"
                            ? "↑"
                            : "↓"
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="left"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedStubsData.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={maintable}
                          size="small"
                          align="left"
                        >
                          {row.Employee}
                        </TableCell>
                        <TableCell sx={maintable} size="small" align="left">
                          {row.Amount}
                        </TableCell>
                        <TableCell sx={maintable} size="small" align="left">
                          {row.Date}
                        </TableCell>
                        <TableCell sx={maintable} size="small" align="left">
                          {!row.PeriodEndDate ||
                          row.PeriodEndDate === "NaN/NaN/aN"
                            ? "OTHER"
                            : row.PeriodEndDate}
                        </TableCell>
                        <TableCell sx={maintable} size="small" align="left">
                          <PDFDownloadLink
                            document={<PayStubPDF employeeData={row} />}
                            fileName={`pay_stub_${
                              row.EmployeeId
                            }_${row.Date.replace(/[/]/g, "_")}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                "Loading document..."
                              ) : (
                                <FaFilePdf color="#FF0000" size="1.5em" />
                              )
                            }
                          </PDFDownloadLink>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  );
};

export default MyStubs;

const maintitle200header = {
  fontWeight: 800,
  fontSize: "0.9rem",
  backgroundColor: "#f5f5f5",
  color: "#020202",
  maxWidth: "3.6rem",
};
const maintable = {
  letterSpacing: "0.1rem",
  fontWeight: 400,
  fontSize: "0.81rem",
  backgroundColor: "#ffffff",
  color: "#101010",
  minWidth: "2.7rem",
};
