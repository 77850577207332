import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  onSnapshot,
  orderBy,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { MdEdit } from "react-icons/md";
import { getFunctions, httpsCallable } from "firebase/functions";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { FaRegIdBadge, FaFilePdf } from "react-icons/fa";
import { ImCoinDollar } from "react-icons/im";
import { TiBusinessCard } from "react-icons/ti";

const PunchSummary = () => {
  const { user } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [tableTitle, setTableTitle] = useState("");
  const [selectedPayRoll, setSelectedPayRoll] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [payCycle, setPayCycle] = useState("");
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [value, setValue] = React.useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "FirstName",
    direction: "asc",
  });
  const [averageRooms, setAverageRooms] = useState({});
  const [averageTimes, setAverageTimes] = useState({});
  const [filterStatus, setFilterStatus] = useState("Active");
  const [editID, setEditID] = useState(null);
  const [tempID, setTempID] = useState("");
  const [claims, setClaims] = useState({});

  const canViewRates =
    user.photoURL.includes("Global") ||
    user.photoURL.includes("Accounting") ||
    user.photoURL.includes("Regional") ||
    (claims[`${selectedPropertyCode} Employee Admin`] &&
      claims[`${selectedPropertyCode} View Pay Rates`]);

  const canEditPeachTreeID =
    user.photoURL.includes("Accounting") || user.photoURL.includes("Global");

  const canViewSalary =
    user.photoURL.includes("Global") ||
    user.photoURL.includes("Accounting") ||
    user.photoURL.includes("Regional");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (user && user.email) {
      const functions = getFunctions();
      const getUserClaims = httpsCallable(functions, "getUserClaims");
      getUserClaims({ email: user.email })
        .then((result) => {
          setClaims(result.data);
          console.log("Fetched claims:", result.data);
        })
        .catch((error) => {
          console.error("Error fetching user claims:", error);
        });
    }
  }, [user, user.email]);

  // USE EFFECT FOR PAYROLL SUMMARY
  useEffect(() => {
    let formattedDate = "";
    if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
      let periodEndDate = new Date(
        payRollData[currentPayRollIndex].PeriodEndDate?.toDate()
      );
      periodEndDate.setDate(periodEndDate.getDate() - 1); // subtract one day
      formattedDate = periodEndDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
    setTableTitle(`${selectedPropertyCode} PAYROLL - ${formattedDate}`);
  }, [selectedPropertyCode, currentPayRollIndex, payRollData]);

  useEffect(() => {
    if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
      setSelectedPayRoll(payRollData[currentPayRollIndex]);
    }
  }, [currentPayRollIndex, payRollData]);

  // USE EFFECT TO FETCH PROPERTY CODE
  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(
        propertiesRef,
        where("AdminEmail", "array-contains", email)
      );

      // Fetch based on roleEmail
      const querySnapshot1 = await getDocs(q1);
      const codes = [];
      querySnapshot1.forEach((doc) => {
        codes.push(doc.data().propCode);
      });

      // Fetch based on AdminEmail
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        // Avoid duplicates
        const propCode = doc.data().propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
        }
      });

      setPropertyCodes(codes);
    };

    fetchPropertyCodes();
  }, [user]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "empRegister"),
        where("PropID", "==", selectedPropertyCode)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const employees = snapshot.docs.map((doc) => ({
          id: doc.id,
          EmployeeId: doc.data().EmpID,
          FirstName: doc.data().NameFirst,
          LastName: doc.data().NameLast,
          Phone: doc.data().AdPhone,
          PayD1: doc.data().PayD1,
          PayD1_Rate: doc.data().PayD1_Rate,
          PayD1_Type: doc.data().PayD1_Type,
          PayD2: doc.data().PayD2,
          PayD2_Rate: doc.data().PayD2_Rate,
          PayD2_Type: doc.data().PayD2_Type,
          Status: doc.data().Status,
          PRR: doc.data().PRR,
          Email: doc.data().Email,
          peachTreeID: doc.data().peachTreeID,
          "W4-IRS": doc.data()["W4-IRS"],
          "W4-LOCAL": doc.data()["W4-LOCAL"],
          SSN: doc.data().SSN,
          ID: doc.data().ID,
          paySelection: doc.data().paySelection,
          health: doc.data().health,
        }));

        // Apply filtering for salary type visibility
        const filteredEmployees = employees.filter((employee) => {
          const isSalary =
            employee.PayD1_Type === "SALARY" ||
            employee.PayD2_Type === "SALARY";
          return !isSalary || (isSalary && canViewSalary);
        });

        setAllEmployees(filteredEmployees);
        setEmployeeList(filteredEmployees);

        // Set the name for the first employee if available
        if (filteredEmployees.length > 0) {
          setEmployeeName(
            `${filteredEmployees[0].FirstName} ${filteredEmployees[0].LastName}`
          );
        } else {
          setEmployeeName("");
        }
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode, canViewSalary]); // Include all relevant dependencies.

  const sortedEmployees = React.useMemo(() => {
    let sortableEmployees = [...allEmployees].filter(
      (employee) => employee.Status !== "Terminated"
    );
    if (sortConfig !== null) {
      sortableEmployees.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEmployees;
  }, [allEmployees, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const fetchProperties = async () => {
      if (!selectedPropertyCode) return;

      const propertiesDoc = await getDoc(
        doc(db, "Properties", selectedPropertyCode)
      );
      const { payPeriod, payCycle } = propertiesDoc.data();
      const currentDate = new Date();
      let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
      let payPeriodEndDate;

      setPayCycle(payCycle);

      switch (payCycle) {
        case "Monthly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setMonth(payPeriodEndDate.getMonth() + 1);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Bi-Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 14);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 7);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        default:
          break;
      }

      setPayPeriodStart(payPeriodStartDate);
      setPayPeriodEnd(payPeriodEndDate);
    };

    fetchProperties();
  }, [selectedPropertyCode]);

  // useEffect(() => {
  //   if (selectedPropertyCode) {
  //     const q = query(
  //       collection(db, "empRegister"),
  //       where("PropID", "==", selectedPropertyCode)
  //     );
  //     const unsubscribe = onSnapshot(q, (snapshot) => {
  //       const employees = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         EmployeeId: doc.data().EmpID,
  //         FirstName: doc.data().NameFirst,
  //         LastName: doc.data().NameLast,
  //         peachTreeID: doc.data().peachTreeID,
  //       }));

  //       setEmployeeList(employees);

  //       if (employees.length > 0) {
  //         setEmployeeName(`${employees[0].FirstName} ${employees[0].LastName}`);
  //       } else {
  //         setEmployeeName("");
  //       }
  //     });

  //     return unsubscribe;
  //   }
  // }, [selectedPropertyCode]);

  useEffect(() => {
    if (payPeriodStart && payPeriodEnd) {
      const q = query(
        collection(db, "PUNCHES"),
        where("PropCode", "==", selectedPropertyCode)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setEmployees([]);

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          const inTime = data.InTime.toDate();
          if (inTime >= payPeriodStart && inTime <= payPeriodEnd) {
            setEmployees((prevEmployees) => {
              const existingEmployee = prevEmployees.find(
                (employee) => employee.id === doc.id
              );

              if (existingEmployee) {
                return prevEmployees.map((employee) =>
                  employee.id === doc.id
                    ? {
                        id: doc.id,
                        ...data,
                        isEditable:
                          data.Status === "Open" ||
                          data.Status === "Manual" ||
                          data.Status === "Modified",
                      }
                    : employee
                );
              } else {
                return [
                  ...prevEmployees,
                  {
                    id: doc.id,
                    ...data,
                    isEditable:
                      data.Status === "Open" ||
                      data.Status === "Manual" ||
                      data.Status === "Modified",
                  },
                ];
              }
            });
          }
        });
      });

      return unsubscribe;
    }
  }, [user, payPeriodStart, payPeriodEnd, selectedPropertyCode]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "payRoll"),
        where("PropertyCode", "==", selectedPropertyCode),
        orderBy("PeriodEndDate", "desc")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPayRollData = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        // Sort the fetched data alphabetically by FirstName
        fetchedPayRollData.sort((a, b) => {
          if (a.FirstName < b.FirstName) {
            return -1;
          }
          if (a.FirstName > b.FirstName) {
            return 1;
          }
          return 0;
        });

        setPayRollData(fetchedPayRollData);

        // Calculate average "Time" value and "Rooms" value for each employee
        let averageTimes = {};
        let averageRooms = {};
        const currentYear = new Date().getFullYear();
        fetchedPayRollData.forEach((doc) => {
          if (
            doc.PeriodEndDate?.toDate().getFullYear() === currentYear &&
            doc.Status !== "Void"
          ) {
            // Calculate average time
            const timeParts = doc.Totals?.Time.split(" ");
            const hours = timeParts[0] ? parseInt(timeParts[0]) : 0;
            const minutes = timeParts[2] ? parseInt(timeParts[2]) : 0;
            const totalMinutes = hours * 60 + minutes;

            if (doc.EmployeeId in averageTimes) {
              averageTimes[doc.EmployeeId].push(totalMinutes);
            } else {
              averageTimes[doc.EmployeeId] = [totalMinutes];
            }

            // Calculate average rooms
            const rooms = doc.Totals?.Rooms;
            if (doc.EmployeeId in averageRooms) {
              averageRooms[doc.EmployeeId].push(rooms);
            } else {
              averageRooms[doc.EmployeeId] = [rooms];
            }
          }
        });

        // Compute the average and convert back to "Hh Mm" format for time
        for (let employeeId in averageTimes) {
          const averageMinutes =
            averageTimes[employeeId].reduce((a, b) => a + b, 0) /
            averageTimes[employeeId].length;
          const averageHours = Math.floor(averageMinutes / 60);
          const remainingMinutes = Math.round(averageMinutes % 60);
          averageTimes[employeeId] = `${averageHours}h ${remainingMinutes}m`;
        }

        // Compute the average for rooms
        for (let employeeId in averageRooms) {
          const average =
            averageRooms[employeeId].reduce((a, b) => a + b, 0) /
            averageRooms[employeeId].length;
          averageRooms[employeeId] = Math.round(average);
        }

        setAverageTimes(averageTimes);
        setAverageRooms(averageRooms);
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const querySnapshot = await getDocs(collection(db, "empRegister"));
      const employeesData = [];

      querySnapshot.forEach((doc) => {
        employeesData.push({
          id: doc.id,
          ...doc.data(),
          PR2: doc.data().PR2,
          PR3: doc.data().PR3,
          PayD1_Rate: doc.data().PayD1_Rate,
        });
      });

      setEmployeeData(employeesData);

      const departments = employeesData.map((employee) => [
        employee.PayD1,
        employee.PayD2,
      ]);

      setDepartments(departments);
    };

    fetchEmployeeData();
  }, []);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // PDF STYLESHEET
  const styles = StyleSheet.create({
    page: { flexDirection: "row", backgroundColor: "#ffffff", padding: 9 },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 0.5,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableCol: { width: "7%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 3, fontSize: 9 },
    strikethrough: {
      textDecoration: "line-through",
    },
    tableHead: { margin: 3, fontSize: 11, fontWeight: "bold" },
  });

  // ROSTER EXPORT:
  const EmployeeRosterPDF = ({ employees }) => (
    <View style={styles.section}>
      <Text>{`${new Date().toLocaleDateString(
        "en-US"
      )} - Employee Roster (${selectedPropertyCode})`}</Text>

      <View style={styles.section}>
        <View style={styles.tableRow}>
          {/* Add headers here */}
          <View style={styles.tableCol}>
            <Text style={styles.tableHead}>ID</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>NAME</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>LAST</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHead}>AVG HRS</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHead}>AVG RMS</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>PHONE</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>D1</Text>
          </View>
          <View style={styles.tableColWider}>
            <Text style={styles.tableHead}>D2</Text>
          </View>
        </View>

        {employees.map((employee, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{employee.EmployeeId}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.FirstName}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.LastName}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {averageTimes[employee.EmployeeId]}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {averageRooms[employee.EmployeeId]}
              </Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.Phone}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.PayD1}</Text>
            </View>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>{employee.PayD2}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );

  const MyDocument3 = ({ employees }) => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <EmployeeRosterPDF employees={employees} />
      </Page>
    </Document>
  );

  const handlePropertyCodeChange = (event) => {
    setSelectedPropertyCode(event.target.value);
  };

  let calculatedDate;
  if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
    let endDate = payRollData[currentPayRollIndex].PeriodEndDate?.toDate();
    endDate.setDate(endDate.getDate() - 1); // subtract one day
    calculatedDate = endDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  const handleEdit = (employee) => {
    setEditID(employee.id);
    setTempID(employee.peachTreeID || "");
  };

  const handleCancel = () => {
    setEditID(null);
    setTempID("");
  };

  const handleSave = async (employee) => {
    const employeeRef = doc(db, "empRegister", employee.id);
    try {
      await updateDoc(employeeRef, { peachTreeID: tempID });
      setEditID(null);
      setTempID("");
      // Optionally show a success message
    } catch (error) {
      // Handle the error, e.g., show an error message
      console.error("Error updating document: ", error);
    }
  };

  return (
    <div>
      {/* HEADER */}
      <Grid
        container
        alignItems="center"
        sx={{
          padding: "2rem",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            paddingTop: { xs: "1rem", sm: 0 },
            paddingBottom: { xs: "1rem", sm: 0 },
          }}
        >
          <Typography
            sx={{
              ...sharedTextStyles,
              marginRight: 4.5,
              letterSpacing: "0.45rem",
              fontWeight: "300",
              fontSize: "1.71rem",
              color: "#202426",
            }}
            variant="body1"
            align="left"
          >
            ROSTER
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: { xs: "1rem", sm: 0 },
            paddingBottom: { xs: "1rem", sm: 0 },
          }}
        >
          <FormControl
            sx={{
              minWidth: "18rem",
              "@media (max-width: 600px)": {
                minWidth: "12rem",
              },
            }}
          >
            <InputLabel
              htmlFor="property-code"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "#101010",
                marginBottom: "0.2rem",
                transition: "0.3s",
              }}
            >
              PROPERTY CODE
            </InputLabel>
            <Select
              value={selectedPropertyCode}
              onChange={handlePropertyCodeChange}
              inputProps={{
                name: "property-code",
                id: "property-code",
              }}
              sx={{
                fontSize: "0.9rem",
                backgroundColor: "#fcfcfc",
                minWidth: "10em",
                borderRadius: "27px",
                fontWeight: "800",
              }}
            >
              {propertyCodes.map((code) => (
                <MenuItem key={code} value={code} sx={{ fontSize: "0.9rem" }}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={filterStatus === "Active"}
                onChange={(e) =>
                  setFilterStatus(e.target.checked ? "Active" : "Terminated")
                }
                name="employeeStatusSwitch"
                color="default"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    "&.Mui-checked": {
                      color: "#74c365",
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#74c365",
                      },
                    },
                    "&.Mui-checked:hover": {
                      backgroundColor: "rgba(249, 139, 133, 0.08)",
                    },
                    "&.Mui-checked .MuiSwitch-thumb": {
                      backgroundColor: "#74c365",
                    },
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "#F98B85",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundSize: "cover",
                      },
                    },
                  },
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#F98B85",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundSize: "cover",
                    },
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#F98B85",
                  },
                }}
              />
            }
            label={filterStatus === "Active" ? "ACTIVE EMPLOYEES" : "INACTIVE"}
            labelPlacement="top"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "0.9rem",
                fontWeight: 400,
                color: "#010101",
                transform: "translateY(-5px)", // Adjust label position to be closer to the switch
              },
            }}
          />
        </Grid>
      </Grid>
      <Box height={16} />

      <Card
        sx={{
          borderRadius: "18px",
        }}
      >
        {/* ROSTER */}

        <Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
            // sx={{ borderBottom: "0.5px solid black" }}
          >
            <Box
              sx={{
                marginLeft: "18px",
                marginTop: "36px",
              }}
            >
              <Grid item>
                <PDFDownloadLink
                  document={<MyDocument3 employees={sortedEmployees} />}
                  fileName={`${new Date().toLocaleDateString(
                    "en-US"
                  )}_Employee_Roster_${selectedPropertyCode}.pdf`}
                >
                  {() => (
                    <IconButton
                      sx={{
                        fontSize: "1.5rem",
                        color: "#FF0000",
                        backgroundColor: "transparent",
                      }}
                    >
                      <FaFilePdf />
                    </IconButton>
                  )}
                </PDFDownloadLink>
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontSize: "0.72rem",
                    color: "#010101",
                  }}
                  variant="caption"
                >
                  EXPORT TO PDF
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <TableContainer
            sx={{
              overflowX: "auto",
            }}
            component={Paper}
          >
            <Table id="ROSTER" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={totals}>
                    ID
                  </TableCell>

                  <TableCell align="left" sx={totals}>
                    <TableSortLabel
                      active={sortConfig && sortConfig.key === "FirstName"}
                      direction={
                        sortConfig && sortConfig.key === "FirstName"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => requestSort("FirstName")}
                    >
                      NAME
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    <TableSortLabel
                      active={sortConfig && sortConfig.key === "LastName"}
                      direction={
                        sortConfig && sortConfig.key === "LastName"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => requestSort("LastName")}
                    >
                      LAST
                    </TableSortLabel>
                  </TableCell>
                  {canViewRates && (
                    <>
                      <TableCell align="center" sx={totals}>
                        DOCS
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left" sx={totals}>
                    AVG HRS
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    AVG RMS
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    PHONE
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    EMAIL
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    <TableSortLabel
                      active={sortConfig && sortConfig.key === "PayD1"}
                      direction={
                        sortConfig && sortConfig.key === "PayD1"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => requestSort("PayD1")}
                    >
                      D1
                    </TableSortLabel>
                  </TableCell>
                  {canViewRates && (
                    <>
                      <TableCell align="left" sx={totals}>
                        D1 RATE
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left" sx={totals}>
                    D1 TYPE
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    D2
                  </TableCell>
                  {canViewRates && (
                    <>
                      <TableCell align="left" sx={totals}>
                        D2 RATE
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left" sx={totals}>
                    D2 TYPE
                  </TableCell>
                  {canViewRates && (
                    <>
                      <TableCell align="left" sx={totals}>
                        PAY RATE REVIEW
                      </TableCell>
                    </>
                  )}
                  {canEditPeachTreeID && (
                    <>
                      <TableCell align="left" sx={totals}>
                        PAYROLL ID
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allEmployees
                  .filter((employee) => {
                    if (filterStatus === "Active")
                      return employee.Status !== "Terminated";
                    return employee.Status === filterStatus;
                  })
                  .sort((a, b) => {
                    if (sortConfig === null) {
                      return 0;
                    }
                    if (
                      sortConfig.key === "FirstName" ||
                      sortConfig.key === "LastName" ||
                      sortConfig.key === "PayD1"
                    ) {
                      if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === "asc" ? -1 : 1;
                      }
                      if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === "asc" ? 1 : -1;
                      }
                    }
                    return 0;
                  })
                  .map((employee) => (
                    <TableRow hover key={employee.id}>
                      <TableCell sx={maintable} size="small" align="left">
                        {employee.EmployeeId}
                      </TableCell>
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.FirstName}
                      </TableCell>
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.LastName}
                      </TableCell>
                      {
                        <TableCell sx={maintable} align="center" size="small">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ borderRadius: "9px", overflow: "hidden" }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["W4-IRS"]
                                    ? "#E0E0E0"
                                    : employee["W4-IRS"] === "APPROVED"
                                    ? "#c7d57f"
                                    : employee["W4-IRS"] === "SUBMITTED"
                                    ? "#FFC107"
                                    : employee["W4-IRS"] === "REJECTED"
                                    ? "#F98B85"
                                    : "#E0E0E0"
                                }
                                height="40px"
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "0.81rem",
                                    color: "#202426",
                                  }}
                                >
                                  W4
                                </Typography>
                              </Box>
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["SSN"]
                                    ? "#E0E0E0"
                                    : employee["SSN"] === "APPROVED"
                                    ? "#c7d57f"
                                    : employee["SSN"] === "SUBMITTED"
                                    ? "#FFC107"
                                    : employee["SSN"] === "REJECTED"
                                    ? "#F98B85"
                                    : "#E0E0E0"
                                }
                                height="40px"
                              >
                                <Tooltip title={"SSN"}>
                                  <span>
                                    <IconButton sx={{ color: "#202426" }}>
                                      <TiBusinessCard />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>

                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["paySelection"]
                                    ? "#E0E0E0"
                                    : employee["paySelection"] === "APPROVED"
                                    ? "#c7d57f"
                                    : employee["paySelection"] === "SUBMITTED"
                                    ? "#FFC107"
                                    : employee["paySelection"] === "REJECTED"
                                    ? "#F98B85"
                                    : "#E0E0E0"
                                }
                                height="40px"
                              >
                                <Tooltip title={"Pay Selection"}>
                                  <span>
                                    <IconButton sx={{ color: "#202426" }}>
                                      <ImCoinDollar />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["W4-LOCAL"]
                                    ? "#E0E0E0"
                                    : employee["W4-LOCAL"] === "APPROVED"
                                    ? "#c7d57f"
                                    : employee["W4-LOCAL"] === "SUBMITTED"
                                    ? "#FFC107"
                                    : employee["W4-LOCAL"] === "REJECTED"
                                    ? "#F98B85"
                                    : "#E0E0E0"
                                }
                                height="40px"
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "0.81rem",
                                    color: "#202426",
                                  }}
                                >
                                  W4L
                                </Typography>
                              </Box>
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["ID"]
                                    ? "#E0E0E0"
                                    : employee["ID"] === "APPROVED"
                                    ? "#c7d57f"
                                    : employee["ID"] === "SUBMITTED"
                                    ? "#FFC107"
                                    : employee["ID"] === "REJECTED"
                                    ? "#F98B85"
                                    : "#E0E0E0"
                                }
                                height="40px"
                              >
                                <Tooltip title={"Identification"}>
                                  <span>
                                    <IconButton sx={{ color: "#202426" }}>
                                      <FaRegIdBadge />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                              <Box
                                width="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minWidth="1.8rem"
                                bgcolor={
                                  !employee["health"]
                                    ? "#E0E0E0"
                                    : employee["health"] === "APPROVED"
                                    ? "#c7d57f"
                                    : employee["health"] === "SUBMITTED"
                                    ? "#FFC107"
                                    : employee["health"] === "REJECTED"
                                    ? "#F98B85"
                                    : "#E0E0E0"
                                }
                                height="40px"
                              >
                                <Tooltip title={"Health Insurance"}>
                                  <span>
                                    <IconButton sx={{ color: "#202426" }}>
                                      <MdOutlineHealthAndSafety />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                      }

                      <TableCell sx={maintable} align="left" size="small">
                        {averageTimes[employee.EmployeeId]}
                      </TableCell>
                      <TableCell sx={maintable} align="left" size="small">
                        {averageRooms[employee.EmployeeId]}
                      </TableCell>
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.Phone}
                      </TableCell>
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.Email}
                      </TableCell>
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.PayD1}
                      </TableCell>
                      {canViewRates && (
                        <>
                          <TableCell sx={maintable} align="left" size="small">
                            {employee.PayD1_Rate}
                          </TableCell>
                        </>
                      )}
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.PayD1_Type}
                      </TableCell>
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.PayD2}
                      </TableCell>
                      {canViewRates && (
                        <>
                          <TableCell sx={maintable} align="left" size="small">
                            {employee.PayD2_Rate}
                          </TableCell>
                        </>
                      )}
                      <TableCell sx={maintable} align="left" size="small">
                        {employee.PayD2_Type}
                      </TableCell>
                      {canViewRates && (
                        <>
                          <TableCell sx={maintable} align="left" size="small">
                            {employee.PRR && !isNaN(new Date(employee.PRR))
                              ? new Date(employee.PRR).toLocaleDateString(
                                  "en-US"
                                )
                              : ""}
                          </TableCell>
                        </>
                      )}
                      {canEditPeachTreeID && (
                        <>
                          {editID === employee.id && canEditPeachTreeID ? (
                            <TableCell sx={maintable} align="left" size="small">
                              <TextField
                                value={tempID}
                                onChange={(e) => setTempID(e.target.value)}
                                size="small"
                              />
                              <Button onClick={() => handleSave(employee)}>
                                Save
                              </Button>
                              <Button onClick={handleCancel}>Cancel</Button>
                            </TableCell>
                          ) : (
                            <TableCell sx={maintable} align="left" size="small">
                              {employee.peachTreeID || "N/A"}
                              <IconButton onClick={() => handleEdit(employee)}>
                                <MdEdit />
                              </IconButton>
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box height={16} />
      </Card>
    </div>
  );
};

export default PunchSummary;

const maintable = {
  fontWeight: 400,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
  rowHeight: "0.1rem",
};
const totals = {
  fontWeight: 600,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
};

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};
