import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  IconButton,
  Box,
  Card,
  CardContent,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { auth, storage } from "../../firebase/firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  writeBatch,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { deleteObject } from "firebase/storage";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase/firebase-config";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { SiFacepunch } from "react-icons/si";
import { SlSpeech } from "react-icons/sl";
import { TfiClose } from "react-icons/tfi";

export default function ASL() {
  const { user, dispatch } = useAuth();
  const [personalData, setPersonalData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [profilePictureDialogOpen, setProfilePictureDialogOpen] =
    useState(false);
  const [enrollDialogOpen, setEnrollDialogOpen] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [timeclockStatus, setTimeclockStatus] = useState({});
  const [newEmail, setNewEmail] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [language, setLanguage] = useState("en");
  const [myDocumentsDialogOpen, setMyDocumentsDialogOpen] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isFetchingProperties, setIsFetchingProperties] = useState(false);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const { timeClockStatus } = useAuth();
  const [emailPhoneDialogOpen, setEmailPhoneDialogOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  useEffect(() => {
    const fetchStatus = async () => {
      if (personalData && personalData.AdPhone) {
        // Check if personalData and AdPhone exist
        const phoneAuthUserDocRef = doc(
          db,
          "phoneAuthUsers",
          personalData.AdPhone
        );
        const docSnapshot = await getDoc(phoneAuthUserDocRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setStatus(data.Status);
        }
      }
    };

    fetchStatus();
  }, [personalData]);

  const logout = useCallback(() => {
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOGOUT" });
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    const fetchPersonalData = async (
      user,
      setPersonalData,
      setProfilePictureURL,
      setPropertyCodes,
      setTimeclockStatus
    ) => {
      if (!user) return;

      const empRef = collection(db, "empRegister");
      const q = query(empRef, where("AdEmail", "==", user.email));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const firstRecord = snapshot.docs[0].data();
        setPersonalData(firstRecord);
        // Fetch profile picture URL and property codes from phoneAuthUsers
        const phoneAuthUserDoc = await getDoc(
          doc(db, "phoneAuthUsers", firstRecord.AdPhone)
        );
        if (phoneAuthUserDoc.exists()) {
          const data = phoneAuthUserDoc.data();
          setProfilePictureURL(data.profilePictureURL || "");
          setPropertyCodes(data.propID || []);
          if (data.phoneChangeDate) {
            setPersonalData((prevData) => ({
              ...prevData,
              phoneChangeDate: data.phoneChangeDate,
            }));
          }

          // Fetch timeclock status for each property code
          const timeclockStatus = {};
          for (const code of data.propID || []) {
            const empData = await getDocs(
              query(
                empRef,
                where("AdPhone", "==", firstRecord.AdPhone),
                where("PropID", "==", code)
              )
            );
            if (!empData.empty) {
              const empDoc = empData.docs[0].data();
              timeclockStatus[code] = empDoc.timeclock || null;
            }
          }
          setTimeclockStatus(timeclockStatus);
        }
      }
    };

    if (user) {
      fetchPersonalData(
        user,
        setPersonalData,
        setProfilePictureURL,
        setPropertyCodes,
        setTimeclockStatus
      );
    }
  }, [user]);

  const handleEnrollButtonClick = (code) => {
    setSelectedPropertyCode(code);
    setShowTerms(true);
  };

  const fetchPayPeriodEnd = async (propertyCode) => {
    if (!propertyCode || !user) return null;

    const propertiesDoc = await getDoc(doc(db, "Properties", propertyCode));
    const { payPeriod, payCycle } = propertiesDoc.data();
    const currentDate = new Date();
    let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
    let payPeriodEndDate;

    const calculatePayPeriod = (start, interval) => {
      const end = new Date(start);
      end.setDate(start.getDate() + interval);
      return end;
    };

    switch (payCycle) {
      case "Monthly":
        while (payPeriodStartDate < currentDate) {
          payPeriodEndDate = calculatePayPeriod(payPeriodStartDate, 30);
          if (payPeriodEndDate > currentDate) break;
          payPeriodStartDate = new Date(payPeriodEndDate);
        }
        break;
      case "Bi-Weekly":
        while (payPeriodStartDate < currentDate) {
          payPeriodEndDate = calculatePayPeriod(payPeriodStartDate, 14);
          if (payPeriodEndDate > currentDate) break;
          payPeriodStartDate = new Date(payPeriodEndDate);
        }
        break;
      case "Weekly":
        while (payPeriodStartDate < currentDate) {
          payPeriodEndDate = calculatePayPeriod(payPeriodStartDate, 7);
          if (payPeriodEndDate > currentDate) break;
          payPeriodStartDate = new Date(payPeriodEndDate);
        }
        break;
      default:
        break;
    }

    return payPeriodEndDate;
  };

  const subtractOneDay = (date) => {
    const dateObj = new Date(date);

    if (isNaN(dateObj)) {
      throw new Error("Invalid date format");
    }

    dateObj.setDate(dateObj.getDate() - 1);

    const newMonth = String(dateObj.getMonth() + 1).padStart(2, "0");
    const newDay = String(dateObj.getDate()).padStart(2, "0");
    const newYear = dateObj.getFullYear();

    return `${newMonth}/${newDay}/${newYear}`;
  };

  const handleEmailChange = async () => {
    setIsSubmitting(true);

    const q = query(
      collection(db, "empRegister"),
      where("AdEmail", "==", user.email)
    );
    const snapshot = await getDocs(q);

    const batch = writeBatch(db);

    snapshot.forEach((docSnapshot) => {
      const docRef = doc(db, "empRegister", docSnapshot.id);
      batch.update(docRef, { Email: newEmail });
    });

    await batch.commit();

    setPersonalData((prevData) => ({ ...prevData, Email: newEmail }));
    setNewEmail("");
    setEmailDialogOpen(false);
    setIsSubmitting(false);
    alert("Email updated successfully!");
  };

  const handlePhoneChange = async () => {
    setIsSubmitting(true);

    const oldPhoneNumber = personalData.AdPhone;
    const currentDate = new Date().toISOString();

    try {
      // Check if a document with the new phone number already exists
      const newUserDocRef = doc(db, "phoneAuthUsers", newPhoneNumber);
      const newUserDoc = await getDoc(newUserDocRef);

      if (newUserDoc.exists()) {
        alert(
          "This phone number is already in use. Please use a different number."
        );
        setIsSubmitting(false);
        return;
      }

      // Proceed with the function if the new phone number is not in use
      const empQuery = query(
        collection(db, "empRegister"),
        where("AdPhone", "==", oldPhoneNumber)
      );
      const empSnapshot = await getDocs(empQuery);
      const batch = writeBatch(db);

      empSnapshot.forEach((docSnapshot) => {
        const docRef = doc(db, "empRegister", docSnapshot.id);
        batch.update(docRef, {
          AdPhone: newPhoneNumber,
          AdEmail: "", // Set AdEmail to blank strings
        });
      });

      await batch.commit();

      // Update phoneAuthUsers collection
      const oldUserDocRef = doc(db, "phoneAuthUsers", oldPhoneNumber);
      const oldUserDoc = await getDoc(oldUserDocRef);
      if (oldUserDoc.exists()) {
        const userData = oldUserDoc.data();
        const updatedData = {
          ...userData,
          phoneNumber: newPhoneNumber,
          profilePictureURL: profilePictureURL, // Store the profile picture URL
          phoneChangeDate: currentDate, // Record the date of the change
        };
        await setDoc(newUserDocRef, updatedData);
        await deleteDoc(oldUserDocRef);

        // Send an axios post request with the necessary information
        const apiGatewayUrl =
          "https://prod-55.westus.logic.azure.com:443/workflows/bb84288c70994588a9c17e1ca12f6cd6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=UqBgANaK9RdtLIRKmhzOZiicFF-A56rXvCqyYDu39zY";
        await axios.post(apiGatewayUrl, {
          oldPhoneNumber: oldPhoneNumber,
          newPhoneNumber: newPhoneNumber,
          empIDs: userData.empID,
          phoneChangeDate: updatedData.phoneChangeDate,
        });

        // Add notes to the empRegister collection
        for (const empID of userData.empID) {
          const empDocRef = doc(db, "empRegister", empID);
          const empDoc = await getDoc(empDocRef);

          if (empDoc.exists()) {
            const empData = empDoc.data();
            const notes = empData.Notes || [];
            notes.push(
              `User phone number change on ${currentDate}: ${oldPhoneNumber} > ${newPhoneNumber}`
            );

            await updateDoc(empDocRef, { Notes: notes });
          }
        }
      }

      // Inform the user
      alert(
        "Phone number updated successfully! Please re-login with your new phone number."
      );

      // Log out the user
      await logout();
    } catch (error) {
      console.error("Error updating phone number:", error);
      alert(
        "An error occurred while updating the phone number. Please try again."
      );
    } finally {
      setIsSubmitting(false);
      setPhoneDialogOpen(false);
    }
  };

  // Function to check if the phone number change button should be disabled
  const isPhoneChangeDisabled = () => {
    if (!personalData.phoneChangeDate) return false;
    const lastChangeDate = new Date(personalData.phoneChangeDate);
    const currentDate = new Date();
    const differenceInDays = Math.floor(
      (currentDate - lastChangeDate) / (1000 * 60 * 60 * 24)
    );
    return differenceInDays < 10;
  };

  const handlePhoneInputChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 0 && !value.startsWith("1")) {
      value = "1" + value;
    }

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    setNewPhoneNumber(value);
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleProfilePictureUpload = async () => {
    if (profilePicture) {
      setIsSubmitting(true);
      setSubmissionMessage("Uploading profile picture...");
      setSubmissionDialogOpen(true);

      const oldProfilePictureURL = profilePictureURL;

      try {
        // Delete old profile picture if exists
        if (oldProfilePictureURL) {
          const oldStorageRef = ref(storage, oldProfilePictureURL);
          await deleteObject(oldStorageRef);
        }

        // Upload new profile picture
        const storageRef = ref(storage, `profilePictures/${user.uid}`);
        await uploadBytes(storageRef, profilePicture);
        const downloadURL = await getDownloadURL(storageRef);
        setProfilePictureURL(downloadURL);

        // Update profile picture URL and status in phoneAuthUsers document
        const phoneAuthUserDocRef = doc(
          db,
          "phoneAuthUsers",
          personalData.AdPhone
        );
        await updateDoc(phoneAuthUserDocRef, {
          profilePictureURL: downloadURL,
          Status: "1",
        });

        setIsSubmitting(false);
        setProfilePictureDialogOpen(false);
        setSubmissionDialogOpen(false);
        alert("Profile picture updated successfully!");
      } catch (error) {
        console.error("Error updating profile picture:", error);
        setIsSubmitting(false);
        setProfilePictureDialogOpen(false);
        setSubmissionDialogOpen(false);
        alert("Failed to update profile picture. Please try again.");
      }
    }
  };

  const handleEnroll = async (propertyCode) => {
    setIsSubmitting(true);
    setSubmissionMessage("Enrolling in timeclock...");
    setSubmissionDialogOpen(true);

    try {
      // Fetch the phoneAuthUsers document to get the EmpID array
      const phoneAuthUserDocRef = doc(
        db,
        "phoneAuthUsers",
        personalData.AdPhone
      );
      const phoneAuthUserDoc = await getDoc(phoneAuthUserDocRef);

      if (!phoneAuthUserDoc.exists()) {
        throw new Error("phoneAuthUsers document not found");
      }

      const phoneAuthUserData = phoneAuthUserDoc.data();
      const empIDs = phoneAuthUserData.empID || [];
      const propIDs = phoneAuthUserData.propID || [];

      // Find the position of the given PropID
      const propIndex = propIDs.indexOf(propertyCode);
      if (propIndex === -1) {
        throw new Error("Property code not found in propID array");
      }

      // Get the corresponding EmpID from the EmpID array
      const empID = empIDs[propIndex];
      if (!empID) {
        throw new Error("EmpID not found for the given property code");
      }

      const apiGatewayUrl =
        "https://prod-109.westus.logic.azure.com:443/workflows/ec9d5d848673494180f4449c67cc5997/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OJIYzNHeNvi640kGDnTODIxw6201zT5UxyzYivy7-Yc";
      await axios.post(apiGatewayUrl, {
        body: JSON.stringify({
          url: profilePictureURL,
          email: empID, // Send EmpID instead of user.email
          propID: propertyCode,
        }),
      });

      alert(`Enrolled in timeclock for property code: ${propertyCode}`);
    } catch (error) {
      console.error("Error enrolling in timeclock:", error);
      alert(
        "An error occurred while enrolling in the timeclock. Please try again."
      );
    } finally {
      setIsSubmitting(false);
      setTermsDialogOpen(false);
      setEnrollDialogOpen(false);
      setSubmissionDialogOpen(false);
    }
  };

  const handleConsent = async () => {
    if (selectedPropertyCode) {
      await handleEnroll(selectedPropertyCode);
      setShowTerms(false);
      setEnrollDialogOpen(false);
    }
  };

  const formatSSN = (ssn) => {
    if (ssn.length === 4) return ssn;
    if (ssn.length === 9) return `*****${ssn.slice(-4)}`;
    if (ssn.length === 11 && ssn.includes("-")) return `*****-${ssn.slice(-4)}`;
    return ssn;
  };

  if (!personalData) {
    return <CircularProgress />;
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    } else {
      setFileName("");
      setFile(null);
    }
  };

  const fileCategories = [
    {
      name: "W4-IRS",
      description: {
        en: "Federal W4 form for tax withholding",
        es: "Formulario W4 federal para la retención de impuestos",
      },
    },
    {
      name: "W4-LOCAL",
      description: {
        en: "Local W4 form for state or city tax withholding",
        es: "Formulario W4 local para la retención de impuestos estatales o municipales",
      },
    },
    {
      name: "ID",
      description: {
        en: "Identification documents like driver's license or passport",
        es: "Documentos de identificación como licencia de conducir o pasaporte",
      },
    },
    {
      name: "SSN",
      description: {
        en: "Social Security Number documentation",
        es: "Documentación del número de seguro social",
      },
    },
    {
      name: "BANKING",
      description: {
        en: "Banking details such as direct deposit forms",
        es: "Detalles bancarios como formularios de depósito directo",
      },
    },
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
  };

  const handleFileUpload = async () => {
    if (!file || selectedCategories.length === 0) return;

    setIsSubmitting(true);
    setSubmissionDialogOpen(true); // Show dialog at the beginning
    const fileExtension = file.name.split(".").pop();
    const now = new Date();
    const formattedDate = `${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}_${String(now.getDate()).padStart(2, "0")}_${now.getFullYear()}`;

    const getCategoryPrefix = (categories) => {
      const priority = ["BANKING", "W4-IRS", "W4-LOCAL", "ID", "SSN"];
      for (const prefix of priority) {
        if (categories.includes(prefix)) return prefix;
      }
      return categories[0];
    };

    const categoryPrefix = getCategoryPrefix(selectedCategories);
    const otherCategories = selectedCategories.filter(
      (category) => category !== categoryPrefix
    );

    const newFileName = `${categoryPrefix}_${otherCategories.join(
      "_"
    )}_${formattedDate}.${fileExtension}`;

    const mainFolderId = "1Bn8FFDHh_1Wd5TPIsq4LdXfqPeaNkw3D"; // Main folder ID

    try {
      // Fetch the phoneAuthUsers document to get the EmpID array
      const phoneAuthUserDocRef = doc(
        db,
        "phoneAuthUsers",
        personalData.AdPhone
      );
      const phoneAuthUserDoc = await getDoc(phoneAuthUserDocRef);

      if (!phoneAuthUserDoc.exists()) {
        throw new Error("phoneAuthUsers document not found");
      }

      const phoneAuthUserData = phoneAuthUserDoc.data();
      const empIDs = phoneAuthUserData.empID || [];
      const propIDs = phoneAuthUserData.propID || [];

      for (const propertyCode of propIDs) {
        // Find the position of the given PropID
        const propIndex = propIDs.indexOf(propertyCode);
        if (propIndex === -1) {
          throw new Error("Property code not found in propID array");
        }

        // Get the corresponding EmpID from the EmpID array
        const empID = empIDs[propIndex];
        if (!empID) {
          throw new Error("EmpID not found for the given property code");
        }

        // Fetch payPeriodEnd for the current property code
        const payPeriodEndDate = await fetchPayPeriodEnd(propertyCode);
        if (!payPeriodEndDate) {
          throw new Error(
            `Failed to fetch payPeriodEnd for property code: ${propertyCode}`
          );
        }

        const folderName = `${personalData.NameFirst} ${personalData.NameLast}-${empID}`;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", newFileName);
        formData.append("EmpID", empID);
        formData.append("PropID", propertyCode);
        formData.append("folderName", folderName); // Append the folder name to the form data
        formData.append("mainFolderId", mainFolderId); // Append the main folder ID to the form data

        const response = await fetch(
          "https://us-central1-doorman-1985.cloudfunctions.net/uploadFile",
          {
            method: "POST",
            body: formData,
            headers: {
              Accept: "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("File uploaded successfully!", data);
          const payrollChangesRef = collection(db, "payrollChanges");
          await addDoc(payrollChangesRef, {
            EmpID: empID,
            NameFirst: personalData.NameFirst,
            NameLast: personalData.NameLast,
            changes: [`File Uploaded: ${newFileName}`],
            payPeriod: subtractOneDay(payPeriodEndDate),
            ChangedBy: user.email,
            propCode: propertyCode,
          });

          // Update the employee document for each relevant category
          const empDocRef = doc(db, "empRegister", empID);
          const updateData = selectedCategories.reduce((acc, category) => {
            if (
              ["W4-IRS", "W4-LOCAL", "ID", "SSN", "BANKING"].includes(category)
            ) {
              acc[category] = "SUBMITTED";
            }
            return acc;
          }, {});

          if (Object.keys(updateData).length > 0) {
            await updateDoc(empDocRef, updateData);
          }
        } else {
          console.error("File upload failed:", response.statusText);
          alert(
            `File upload failed for property ${propertyCode}: ${
              response.statusText || "An error occurred."
            }`
          );
        }
      }

      alert("File(s) uploaded successfully!");
      setFile(null);
      setSelectedCategories([]);
      setReloadTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error uploading file:", error.message);
      alert(`Error uploading file: ${error.message || "An error occurred."}`);
    } finally {
      setIsSubmitting(false);
      setSubmissionDialogOpen(false); // Close dialog at the end
    }
  };

  const emailIsEmpty = !personalData.Email;

  const fetchProperties = async () => {
    setIsFetchingProperties(true);

    try {
      // Sanitize phone number by removing the leading "+"
      const sanitizedPhoneNumber = user.phoneNumber.replace(/^\+/, "");
      console.log("Sanitized Phone Number:", sanitizedPhoneNumber);

      const empRef = collection(db, "empRegister");
      const q = query(empRef, where("AdPhone", "==", sanitizedPhoneNumber));
      const snapshot = await getDocs(q);

      console.log("Snapshot size:", snapshot.size);

      if (!snapshot.empty) {
        const fetchedProperties = snapshot.docs.map((doc) => doc.data().PropID);
        console.log("Fetched Properties:", fetchedProperties);
        setProperties(fetchedProperties);

        // Update AdEmail to all documents that match the user's phone number
        const batch = writeBatch(db);
        snapshot.forEach((docSnapshot) => {
          const docData = docSnapshot.data();
          console.log("Updating AdEmail for doc ID:", docSnapshot.id);
          const docRef = doc(db, "empRegister", docSnapshot.id);
          batch.update(docRef, { AdEmail: user.email });
        });

        await batch.commit();
        console.log("Batch update committed.");
      } else {
        console.log("No documents found for the sanitized phone number.");
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
      alert("An error occurred while fetching properties. Please try again.");
    } finally {
      setIsFetchingProperties(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "36vh",
      }}
    >
      <Card
        sx={{
          maxWidth: 360,
          textAlign: "center",
          borderRadius: "36px",
          backgroundColor: "transparent",
        }}
      >
        <CardContent
          sx={{
            backgroundColor: "#f9f9f9",
            padding: "0rem",
            borderRadius: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Avatar
              src={profilePictureURL}
              alt="Profile Picture"
              sx={{
                width: 360,
                height: 360,
                marginBottom: "1.8rem",
                borderRadius: "1.8rem 1.8rem 0rem 0rem",
                filter: "grayscale(99%)",
              }}
            />
            {(!Object.values(timeclockStatus).includes("yes") ||
              status !== "1") && (
              <>
                <IconButton
                  onClick={() => setProfilePictureDialogOpen(true)}
                  sx={{
                    position: "absolute",
                    right: "1rem",
                    bottom: "1rem",
                    backgroundColor: language === "en" ? "#74c365" : "#FFC107",
                    borderRadius: "50%",
                    padding: "0.5rem",
                    color: "#f9f9f9",
                  }}
                >
                  <EditIcon />
                </IconButton>
              </>
            )}
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              sx={{
                marginLeft: 1,
                letterSpacing: "0.18rem",
                fontWeight: "800",
                fontSize: "1.53rem",
                color: "#010101",
                paddingBottom: "0.9rem",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {personalData.NameFirst || ""} {personalData.NameMid || ""}{" "}
              {personalData.NameLast || ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                letterSpacing: "0.045rem",
                fontWeight: "400",
                fontSize: "0.72rem",
                color: "#010101",
                paddingBottom: "0.36rem",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {personalData.Email || ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                letterSpacing: "0.045rem",
                fontWeight: "400",
                fontSize: "0.72rem",
                color: "#010101",
                paddingBottom: "0.36rem",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {personalData.AdPhone || ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                letterSpacing: "0.045rem",
                fontWeight: "400",
                fontSize: "0.72rem",
                color: "#010101",
                paddingBottom: "0.36rem",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {personalData.Ad1 || ""}, {personalData.AdCity || ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                letterSpacing: "0.045rem",
                fontWeight: "400",
                fontSize: "0.72rem",
                color: "#010101",
                paddingBottom: "0.36rem",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {personalData.AdState || ""}, {personalData.AdZip || ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                letterSpacing: "0.045rem",
                fontWeight: "400",
                fontSize: "0.72rem",
                color: "#010101",
                paddingBottom: "0.36rem",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {personalData.DOB
                ? new Date(personalData.DOB).toLocaleDateString()
                : ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 1,
                letterSpacing: "0.045rem",
                fontWeight: "400",
                fontSize: "0.72rem",
                color: "#010101",
                paddingBottom: "0.36rem",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {formatSSN(personalData.SS4 || "")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",

                position: "absolute",
                top: 9,
                left: 9,
                backgroundColor: "#ffffff",
                padding: "0.3rem 0.3rem",
                borderRadius: "0.5rem",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={language === "en"}
                    onChange={() => {
                      setLanguage((prevLanguage) =>
                        prevLanguage === "en" ? "es" : "en"
                      );
                    }}
                    sx={{
                      "& .MuiSwitch-switchBase": {
                        "&.Mui-checked": {
                          color: "#FFC107",
                          "& + .MuiSwitch-track": {
                            backgroundColor: "#FFC107",
                          },
                        },
                        "&.Mui-checked:hover": {
                          backgroundColor: "rgba(249, 139, 133, 0.08)",
                        },
                        "&.Mui-checked .MuiSwitch-thumb": {
                          backgroundColor: "#FFC107",
                        },
                        "& .MuiSwitch-thumb": {
                          backgroundColor: "#74c365",
                          "&::before": {
                            content: '""',

                            width: "100%",
                            height: "100%",
                            backgroundSize: "cover",
                          },
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        backgroundColor: "#74c365",
                        "&::before": {
                          content: '""',

                          width: "100%",
                          height: "100%",
                          backgroundSize: "cover",
                        },
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: "#74c365",
                      },
                      marginLeft: "27px",
                    }}
                  />
                }
                label={language === "en" ? "EN" : "ES"}
                labelPlacement="right"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: "#101010",
                    fontWeight: "800",
                    fontSize: "0.9rem",
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "2rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={
                  language === "en"
                    ? "Change Email or Phone Number"
                    : "Cambiar Correo o Número de Teléfono"
                }
              >
                <span>
                  <IconButton
                    onClick={() => setEmailPhoneDialogOpen(true)}
                    disabled={isSubmitting}
                    sx={{ color: "#000000" }}
                  >
                    <SlSpeech />
                  </IconButton>
                </span>
              </Tooltip>
              <Typography variant="caption">
                {language === "en" ? "EMAIL/PHONE" : "CORREO/TELÉFONO"}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={language === "en" ? "My Documents" : "Mis Documentos"}
              >
                <span>
                  <IconButton
                    onClick={() => setMyDocumentsDialogOpen(true)}
                    disabled={isSubmitting}
                    sx={{ color: "#000000" }}
                  >
                    <MdOutlineDocumentScanner />
                  </IconButton>
                </span>
              </Tooltip>
              <Typography variant="caption">
                {language === "en" ? "PAPERWORK" : "DOCUMENTOS"}
              </Typography>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Tooltip title={language === "en" ? "Enroll" : "Inscribirse"}>
                <span>
                  <IconButton
                    onClick={() => setEnrollDialogOpen(true)}
                    disabled={isSubmitting}
                    sx={{ color: "#000000" }}
                  >
                    <SiFacepunch />
                  </IconButton>
                </span>
              </Tooltip>
              <Typography variant="caption">
                {language === "en" ? "TIMECLOCK" : "RELOJ DE TIEMPO"}
              </Typography>
            </Box>
          </Box>
        </CardContent>

        <Dialog
          open={myDocumentsDialogOpen}
          onClose={() => setMyDocumentsDialogOpen(false)}
          PaperProps={{
            sx: {
              padding: "1rem",
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
              position: "relative",
            },
          }}
        >
          <IconButton
            onClick={() => setMyDocumentsDialogOpen(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#101010",
            }}
          >
            <TfiClose />
          </IconButton>
          <DialogTitle
            sx={{
              letterSpacing: "0.3rem",
              fontWeight: "400",
              fontSize: "0.9rem",
              color: "#101010",
            }}
          >
            UPLOAD FILE
          </DialogTitle>
          <DialogContent>
            <Grid
              item
              xs={12}
              container
              sx={{
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Grid container spacing={12}>
                {/* Checkboxes and Upload Functions */}
                <Grid item xs={12}>
                  <Grid container>
                    {fileCategories.map((category) => (
                      <Grid item xs={12} key={category.name}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedCategories.includes(
                                category.name
                              )}
                              onChange={() =>
                                handleCategoryChange(category.name)
                              }
                            />
                          }
                          label={category.name}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "400",
                            fontSize: "0.81rem",
                            color: "#101010",
                          }}
                        >
                          {category.description[language]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    paddingTop={3}
                  >
                    <Grid item>
                      <input
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        onChange={handleFileChange}
                        id="file-upload"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="file-upload">
                        <Button
                          component="span"
                          variant="contained"
                          sx={{
                            backgroundColor: "transparent",
                            borderRadius: "9px",
                            padding: "9.9px 18px 9.9px 18px",
                            color: "#101010",
                            fontWeight: "400",
                            fontSize: "0.72rem",
                            border: "2px solid #101010",

                            "&:hover": {
                              backgroundColor: "#101010",
                              opacity: "0.6425339366515838",
                              color: "#f9f9f9",
                            },
                          }}
                        >
                          CHOOSE FILE
                        </Button>
                      </label>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "400",
                          fontSize: "0.81rem",
                          color: "#101010",
                        }}
                      >
                        {fileName ||
                          (language === "en"
                            ? "Select all applicable file categories above, then upload file"
                            : "Seleccione todas las categorías de archivos aplicables arriba, luego cargue el archivo")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={handleFileUpload}
                  disabled={!file || selectedCategories.length === 0}
                  fullWidth
                  sx={{
                    backgroundColor: "#c7d57f",
                    borderRadius: "9px",
                    padding: "9.9px 18px 9.9px 18px",
                    color: "#010101",
                    letterSpacing: "0.1rem",
                    fontWeight: "800",
                    fontSize: "0.72rem",
                    border: "2px solid #f9f9f9",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#010101",
                    },
                  }}
                >
                  {language === "en" ? "SUBMIT" : "ENVIAR"}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog
          open={emailPhoneDialogOpen}
          onClose={() => setEmailPhoneDialogOpen(false)}
          PaperProps={{
            sx: {
              padding: "1rem",
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
              position: "relative",
            },
          }}
        >
          <IconButton
            onClick={() => setEmailPhoneDialogOpen(false)}
            sx={{
              position: "absolute",
              top: 9,
              right: 9,
              color: "#101010",
            }}
          >
            <TfiClose />
          </IconButton>
          <DialogTitle
            sx={{
              letterSpacing: "0.3rem",
              fontWeight: "400",
              fontSize: "0.9rem",
              color: "#101010",
            }}
          >
            {language === "en"
              ? "CHANGE EMAIL / PHONE NUMBER"
              : "CAMBIAR CORREO ELECTRÓNICO / NÚMERO DE TELÉFONO"}
          </DialogTitle>
          <DialogContent
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: "400",
              fontSize: "0.9rem",
              color: "#101010",
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              label={
                language === "en"
                  ? "New Email Address"
                  : "Nuevo correo electrónico"
              }
              type="email"
              fullWidth
              value={newEmail}
              variant="outlined"
              onChange={(e) => setNewEmail(e.target.value)}
              sx={{ ...commonSx }}
            />
            <TextField
              margin="dense"
              label={
                language === "en"
                  ? "New Phone Number"
                  : "Nuevo número de teléfono"
              }
              type="tel"
              fullWidth
              variant="outlined"
              value={newPhoneNumber}
              onChange={handlePhoneInputChange}
              disabled={isSubmitting || emailIsEmpty || isPhoneChangeDisabled()}
              sx={{ ...commonSx }}
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={handleEmailChange}
                  color="primary"
                  disabled={isSubmitting || !newEmail}
                  fullWidth
                  sx={{
                    backgroundColor: "#c7d57f",
                    borderRadius: "9px",
                    padding: "9.9px 18px 9.9px 18px",
                    color: "#010101",
                    letterSpacing: "0.1rem",
                    fontWeight: "800",
                    fontSize: "0.72rem",
                    border: "2px solid #f9f9f9",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#010101",
                    },
                  }}
                >
                  {language === "en" ? "SAVE EMAIL" : "GUARDAR CORREO"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={handlePhoneChange}
                  color="primary"
                  disabled={
                    isSubmitting ||
                    !newPhoneNumber ||
                    emailIsEmpty ||
                    isPhoneChangeDisabled()
                  }
                  fullWidth
                  sx={{
                    backgroundColor: "#c7d57f",
                    borderRadius: "9px",
                    padding: "9.9px 18px 9.9px 18px",
                    color: "#010101",
                    letterSpacing: "0.1rem",
                    fontWeight: "800",
                    fontSize: "0.72rem",
                    border: "2px solid #f9f9f9",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#010101",
                    },
                  }}
                >
                  {language === "en" ? "SAVE PHONE" : "GUARDAR TELÉFONO"}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog
          open={profilePictureDialogOpen}
          onClose={() => setProfilePictureDialogOpen(false)}
          PaperProps={{
            sx: {
              padding: "1rem",
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
              position: "relative",
            },
          }}
        >
          <IconButton
            onClick={() => setProfilePictureDialogOpen(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#101010",
            }}
          >
            <TfiClose />
          </IconButton>
          <DialogTitle
            sx={{
              letterSpacing: "0.3rem",
              fontWeight: "400",
              fontSize: "0.9rem",
              color: "#101010",
            }}
          >
            PROFILE PIC
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body2"
              sx={{
                marginBottom: "1rem",
                fontWeight: "400",
                fontSize: "0.81rem",
                color: "#101010",
              }}
            >
              {language === "en"
                ? "Please take a selfie of yourself in good light and with a neutral blank background to enroll in the punch time clock."
                : "Por favor, tómate una selfie con buena luz y con un fondo neutral y en blanco para inscribirte en el reloj de tiempo de punch."}
            </Typography>
            {timeClockStatus === "yes" ? (
              <Typography variant="body2">
                {language === "en"
                  ? "You are already enrolled in the timeclock. Feel free to have fun with your profile picture!"
                  : "Ya estás inscrito en el reloj de tiempo. ¡Siéntete libre de divertirte con tu foto de perfil!"}
              </Typography>
            ) : null}
            <input type="file" onChange={handleProfilePictureChange} />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={handleProfilePictureUpload}
                  color="primary"
                  disabled={!profilePicture || isSubmitting}
                  fullWidth
                  sx={{
                    backgroundColor: "#c7d57f",
                    borderRadius: "9px",
                    padding: "9.9px 18px 9.9px 18px",
                    color: "#010101",
                    letterSpacing: "0.1rem",
                    fontWeight: "800",
                    fontSize: "0.72rem",
                    border: "2px solid #f9f9f9",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#010101",
                    },
                  }}
                >
                  {language === "en" ? "UPLOAD" : "SUBIR"}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog
          open={enrollDialogOpen}
          onClose={() => setEnrollDialogOpen(false)}
          PaperProps={{
            sx: {
              padding: "1rem",
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
              position: "relative",
            },
          }}
        >
          <IconButton
            onClick={() => setEnrollDialogOpen(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#101010",
            }}
          >
            <TfiClose />
          </IconButton>
          <DialogContent
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: "400",
              fontSize: "0.9rem",
              color: "#101010",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                letterSpacing: "0.18rem",
                fontWeight: "400",
                fontSize: "0.9rem",
                color: "#101010",
                ...commonSx,
              }}
            >
              {language === "en"
                ? "ENROLL IN TIMECLOCK"
                : "INSCRIBIRSE EN EL RELOJ"}
            </Typography>
            {propertyCodes.map((code) => (
              <div key={code}>
                <Typography
                  fullwidth
                  sx={{
                    fontWeight: "800",
                    fontSize: "0.99rem",
                    color: "#101010",
                    paddingTop: "0.9rem",
                    paddingBottom: "0.45rem",
                  }}
                >
                  {code}
                  <Button
                    variant="contained"
                    onClick={() => handleEnrollButtonClick(code)}
                    sx={{
                      backgroundColor: "#FFC107",
                      borderRadius: "9px",
                      marginLeft: "1rem",
                      color: "#f9f9f9",
                      letterSpacing: "0.1rem",
                      fontWeight: "400",
                      fontSize: "0.81rem",

                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#010101",
                      },
                    }}
                  >
                    {timeclockStatus[code] === "retake"
                      ? language === "en"
                        ? "FIX PROFILE PIC AND RETAKE"
                        : "ARREGLAR FOTO DE PERFIL Y REPETIR"
                      : timeclockStatus[code] === "yes"
                      ? language === "en"
                        ? "ENROLLED"
                        : "INSCRITO"
                      : language === "en"
                      ? "ENROLL IN TIMECLOCK"
                      : "INSCRIBIRSE EN EL RELOJ"}
                  </Button>
                </Typography>
              </div>
            ))}
            <Box sx={{ marginTop: "2rem" }}>
              {properties.length > 0 ? (
                properties.map((code) => (
                  <div key={code}>
                    <Typography>{code}</Typography>
                  </div>
                ))
              ) : (
                <Typography variant="body2" sx={{ marginTop: "0.9rem" }}>
                  {language === "en"
                    ? "If you are missing locations, press 'REFRESH' below."
                    : "Si te faltan ubicaciones, presiona 'ACTUALIZAR' abajo."}
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={fetchProperties}
                  color="primary"
                  disabled={isSubmitting || isRefreshDisabled}
                  fullWidth
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "9px",
                    padding: "9px 9px 9px 9px",
                    color: "#101010",
                    fontWeight: "400",
                    fontSize: "0.72rem",
                    border: "2px solid #101010",

                    "&:hover": {
                      backgroundColor: "#101010",
                      opacity: "0.6425339366515838",
                      color: "#f9f9f9",
                    },
                  }}
                >
                  {language === "en" ? "REFRESH" : "ACTUALIZAR"}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog open={showTerms} onClose={() => setShowTerms(false)}>
          <IconButton
            onClick={() => setShowTerms(false)}
            sx={{
              position: "absolute",
              top: 9,
              right: 9,
              color: "#101010",
            }}
          >
            <TfiClose />
          </IconButton>
          <DialogTitle
            sx={{
              marginLEFT: 9,
              letterSpacing: "0.1rem",
              fontWeight: "800",
              fontSize: "0.9rem",
              color: "#101010",
              paddingTop: "1rem",
            }}
          >
            {language === "en" ? "TERMS OF SERVICE" : "TÉRMINOS DEL SERVICIO"}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {language === "en" ? (
                <>
                  <strong>Understanding Our Attendance System</strong>
                  <br />
                  <br />
                  <strong>How It Works</strong>
                  <br />
                  Your Unique Faceprint: Just like your fingerprints, the
                  distances between your eyes, nose, and jawline create a unique
                  facial profile.
                  <br />
                  Digital Code: Our system takes a photo of your face and
                  creates a digital code, similar to how your smartphone
                  recognizes your face for unlocking.
                  <br />
                  Easy Clock In/Out: When you arrive or leave, simply look at
                  the camera. The system will verify your identity using your
                  stored facial code.
                  <br />
                  <br />
                  <strong>Your Data: Collected, Used, Protected</strong>
                  <br />
                  Collection: We'll take one photo of your face during
                  enrollment.
                  <br />
                  Usage: This photo is used to create your unique digital code
                  and for attendance verification only.
                  <br />
                  Secure Storage: Your facial data is stored securely and
                  encrypted to prevent unauthorized access.
                  <br />
                  No Commercial Use: We will never sell, rent, or share your
                  facial data with third parties for any reason.
                  <br />
                  Retention: Your data will be kept as long as you're employed
                  here. Upon termination of your employment, your facial data
                  will be permanently deleted from the system.
                  <br />
                  <br />
                  <strong>Verification and Indemnification</strong>
                  <br />
                  While the system is designed for accuracy, we understand that
                  errors can occur. We encourage you to regularly review your
                  attendance records via our online portal or by requesting a
                  printed copy from your employer. By using this system, you
                  agree to hold the company, the system developer, and any
                  authorized users of the system harmless from any liability for
                  unintentional errors in time and attendance tracking.
                  Employees agree to indemnify and hold harmless the company and
                  associated personnel from any claims, damages, or losses
                  arising from the use of the facial recognition attendance
                  system, except where such claims, damages, or losses are due
                  to willful misconduct or gross negligence.
                  <br />
                  <br />
                  By clicking "Agree" below, you confirm that you've read and
                  understood these terms and consent to using the facial
                  recognition attendance system.
                </>
              ) : (
                <>
                  <strong>Entendiendo Nuestro Sistema de Asistencia</strong>
                  <br />
                  <br />
                  <strong>Cómo Funciona</strong>
                  <br />
                  Tu Huella Facial Única: Al igual que tus huellas dactilares,
                  las distancias entre tus ojos, nariz y mandíbula crean un
                  perfil facial único.
                  <br />
                  Código Digital: Nuestro sistema toma una foto de tu cara y
                  crea un código digital, similar a cómo tu smartphone reconoce
                  tu cara para desbloquearse.
                  <br />
                  Fácil Registro de Entrada/Salida: Cuando llegas o te vas,
                  simplemente mira a la cámara. El sistema verificará tu
                  identidad usando tu código facial almacenado.
                  <br />
                  <br />
                  <strong>Tus Datos: Recopilados, Usados, Protegidos</strong>
                  <br />
                  Recopilación: Tomaremos una foto de tu cara durante el proceso
                  de inscripción.
                  <br />
                  Uso: Esta foto se usa para crear tu código digital único y
                  solo para la verificación de asistencia.
                  <br />
                  Almacenamiento Seguro: Tus datos faciales se almacenan de
                  manera segura y encriptada para prevenir el acceso no
                  autorizado.
                  <br />
                  No Uso Comercial: Nunca venderemos, alquilaremos ni
                  compartiremos tus datos faciales con terceros por ninguna
                  razón.
                  <br />
                  Retención: Tus datos se mantendrán mientras estés empleado
                  aquí. Al terminar tu empleo, tus datos faciales serán
                  eliminados permanentemente del sistema.
                  <br />
                  <br />
                  <strong>Verificación e Indemnización</strong>
                  <br />
                  Aunque el sistema está diseñado para ser preciso, entendemos
                  que pueden ocurrir errores. Te animamos a revisar regularmente
                  tus registros de asistencia a través de nuestro portal en
                  línea o solicitando una copia impresa a tu empleador. Al usar
                  este sistema, aceptas eximir a la empresa, al desarrollador
                  del sistema y a cualquier usuario autorizado del sistema de
                  cualquier responsabilidad por errores no intencionales en el
                  seguimiento del tiempo y la asistencia. Los empleados aceptan
                  indemnizar y eximir de responsabilidad a la empresa y al
                  personal asociado por cualquier reclamación, daño o pérdida
                  que surja del uso del sistema de reconocimiento facial,
                  excepto cuando tales reclamaciones, daños o pérdidas sean
                  debidas a mala conducta intencional o negligencia grave.
                  <br />
                  <br />
                  Al hacer clic en "Aceptar" a continuación, confirmas que has
                  leído y comprendido estos términos y consientes el uso del
                  sistema de reconocimiento facial de asistencia.
                </>
              )}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={handleConsent}
                  color="primary"
                  disabled={
                    isSubmitting ||
                    timeclockStatus[selectedPropertyCode] === "yes"
                  }
                  fullWidth
                  sx={{
                    backgroundColor: "#c7d57f",
                    borderRadius: "9px",
                    padding: "9.9px 18px 9.9px 18px",
                    color: "#010101",
                    letterSpacing: "0.1rem",
                    fontWeight: "800",
                    fontSize: "0.72rem",
                    border: "2px solid #f9f9f9",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#010101",
                    },
                  }}
                >
                  {language === "en"
                    ? "ACCEPT + ENROLL"
                    : "ACEPTAR + INSCRIBIRSE"}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <SubmissionDialog
          open={submissionDialogOpen}
          onClose={() => {
            setSubmissionDialogOpen(false);
          }}
          isSubmitting={isSubmitting}
          message={submissionMessage}
        />
      </Card>
    </Box>
  );
}

const SubmissionDialog = ({ open, onClose, isSubmitting, message }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        padding: "1rem",
        borderRadius: "27px",
        backgroundColor: "#f9f9f9",
      },
    }}
  >
    <DialogContent>
      {isSubmitting ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundColor: "#f9f9f9",
          }}
        >
          <CircularProgress style={{ color: "#101010" }} />
        </div>
      ) : (
        <p
          style={{
            textAlign: "center",
            color: "#101010",
            fontSize: "1rem",
            fontWeight: "800",
            letterSpacing: "0.1rem",
          }}
        >
          {message}
        </p>
      )}
    </DialogContent>
  </Dialog>
);

const commonSx = {
  marginBottom: "0.2rem",
  transition: "0.3s",
  maxWidth: "99rem",
  ".MuiInputLabel-root": {
    fontSize: "0.9rem",
    fontWeight: 200,
    color: "#020202",
  },
  ".MuiInputBase-input": {
    fontSize: "0.9rem",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "18px",
  },
  "& .MuiToggleButton-root": {
    borderRadius: "18px",
  },
};
