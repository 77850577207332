import React, { useState, useEffect, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Card,
  Switch,
  Badge,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  query,
  where,
  updateDoc,
  getDocs,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import { format } from "date-fns";
import { US_STATES } from "./constants";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FaUserCheck, FaUserTimes, FaFileUpload } from "react-icons/fa";
import { FaFilePdf, FaFileImage } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import { BsPatchMinusFill, BsPatchCheckFill } from "react-icons/bs";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import AddProperty from "../addRecord/AddRecord";

export default function UpdateProperty() {
  const { user } = useAuth();
  const location = useLocation(); // Use useLocation to get the navigation state
  const [formType, setFormType] = useState(location.state?.formType || ""); // Set initial value from state
  const [propertyCode, setPropertyCode] = useState(
    location.state?.propertyCode || ""
  ); // Set initial value from state
  const [selectedEmployee, setSelectedEmployee] = useState(
    location.state?.selectedEmployee || ""
  ); // Set initial value from state
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [recordData, setRecordData] = useState(null);
  const [changes, setChanges] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isTerminated, setIsTerminated] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("Active");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const fileCategories = [
    "W4-IRS",
    "W4-LOCAL",
    "ID",
    "SSN",
    "WRITE-UP",
    "BANKING",
    "CERTIFICATE",
    "OTHER",
  ];
  const [hasPropertyAccess, setHasPropertyAccess] = useState(false);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [previousPayPeriodStart, setPreviousPayPeriodStart] = useState(null);
  const [previousPayPeriodEnd, setPreviousPayPeriodEnd] = useState(null);
  const [canViewPay, setCanViewPay] = useState(false);
  const [canViewSalary, setCanViewSalary] = useState(false);
  const [canViewPayroll, setCanViewPayroll] = useState(false);
  const [selectedAckEmpStatus, setSelectedAckEmpStatus] = useState(false);
  const [originalRecordData, setOriginalRecordData] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [employeeFiles, setEmployeeFiles] = useState([]);
  const [emailFields, setEmailFields] = useState({
    ManagerEmail: { email: "", admin: false, viewPay: false },
    AGMEmail: { email: "", admin: false, viewPay: false },
    OtherAdminEmail: { email: "", admin: false, viewPay: false },
  });

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [selectedEmailField, setSelectedEmailField] = useState("");
  const [selectedEmailValue, setSelectedEmailValue] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState(false);
  const [selectedViewPay, setSelectedViewPay] = useState(false);
  const [emailClaimsChanged, setEmailClaimsChanged] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = React.useState(0);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [submittedCounts, setSubmittedCounts] = useState({
    payroll: 0,
    banking: 0,
  });
  const [confirmApproveDocDialogOpen, setConfirmApproveDocDialogOpen] =
    useState(false);
  const [confirmRejectDocDialogOpen, setConfirmRejectDocDialogOpen] =
    useState(false);
  const [fileToApprove, setFileToApprove] = useState(null);
  const [fileToReject, setFileToReject] = useState(null);
  const [rejectReason, setRejectReason] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [actionType, setActionType] = useState('update');


  const handleMainSwitch = (event) => {
    setActionType(event.target.checked ? 'addNew' : 'update');
  };

  const YOUR_FIREBASE_FUNCTION_URL =
    "https://us-central1-doorman-1985.cloudfunctions.net";


  const handleSwitchChange = (event) => {
    setEmployeeStatus(event.target.checked ? "Active" : "Inactive");
  };

  useEffect(() => {
    if (recordData) {
      setOriginalRecordData({ ...recordData });
    }
  }, [recordData]);

  useEffect(() => {
    if (!user) return;

    const fetchPropertyCodes = async () => {
      const roleEmail = `${user.photoURL}Email`;
      const propertiesRef = collection(db, "Properties");
      const queries = [
        query(propertiesRef, where(roleEmail, "==", user.email)),
        query(propertiesRef, where("AdminEmail", "array-contains", user.email)),
      ];

      for (let q of queries) {
        const snapshot = await getDocs(q);
        const newCodes = snapshot.docs.map((doc) => doc.data().propCode);
        setPropertyCodes((prevCodes) => [
          ...new Set([...prevCodes, ...newCodes]),
        ]);

        if (snapshot.docs.length > 0) {
          setHasPropertyAccess(true);
        }
      }
    };

    fetchPropertyCodes();
  }, [user]);

  const filteredEmployees = employees
    .filter((emp) => {
      const isTerminated = emp.Status === "Terminated";
      const isActive =
        employeeStatus === "Active" ? !isTerminated : isTerminated;
      const hasSalary =
        emp.PayD1_Type === "SALARY" || emp.PayD2_Type === "SALARY";
      return isActive && (canViewSalary || !hasSalary);
    })
    .sort((a, b) => {
      const nameA = `${a.NameFirst} ${a.NameLast}`.toUpperCase();
      const nameB = `${b.NameFirst} ${b.NameLast}`.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    if (propertyCode) {
      if (formType === "Property") {
        const fetchProperty = async () => {
          const docRef = doc(db, "Properties", propertyCode);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setRecordData(docSnap.data());
          } else {
            console.log("No such document!");
            setRecordData(null);
          }
        };

        fetchProperty();
      } else if (formType === "Employee") {
        const fetchEmployees = async () => {
          const empRef = collection(db, "empRegister");
          const q = query(empRef, where("PropID", "==", propertyCode));
          const snapshot = await getDocs(q);
          const employees = snapshot.docs.map((doc) => ({
            id: doc.id,
            EmpID: doc.data().EmpID,
            ...doc.data(),
          }));
          setEmployees(employees);

          console.log("Fetched employees:", employees); // Log fetched employees

          if (employees.length === 1) {
            setSelectedEmployee(employees[0].EmpID);
            setRecordData(employees[0]);
          } else if (selectedEmployee) {
            const selectedEmp = employees.find(
              (emp) => emp.EmpID === selectedEmployee
            );
            setRecordData(selectedEmp || null);
          } else {
            setSelectedEmployee("");
            setRecordData(null);
          }
          setChanges({});
        };

        fetchEmployees();
      }
    }
  }, [formType, propertyCode]);

  useEffect(() => {
    if (selectedEmployee && employees.length > 0) {
      const selectedEmp = employees.find(
        (emp) => emp.EmpID === selectedEmployee
      );
      console.log("Selected Employee Data:", selectedEmp); // Log selected employee data
      setRecordData(selectedEmp || {});
    }
  }, [selectedEmployee, employees]);

  useEffect(() => {
    if (selectedEmployee) {
      const fetchSubmittedCounts = async () => {
        try {
          console.log(
            "Fetching submitted counts for employee:",
            selectedEmployee
          );
          const empDocRef = query(
            collection(db, "empRegister"),
            where("EmpID", "==", selectedEmployee)
          );
          const empDocSnapshot = await getDocs(empDocRef);

          if (!empDocSnapshot.empty) {
            const data = empDocSnapshot.docs[0].data();
            console.log("Employee document data:", data);

            const payrollFields = ["ID", "SSN", "W4-IRS", "W4-LOCAL"];
            const payrollCount = payrollFields.filter(
              (field) => data[field] && data[field] === "SUBMITTED"
            ).length;
            const bankingCount =
              data["BANKING"] && data["BANKING"] === "SUBMITTED" ? 1 : 0;

            console.log("Payroll count:", payrollCount);
            console.log("Banking count:", bankingCount);

            setSubmittedCounts({
              payroll: payrollCount,
              banking: bankingCount,
            });
          } else {
            console.log("No such employee document exists!");
            setSubmittedCounts({ payroll: 0, banking: 0 });
          }
        } catch (error) {
          console.error("Error fetching submitted counts:", error);
          setSubmittedCounts({ payroll: 0, banking: 0 });
        }
      };

      fetchSubmittedCounts();
    }
  }, [selectedEmployee]);
  // Fetch files when recordData changes
  useEffect(() => {
    const fetchFiles = async () => {
      if (
        !recordData ||
        !recordData.NameFirst ||
        !recordData.NameLast ||
        !recordData.EmpID
      ) {
        setEmployeeFiles([]); // Clear files if recordData is invalid
        return;
      }

      // Construct the folder name, including the middle name if it exists
      const folderName = `${recordData.NameFirst}${
        recordData.NameMid ? ` ${recordData.NameMid}` : ""
      } ${recordData.NameLast}-${recordData.EmpID}`;

      const mainFolderId = "1Bn8FFDHh_1Wd5TPIsq4LdXfqPeaNkw3D"; // Main folder ID

      try {
        const response = await fetch(
          `https://us-central1-doorman-1985.cloudfunctions.net/listFiles?folderName=${encodeURIComponent(
            folderName
          )}&mainFolderId=${mainFolderId}`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.files && data.files.length > 0) {
            setEmployeeFiles(data.files);
          } else {
            setEmployeeFiles([]); // Set to empty array if no files are found
          }
        } else {
          console.error("Failed to fetch files:", response.statusText);
          setEmployeeFiles([]); // Clear files on error
        }
      } catch (error) {
        console.error("Error fetching files:", error);
        setEmployeeFiles([]); // Clear files on exception
      }
    };

    fetchFiles();
  }, [recordData, reloadTrigger]);

  const approveFile = async (file) => {
    try {
      const empDocRef = doc(db, "empRegister", selectedEmployee);
      const empDoc = await getDoc(empDocRef);

      if (empDoc.exists()) {
        const changes = {};
        changes[file] = "APPROVED";

        await updateDoc(empDocRef, {
          ...changes,
          Notes: arrayUnion(
            `${new Date().toLocaleDateString()} - ${file} document approved by ${
              user.displayName || "Unknown User"
            }`
          ),
        });

        // Fetch the updated document
        const updatedDoc = await getDoc(empDocRef);

        // Update the state with the new document data
        setRecordData(updatedDoc.data());

        setConfirmApproveDocDialogOpen(false); // Close the dialog
      }
    } catch (error) {
      console.error("Error approving file:", error);
    }
  };

  const handleApproveClick = (file) => {
    setFileToApprove(file);
    setConfirmApproveDocDialogOpen(true);
  };

  const handleApproveDialogClose = (confirmed) => {
    if (confirmed) {
      approveFile(fileToApprove);
    } else {
      setConfirmApproveDocDialogOpen(false);
    }
  };

  const rejectFile = async (file) => {
    try {
      const empDocRef = doc(db, "empRegister", selectedEmployee);
      const empDoc = await getDoc(empDocRef);

      if (empDoc.exists()) {
        const changes = {};
        changes[file] = "REJECTED";

        // Update the Firestore document
        await updateDoc(empDocRef, {
          ...changes,
          Notes: arrayUnion(
            `${new Date().toLocaleDateString()} - ${file} document rejected by ${
              user.displayName || "Unknown User"
            }: ${rejectReason}`
          ),
        });

        // Translate the rejectReason to Spanish
        const translationResponse = await axios.post(
          "https://translation.googleapis.com/language/translate/v2",
          {},
          {
            params: {
              q: rejectReason,
              target: "es",
              key: "AIzaSyBcMBhYY8c84uVwJHN42gcvY3a5eaM0VBE",
            },
          }
        );

        const translatedRejectReason =
          translationResponse.data.data.translations[0].translatedText;

        // Send axios HTTP request with the translated reason
        await axios.post(
          "https://prod-29.westus.logic.azure.com:443/workflows/2134e5ab1d5f45d69ef0e7e5622ab0ce/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=f41w_dH6Hwli7mWqaRWXgZNvvLdTIBNw3aFvlbPuJcs",
          {
            category: file,
            employeeName: `${recordData.NameFirst} ${recordData.NameLast}`,
            employeeId: recordData.EmpID,
            isoDate: new Date().toISOString(),
            employeePhone: recordData.AdPhone,
            rejectReason: rejectReason,
            translatedRejectReason: translatedRejectReason, // Include the translated reason
          }
        );

        // Fetch the updated document
        const updatedDoc = await getDoc(empDocRef);

        // Update the state with the new document data
        setRecordData(updatedDoc.data());

        setConfirmRejectDocDialogOpen(false); // Close the dialog
        setRejectReason(""); // Reset the reject reason
      }
    } catch (error) {
      console.error("Error rejecting file:", error);
    }
  };

  const handleRejectClick = (file) => {
    setFileToReject(file);
    setConfirmRejectDocDialogOpen(true);
  };

  const handleRejectDialogClose = (confirmed) => {
    if (confirmed) {
      rejectFile(fileToReject);
    } else {
      setConfirmRejectDocDialogOpen(false);
      setRejectReason(""); // Reset the reject reason
    }
  };

  const renderApprovalButtons = (category, files) => {
    if (!canViewSalary) {
      return null; // Return null if canViewSalary is false
    }

    return (
      <Grid
        sx={{
          marginLeft: "1rem",
          backgroundColor: "#fcfcfc",
        }}
        container
        spacing={2}
      >
        {files
          .filter((file) => recordData[file] === "SUBMITTED")
          .map((file) => (
            <Grid item xs={12} sm={6} lg={3} key={file}>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#fcfcfc",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "center",
                    minWidth: "3.9rem",
                    textAlign: "left",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 800,
                      fontSize: "0.81rem",
                    }}
                    variant="body2"
                  >
                    {file}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {"needs approval"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "4.5rem",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{
                      border: "0",
                      color: "#c7d57f",
                      fontSize: "1.17rem",
                      padding: "1rem",
                    }}
                    onClick={() => handleApproveClick(file)}
                  >
                    <BsPatchCheckFill />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "4.5rem",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{
                      border: "0",
                      color: "#f98b85",
                      fontSize: "1.17rem",
                      padding: "1rem",
                    }}
                    onClick={() => handleRejectClick(file)}
                  >
                    <BsPatchMinusFill />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    );
  };

  const subtractOneDay = (dateStr) => {
    // Parse the date string to get a Date object
    const [month, day, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);

    // Subtract one day
    date.setDate(date.getDate() - 1);

    // Format the new date back to MM/dd/yyyy
    const newMonth = String(date.getMonth() + 1).padStart(2, "0");
    const newDay = String(date.getDate()).padStart(2, "0");
    const newYear = date.getFullYear();

    return `${newMonth}/${newDay}/${newYear}`;
  };

  const SubmissionDialog = ({ open, onClose, isSubmitting }) => (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "1rem",
          borderRadius: "27px",
          backgroundColor: "#f9f9f9",
        },
      }}
    >
      <DialogContent>
        {isSubmitting ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              backgroundColor: "#f9f9f9",
            }}
          >
            <CircularProgress style={{ color: "#101010" }} />{" "}
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#101010",
              fontSize: "1rem",
              fontWeight: "800",
              letterSpacing: "0.1rem",
            }}
          >
            <CircularProgress style={{ color: "#101010" }} />{" "}
          </p>
        )}
      </DialogContent>
    </Dialog>
  );

  const openEmailDialog = async (field, email) => {
    setSelectedEmailField(field);
    setSelectedEmailValue(email);

    if (email) {
      try {
        const claims = await fetchUserClaims(email);
        const adminClaim = claims[`${propertyCode} Employee Admin`] || false;
        const viewPayClaim = claims[`${propertyCode} View Pay Rates`] || false;
        const ackEmpStatusClaim =
          claims[`${propertyCode} Ack Emp Status`] || false;

        console.log("Admin Claim:", adminClaim);
        console.log("View Pay Claim:", viewPayClaim);
        console.log("Ack Emp Status Claim:", ackEmpStatusClaim);

        setSelectedAdmin(adminClaim);
        setSelectedViewPay(viewPayClaim);
        setSelectedAckEmpStatus(ackEmpStatusClaim);
      } catch (error) {
        console.error("Error fetching user claims:", error);
      }
    }

    setEmailDialogOpen(true);
    console.log("Dialog Open State:", true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchUserClaims = useCallback(async (email) => {
    const functions = getFunctions();
    const getUserClaims = httpsCallable(functions, "getUserClaims");

    try {
      const result = await getUserClaims({ email }); // Ensure the email is passed as an object
      console.log("Fetched claims:", result.data);
      return result.data;
    } catch (error) {
      console.error("Error fetching user claims:", error);
      return {};
    }
  }, []);

  const handleEmailDialogClose = async (confirm) => {
    if (confirm) {
      setEmailFields((prevFields) => ({
        ...prevFields,
        [selectedEmailField]: {
          email: selectedEmailValue,
          admin: selectedAdmin,
          viewPay: selectedViewPay,
          ackEmpStatus: selectedAckEmpStatus,
        },
      }));

      // Track changes to email and claims in the `changes` state
      setChanges((prevChanges) => ({
        ...prevChanges,
        [selectedEmailField]: {
          oldValue: originalRecordData[selectedEmailField] || "N/A",
          newValue: selectedEmailValue,
          fieldName: selectedEmailField,
        },
      }));

      try {
        if (originalRecordData[selectedEmailField] !== selectedEmailValue) {
          await updateUserClaims(originalRecordData[selectedEmailField], {
            [`${propertyCode} Employee Admin`]: false,
            [`${propertyCode} View Pay Rates`]: false,
            [`${propertyCode} Ack Emp Status`]: false,
          });
        }

        await updateUserClaims(selectedEmailValue, {
          [`${propertyCode} Employee Admin`]: selectedAdmin,
          [`${propertyCode} View Pay Rates`]: selectedViewPay,
          [`${propertyCode} Ack Emp Status`]: selectedAckEmpStatus,
        });

        setSnackbarMessage("PERMISSIONS UPDATED");
        setSnackbarSeverity("success");
      } catch (error) {
        console.error("Error updating user claims:", error);
        setSnackbarMessage(
          "Failed to update user permissions. Please try again."
        );
        setSnackbarSeverity("error");
      } finally {
        setSnackbarOpen(true);
      }
    }
    setEmailDialogOpen(false);
    console.log("Dialog Open State:", false); // Check if this is logged
  };

  const handleFormTypeChange = (event) => {
    setFormType(event.target.value);
    setRecordData(null);
    setEmployees([]);
    setSelectedEmployee("");
    setChanges({});
  };

  const handlePropertyCodeChange = (event) => {
    setPropertyCode(event.target.value);
  };

  const handleEmployeeChange = (event) => {
    const selectedEmpID = event.target.value;
    setSelectedEmployee(selectedEmpID);
    const selected = employees.find((emp) => emp.EmpID === selectedEmpID);
    console.log("Selected employee in handleEmployeeChange:", selected); // Add log
    setRecordData(selected || {});
    setChanges({});
  };

  const handleFieldChange = (
    name,
    value,
    isCheckbox = false,
    checkboxType = null
  ) => {
    if (isCheckbox) {
      setEmailFields((prevFields) => ({
        ...prevFields,
        [name]: {
          ...prevFields[name],
          [checkboxType]: value,
        },
      }));

      // Track changes to email and claims in the `changes` state
      setChanges((prevChanges) => ({
        ...prevChanges,
        [name]: {
          oldValue: originalRecordData[name] || "N/A",
          newValue: value ? emailFields[name].email : "", // If checkbox is unchecked, set newValue to empty string
          fieldName: name,
        },
      }));
    } else {
      if (originalRecordData) {
        const oldValue = originalRecordData[name] || "N/A";
        if (value !== oldValue) {
          setChanges((prevChanges) => ({
            ...prevChanges,
            [name]: {
              oldValue: oldValue, // Use the original value from originalRecordData
              newValue: value,
              fieldName: name,
            },
          }));

          if (name === "PayD1_Rate" || name === "PayD2_Rate") {
            const currentDate = new Date().toLocaleDateString();
            setChanges((prevChanges) => ({
              ...prevChanges,
              PRR: {
                oldValue: originalRecordData.PRR || "N/A",
                newValue: currentDate,
                fieldName: "PRR",
              },
            }));
            setRecordData((prevData) => ({ ...prevData, PRR: currentDate }));
          }
        } else {
          setChanges((prevChanges) => {
            const newChanges = { ...prevChanges };
            delete newChanges[name];
            return newChanges;
          });
        }
      }
      setRecordData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleDialogClose = async (confirm) => {
    if (confirm) {
      setIsSubmitting(true); // Start the submission process
      try {
        await saveChangesToDatabase();
      } catch (error) {
        console.error("Error during save changes process:", error);
        alert(`Failed to save changes: ${error.message}`);
      } finally {
        setIsSubmitting(false); // End the submission process
        setDialogOpen(false);
      }
    } else {
      setDialogOpen(false);
    }
  };

  const saveChangesToDatabase = async () => {
    if (!recordData) return;

    console.log("Saving changes to database:", changes);

    const docRef = doc(
      db,
      formType === "Property" ? "Properties" : "empRegister",
      formType === "Property" ? propertyCode : selectedEmployee
    );

    // Generate the note entry for the database
    const changeEntries = Object.values(changes)
      .filter(({ fieldName }) => !["newNote"].includes(fieldName))
      .map(
        ({ oldValue, newValue, fieldName }) =>
          `${fieldName}: ${oldValue} -> ${newValue}`
      );
    const dateStr = new Date().toLocaleDateString();
    const noteEntry = `${dateStr} - ${changeEntries.join(", ")} by ${
      user.displayName || "Unknown User"
    }`;

    // Define updatedChanges with the note included
    let updatedChanges = {
      ...Object.keys(changes).reduce((acc, key) => {
        acc[key] = changes[key].newValue;
        return acc;
      }, {}),
      Notes: [...(recordData.Notes || []), noteEntry],
      DateUpdated: new Date().toISOString(),
    };

    await updateDoc(docRef, updatedChanges);

    if (formType === "Employee") {
      const payrollChangesRef = collection(db, "payrollChanges");

      // Filter out changes that should not be written to payrollChanges
      const filteredChangeEntries = Object.values(changes)
        .filter(({ fieldName }) => !["PRR", "newNote"].includes(fieldName))
        .map(
          ({ oldValue, newValue, fieldName }) =>
            `${fieldName}: ${oldValue} -> ${newValue}`
        );

      if (filteredChangeEntries.length > 0) {
        const payPeriodEndDate =
          selectedPayPeriod === "current" ? payPeriodEnd : previousPayPeriodEnd;

        await addDoc(payrollChangesRef, {
          EmpID: recordData.EmpID,
          NameFirst: recordData.NameFirst,
          NameLast: recordData.NameLast,
          changes: filteredChangeEntries,
          payPeriod: subtractOneDay(payPeriodEndDate?.toLocaleDateString()),
          ChangedBy: user.email,
          propCode: propertyCode,
        });
      }
    }

    if (changes.hasOwnProperty("AdPhone")) {
      setNewPhoneNumber(changes.AdPhone.newValue);
      setPhoneDialogOpen(true);
    } else {
      await updateDoc(docRef, updatedChanges);
      alert("Changes saved!");
      setChanges({});
      setDialogOpen(false);
      setEmailClaimsChanged(false); // Reset email claims changed flag
    }
  };

  const handlePhoneDialogClose = async (confirm) => {
    if (confirm) {
      await handlePhoneChange(newPhoneNumber);
    }
    setPhoneDialogOpen(false);
  };

  // Function to handle phone number changes
  const handlePhoneChange = async (newPhoneNumber) => {
    setIsSubmitting(true);

    const oldPhoneNumber = recordData.AdPhone;
    const currentDate = new Date().toISOString();
    const empID = selectedEmployee; // Assuming `selectedEmployee` is the ID of the current employee record

    try {
      // Update the current employee record with the new phone number
      const empDocRef = doc(db, "empRegister", empID);
      await updateDoc(empDocRef, {
        AdPhone: newPhoneNumber,
        AdEmail: "", // Set AdEmail to blank strings
      });

      // Update phoneAuthUsers collection
      const oldUserDocRef = doc(db, "phoneAuthUsers", oldPhoneNumber);
      const oldUserDoc = await getDoc(oldUserDocRef);
      if (oldUserDoc.exists()) {
        const userData = oldUserDoc.data();
        const updatedData = {
          ...userData,
          phoneNumber: newPhoneNumber,
          phoneChangeDate: currentDate, // Record the date of the change
        };

        await deleteDoc(oldUserDocRef);

        // Send an axios post request with the necessary information
        const apiGatewayUrl =
          "https://prod-55.westus.logic.azure.com:443/workflows/bb84288c70994588a9c17e1ca12f6cd6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=UqBgANaK9RdtLIRKmhzOZiicFF-A56rXvCqyYDu39zY";
        await axios.post(apiGatewayUrl, {
          oldPhoneNumber: oldPhoneNumber,
          newPhoneNumber: newPhoneNumber,
          empIDs: [empID], // Assuming `empID` is the only ID we need to update
          phoneChangeDate: updatedData.phoneChangeDate,
        });

        // Add notes to the empRegister collection for the current employee
        const empDoc = await getDoc(empDocRef);
        if (empDoc.exists()) {
          const empData = empDoc.data();
          const notes = empData.Notes || [];
          notes.push(
            `User phone number change on ${currentDate}: ${oldPhoneNumber} > ${newPhoneNumber}`
          );

          await updateDoc(empDocRef, { Notes: notes });
        }
      }

      // Inform the user
      alert("Phone number updated successfully!");
      setChanges({});
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating phone number:", error);
      alert(
        "An error occurred while updating the phone number. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to update user claims
  const updateUserClaims = async (email, newClaims) => {
    try {
      // Fetch existing claims
      const existingClaims = await fetchUserClaims(email);
      const mergedClaims = { ...existingClaims, ...newClaims };

      const response = await fetch(
        "https://us-central1-doorman-1985.cloudfunctions.net/updateUserClaims",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, claims: mergedClaims }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${
            response.status
          }, message: ${await response.text()}`
        );
      }

      const data = await response.json();
      console.log("User claims updated successfully:", data);
    } catch (error) {
      console.error("Error updating user claims:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (!user || !user.email) return;

    const fetchProperties = async () => {
      if (!propertyCode || !user) return;

      const propertiesDoc = await getDoc(doc(db, "Properties", propertyCode));
      const { payPeriod, payCycle } = propertiesDoc.data();
      const currentDate = new Date();
      let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
      let payPeriodEndDate;
      let prevPayPeriodStartDate;
      let prevPayPeriodEndDate;

      const calculatePayPeriod = (start, interval) => {
        const end = new Date(start);
        end.setDate(start.getDate() + interval);
        return [start, end];
      };

      switch (payCycle) {
        case "Monthly":
          while (payPeriodStartDate < currentDate) {
            [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
              payPeriodStartDate,
              30
            );
            if (payPeriodEndDate > currentDate) break;
            prevPayPeriodStartDate = payPeriodStartDate;
            prevPayPeriodEndDate = payPeriodEndDate;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Bi-Weekly":
          while (payPeriodStartDate < currentDate) {
            [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
              payPeriodStartDate,
              14
            );
            if (payPeriodEndDate > currentDate) break;
            prevPayPeriodStartDate = payPeriodStartDate;
            prevPayPeriodEndDate = payPeriodEndDate;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Weekly":
          while (payPeriodStartDate < currentDate) {
            [payPeriodStartDate, payPeriodEndDate] = calculatePayPeriod(
              payPeriodStartDate,
              7
            );
            if (payPeriodEndDate > currentDate) break;
            prevPayPeriodStartDate = payPeriodStartDate;
            prevPayPeriodEndDate = payPeriodEndDate;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        default:
          break;
      }

      const userClaims = await fetchUserClaims(user.email);
      const isAdmin = userClaims[`${propertyCode} Employee Admin`] || false;
      const canViewPayRates =
        userClaims[`${propertyCode} View Pay Rates`] || false;

      setPayPeriodStart(payPeriodStartDate);
      setPayPeriodEnd(payPeriodEndDate);
      setPreviousPayPeriodStart(prevPayPeriodStartDate);
      setPreviousPayPeriodEnd(prevPayPeriodEndDate);

      setCanViewPay(
        user.photoURL.includes("Regional") ||
          user.photoURL.includes("Accounting") ||
          user.photoURL.includes("Global") ||
          (isAdmin && canViewPayRates)
      );
      setCanViewSalary(
        user.photoURL.includes("Accounting") || user.photoURL.includes("Global")
      );
      setCanViewPayroll(
        user.photoURL.includes("Accounting") ||
          user.photoURL.includes("Global") ||
          user.photoURL.includes("Manager") ||
          user.photoURL.includes("Regional") ||
          user.photoURL.includes("OtherAdmin")
      );
    };

    fetchProperties();
  }, [propertyCode, user, fetchUserClaims]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    } else {
      setFileName("");
      setFile(null);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
  };

  const handleFileUpload = async () => {
    if (!file || selectedCategories.length === 0) return;

    setIsSubmitting(true);
    const fileExtension = file.name.split(".").pop();
    const now = new Date();
    const formattedDate = `${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}_${String(now.getDate()).padStart(2, "0")}_${now.getFullYear()}`;

    const getCategoryPrefix = (categories) => {
      const priority = [
        "BANKING",
        "W4-IRS",
        "W4-LOCAL",
        "ID",
        "SSN",
        "OTHER",
        "CERTIFICATE",
        "WRITE-UP",
      ];
      for (const prefix of priority) {
        if (categories.includes(prefix)) return prefix;
      }
      return categories[0];
    };

    const categoryPrefix = getCategoryPrefix(selectedCategories);
    const otherCategories = selectedCategories.filter(
      (category) => category !== categoryPrefix
    );

    const newFileName = `${categoryPrefix}_${otherCategories.join(
      "_"
    )}_${formattedDate}.${fileExtension}`;

    const folderName = `${recordData.NameFirst} ${recordData.NameLast}-${recordData.EmpID}`;
    const mainFolderId = "1Bn8FFDHh_1Wd5TPIsq4LdXfqPeaNkw3D"; // Main folder ID

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", newFileName);
    formData.append("EmpID", recordData.EmpID);
    formData.append("PropID", propertyCode);
    formData.append("folderName", folderName); // Append the folder name to the form data
    formData.append("mainFolderId", mainFolderId); // Append the main folder ID to the form data

    try {
      const response = await fetch(
        "https://us-central1-doorman-1985.cloudfunctions.net/uploadFile",
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("File uploaded successfully!", data);
        const payrollChangesRef = collection(db, "payrollChanges");

        await addDoc(payrollChangesRef, {
          EmpID: recordData.EmpID,
          NameFirst: recordData.NameFirst,
          NameLast: recordData.NameLast,
          changes: [`File Uploaded: ${newFileName}`],
          payPeriod: subtractOneDay(payPeriodEnd?.toLocaleDateString()),
          ChangedBy: user.email,
          propCode: propertyCode,
        });

        // Update the employee document for each relevant category
        const empDocRef = doc(db, "empRegister", recordData.EmpID);
        const updateData = selectedCategories.reduce((acc, category) => {
          if (
            ["W4-IRS", "W4-LOCAL", "ID", "SSN", "BANKING"].includes(category)
          ) {
            acc[category] = "SUBMITTED";
          }
          return acc;
        }, {});

        if (Object.keys(updateData).length > 0) {
          await updateDoc(empDocRef, {
            ...updateData,
            clearedBy: [],
            DateUpdated: new Date().toISOString(), // Add this line to set DateUpdated
          });
        }

        alert("File uploaded successfully!");
        setFile(null);
        setSelectedCategories([]);
        setReloadTrigger((prev) => prev + 1);
      } else {
        console.error("File upload failed:", response.statusText);
        alert(
          `File upload failed: ${response.statusText || "An error occurred."}`
        );
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      alert(`Error uploading file: ${error.message || "An error occurred."}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddNote = async () => {
    if (!newNote) return;

    const dateStr = new Date().toLocaleDateString();
    const noteEntry = `${dateStr} - ${newNote} by ${
      user.displayName || "Unknown User"
    }`;

    const updatedNotes = [...(recordData.Notes || []), noteEntry];

    const docRef = doc(db, "empRegister", selectedEmployee);
    await updateDoc(docRef, { Notes: updatedNotes });

    setRecordData((prevData) => ({
      ...prevData,
      Notes: updatedNotes,
    }));
    setNewNote("");
    alert("Note added successfully!");
  };

  const renderSelectRecordType = () => {
    const isGlobalOrRegionalOrAccounting =
      user.photoURL.includes("Global") ||
      user.photoURL.includes("Regional") ||
      user.photoURL.includes("Accounting");
    const showPropertyOption = isGlobalOrRegionalOrAccounting;
    const showEmployeeOption =
      isGlobalOrRegionalOrAccounting || hasPropertyAccess;

    return (
      <FormControl
        sx={{
          minWidth: "15rem",
          "@media (max-width: 600px)": {
            minWidth: "9rem",
          },
        }}
      >
        <InputLabel
          sx={{
            fontSize: "0.9rem",
            fontWeight: "800",
          }}
        >
          UPDATE
        </InputLabel>
        <Select
          value={formType}
          onChange={handleFormTypeChange}
          label="Select Record Type"
          sx={{
            fontSize: "0.9rem",
            backgroundColor: "#fcfcfc",
            minWidth: "10em",
            borderRadius: "27px",
            fontWeight: "800",
          }}
        >
          <MenuItem
            sx={{ fontSize: "0.9rem", fontWeight: "800" }}
            value=""
            disabled
          >
            UPDATE
          </MenuItem>
          {showEmployeeOption && (
            <MenuItem
              sx={{ fontSize: "0.9rem", fontWeight: "400" }}
              value="Employee"
            >
              EMPLOYEE
            </MenuItem>
          )}
          {showPropertyOption && (
            <MenuItem
              sx={{ fontSize: "0.9rem", fontWeight: "400" }}
              value="Property"
            >
              PROPERTY
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  };

  const categorizeFiles = (files) => {
    const categories = {
      PAYROLL: [],
      ACCOUNTING: [],
      OTHER: [],
    };

    files.forEach((file) => {
      const fileName = file.name.toUpperCase();
      if (
        fileName.startsWith("W4-IRS") ||
        fileName.startsWith("W4-LOCAL") ||
        fileName.startsWith("ID") ||
        fileName.startsWith("SSN")
      ) {
        categories.PAYROLL.push(file);
      } else if (fileName.startsWith("BANKING")) {
        categories.ACCOUNTING.push(file);
      } else if (
        fileName.startsWith("OTHER") ||
        fileName.startsWith("CERTIFICATE") ||
        fileName.startsWith("WRITE")
      ) {
        categories.OTHER.push(file);
      } else {
        categories.OTHER.push(file);
      }
    });

    return categories;
  };

  const FilingCabinet = ({
    employeeFiles,
    canViewPay,
    CanViewSalary,
    user,
  }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const categories = categorizeFiles(employeeFiles);

    return (
      <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
        <Grid
          item
          xs={12}
          container
          sx={{
            padding: "1rem",
            borderRadius: "36px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              marginLeft: 1,
              letterSpacing: "0.3rem",
              fontWeight: "200",
              fontSize: "0.9rem",
              color: "#101010",
              paddingBottom: "1rem",
              paddingTop: "1rem",
            }}
          >
            FILING CABINET
          </Typography>

          <Grid container spacing={12}>
            <Grid item xs={12}>
              <Card sx={{ borderRadius: "18px" }}>
                <Box
                  sx={{
                    padding: "1rem",
                    backgroundColor: "#fcfcfc",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="green"
                    textColor="inherit"
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#74c365",
                      },
                      "& .MuiTab-root": {
                        fontWeight: 800,
                        fontSize: "0.81rem",
                      },
                    }}
                  >
                    <Tab
                      label={
                        <Badge
                          badgeContent={submittedCounts.payroll}
                          color="error"
                          overlap="rectangular"
                        >
                          PAYROLL
                        </Badge>
                      }
                    />
                    <Tab
                      label={
                        <Badge
                          badgeContent={submittedCounts.banking}
                          color="error"
                          overlap="rectangular"
                        >
                          BANKING
                        </Badge>
                      }
                    />

                    <Tab label="OTHER" />
                  </Tabs>
                </Box>
                <Box height={16} />
                {value === 0 && canViewPayroll && (
                  <Box backgroundColor="#fcfcfc">
                    {submittedCounts.payroll > 0 && (
                      <Box>
                        {renderApprovalButtons("PAYROLL", [
                          "W4-IRS",
                          "W4-LOCAL",
                          "ID",
                          "SSN",
                        ])}
                      </Box>
                    )}
                    <Grid
                      container
                      direction={{ xs: "column", sm: "row" }}
                      alignItems="center"
                      spacing={2}
                      backgroundColor="#fcfcfc"
                      sx={{}}
                      padding={3}
                    >
                      <FileList files={categories.PAYROLL} />
                    </Grid>
                  </Box>
                )}
                {value === 1 && canViewPayroll && (
                  <Box>
                    {submittedCounts.banking > 0 && (
                      <Box>{renderApprovalButtons("BANKING", ["BANKING"])}</Box>
                    )}
                    <Grid
                      container
                      direction={{ xs: "column", sm: "row" }}
                      alignItems="center"
                      spacing={2}
                      backgroundColor="#fcfcfc"
                      sx={{}}
                      padding={3}
                    >
                      <FileList files={categories.ACCOUNTING} />
                    </Grid>
                  </Box>
                )}
                {value === 2 && canViewPay && (
                  <Box>
                    <Grid
                      container
                      direction={{ xs: "column", sm: "row" }}
                      alignItems="center"
                      spacing={2}
                      backgroundColor="#fcfcfc"
                      sx={{}}
                      padding={3}
                    >
                      <FileList files={categories.OTHER} />
                    </Grid>
                  </Box>
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <SubmissionDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setReloadTrigger((prev) => prev + 1);
          }}
          isSubmitting={isSubmitting}
        />
      </Grid>
    );
  };

  const FileList = ({ files }) => {
    const getFileIcon = (fileName) => {
      const ext = fileName.split(".").pop().toLowerCase();
      const iconStyle = { marginRight: "0.72rem", fontSize: "1.53rem" };

      if (ext === "pdf")
        return <FaFilePdf style={{ ...iconStyle, color: "#FF0000" }} />;
      if (["jpg", "jpeg", "png"].includes(ext))
        return <FaFileImage style={{ ...iconStyle, color: "#1976d2" }} />;
      return null;
    };

    return files.length > 0 ? (
      <Box
        component="ul"
        sx={{
          listStyleType: "none",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "1rem",
          padding: 0,
          margin: 0,
        }}
      >
        {files.map((file) => (
          <Box
            component="li"
            key={file.id}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              onClick={async (e) => {
                setOpenDialog(true);
                e.preventDefault();
                try {
                  const response = await fetch(
                    `${YOUR_FIREBASE_FUNCTION_URL}/getSignedUrl?fileId=${file.id}`
                  );
                  const textResponse = await response.text();
                  console.log("Raw response:", textResponse);

                  if (!response.ok) {
                    throw new Error("Failed to get signed URL");
                  }

                  const data = JSON.parse(textResponse);
                  console.log("Signed URL data:", data);

                  if (!data.signedUrl) {
                    throw new Error("Invalid response from signed URL API");
                  }

                  window.open(data.signedUrl, "_blank");
                  setOpenDialog(false);
                } catch (error) {
                  console.error("Error fetching signed URL:", error.message);
                }
              }}
              sx={{
                backgroundColor: "transparent",
                borderRadius: "9px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "400",
                fontSize: "0.81rem",
                letterSpacing: "0.1rem",
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  opacity: "0.6425339366515838",
                  color: "#010101",
                },
              }}
            >
              {getFileIcon(file.name)}
              {file.name}
            </Button>
          </Box>
        ))}
      </Box>
    ) : (
      <Typography
        sx={{
          fontSize: "0.9rem",
          fontWeight: 800,
          color: "#101010",
          paddingLeft: 3,
          paddingBottom: 4.5,
        }}
      >
        No files found.
      </Typography>
    );
  };

  const getSectionForField = (field, canViewSalary) => {
    const personalSection = [
      ...(canViewSalary ? ["NameFirst", "NameMid", "NameLast", "SS4"] : []),
      "DOB",
      "EmpID",
      "Ad1",
      "AdCity",
      "AdState",
      "AdZip",
      "AdPhone",
      "Email",
    ];

    const sections = {
      PERSONAL: personalSection,
      "PAY D1": ["PayD1", "PayD1_Type", "PayD1_Rate", "PR2", "PR3"],
      "PAY D2": ["PayD2", "PayD2_Type", "PayD2_Rate"],
      OTHER: ["DateHired", "timeclock", "PRR", "Notes"],
      "PROPERTY INFO": [
        "propCode",
        "propertyName",
        "rms1",
        "rms2",
        "rms3",
        "rmsMTG",
        "rmsTotal",
        "propNewBuildDate",
        "propRenovate",
      ],
      "ADMIN DETAILS": [
        "Manager",
        "ManagerEmail",
        "AGM",
        "AGMEmail",
        "OtherAdmin",
        "OtherAdminEmail",
      ],
    };

    for (const [section, fields] of Object.entries(sections)) {
      if (fields.includes(field)) {
        return section;
      }
    }
    return "OTHER";
  };

  const handleAdminEmailChange = (field, value) => {
    if (value !== recordData[field]) {
      handleFieldChange(field, value);
      setEmailFields((prevFields) => ({
        ...prevFields,
        [field]: {
          ...prevFields[field],
          email: value,
        },
      }));
    }
  };

  const renderAdminEmailField = (name, label, emailField) => {
    const isReadOnly =
      emailField === "AccountingEmail" || emailField === "RegionalEmail";

    const shouldHideField =
      isReadOnly &&
      !(
        user.photoURL.includes("Regional") ||
        user.photoURL.includes("Accounting") ||
        user.photoURL.includes("Global")
      );

    if (shouldHideField) {
      return null;
    }

    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={label}
            variant="outlined"
            name={name}
            value={recordData[name]}
            onChange={(e) => handleFieldChange(name, e.target.value)}
            InputProps={{
              readOnly: isReadOnly,
            }}
            sx={{ ...commonSx }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            name={emailField}
            value={recordData[emailField]}
            onChange={(e) =>
              handleAdminEmailChange(emailField, e.target.value.toLowerCase())
            }
            InputProps={{
              readOnly: isReadOnly,
            }}
            sx={{ ...commonSx }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            onClick={() => openEmailDialog(emailField, recordData[emailField])}
            disabled={
              !recordData[emailField] ||
              Boolean(changes[name]) ||
              Boolean(changes[emailField])
            }
            sx={{
              backgroundColor: "transparent",
              borderRadius: "9px",
              padding: "9px 9px 9px 9px",
              color: "#101010",
              fontWeight: "400",
              fontSize: "0.72rem",
              border: "2px solid #101010",

              "&:hover": {
                backgroundColor: "#101010",
                opacity: "0.6425339366515838",
                color: "#f9f9f9",
              },
            }}
          >
            PERMISSIONS
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderAdminEmailFields = () => {
    const emailFieldsConfig = [
      { name: "Manager", label: "Manager", emailField: "ManagerEmail" },
      { name: "AGM", label: "AGM", emailField: "AGMEmail" },
      {
        name: "OtherAdmin",
        label: "Other Admin",
        emailField: "OtherAdminEmail",
      },
    ];

    return emailFieldsConfig.map(({ name, label, emailField }) =>
      renderAdminEmailField(name, label, emailField)
    );
  };

  const renderForm = () => {
    if (!recordData) return null;

    const hiddenFields = [
      "Id",
      "gender",
      "faceid",
      "DateUpdated",
      "timeSheetID",
      "collection",
      "propName",
      "timeTableID",
      "PropID",
      "LinkUpdate",
      "DateCreated",
      "AdEmail",
      "ID",
      "age",
      "enrollDate",
      "peachTreeID",
      "Background",
    ];

    const readOnlyFields = [
      "EmpID",
      "PRR",
      "Status",
      "enrollDate",
      "timeclock",
      "DateHired",
    ];

    const payTypes = [
      "BARTENDER",
      "BREAKFAST",
      "CONVENTION",
      "F&B",
      "FRONT DESK",
      "HOUSEKEEPING",
      "HOUSEKEEPING /HR",
      "LAUNDRY",
      "MANAGEMENT",
      "MAINTENANCE",
      "SALES",
      "SHUTTLE",
      "N/A",
    ];

    const payD1Types = ["PER HOUR", "PER ROOM", "SALARY"];
    const payD2Types = ["PER HOUR", "SALARY", "N/A"];

    const firstSection = [
      { name: "NameFirst", label: "First Name" },
      { name: "NameMid", label: "Middle Name" },
      { name: "NameLast", label: "Last Name" },
      { name: "EmpID", label: "Employee ID" },
      { name: "SS4", label: "SSN" },
      { name: "DOB", label: "Date of Birth" },
      { name: "Ad1", label: "Address" },
      { name: "AdCity", label: "City" },
      { name: "AdState", label: "State" },
      { name: "AdZip", label: "Zip Code" },
      { name: "AdPhone", label: "Phone" },
      { name: "Email", label: "Email" },
    ];

    const payD1Section = [
      { name: "PayD1", label: "Department 1" },
      { name: "PayD1_Type", label: "Pay Type" },
      { name: "PayD1_Rate", label: "Pay Rate" },
      { name: "PR2", label: "Per Room 2" },
      { name: "PR3", label: "Per Room 3" },
    ];

    const payD2Section = [
      { name: "PayD2", label: "Department 2" },
      { name: "PayD2_Type", label: "Pay Type" },
      { name: "PayD2_Rate", label: "Pay Rate" },
    ];

    const thirdSection = [
      { name: "DateHired", label: "Date Hired" },
      { name: "timeclock", label: "Punch Clock Enrolled" },
      { name: "PRR", label: "Pay Rate Reviewed" },
      { name: "Notes", label: "Notes", multiline: true, rows: 4 },
    ];

    const propertySectionReadOnly = [
      { name: "propCode", label: "Property Code" },
      { name: "propertyName", label: "Property Name" },
      { name: "rms1", label: "RMS 1" },
      { name: "rms2", label: "RMS 2" },
      { name: "rms3", label: "RMS 3" },
      { name: "rmsMTG", label: "RMS MTG" },
      { name: "rmsTotal", label: "RMS Total" },
      { name: "propNewBuildDate", label: "New Build Date" },
      { name: "propRenovate", label: "Renovate Date" },
    ];

    const renderCustomSection = (section, sectionName, readOnly = false) => {
      return section.map(({ name, label, isCheckbox, multiline, rows }) => {
        // Don't render if field doesn't exist or is hidden
        if (hiddenFields.includes(name)) {
          return null;
        }

        // Handle PR2 and PR3 visibility based on PayD1_Type
        if (
          (name === "PR2" || name === "PR3") &&
          recordData["PayD1_Type"] !== "PER ROOM"
        ) {
          return null;
        }

        const renderField = () => {
          if (isCheckbox) {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTerminated}
                    onChange={(e) => setIsTerminated(e.target.checked)}
                  />
                }
                label={label}
              />
            );
          }

          if (name === "DateHired") {
            const formattedDate = recordData[name]
              ? format(new Date(recordData[name]), "MM/dd/yyyy")
              : "";

            return (
              <TextField
                fullWidth
                label={label}
                variant="outlined"
                name={name}
                value={formattedDate}
                onChange={(e) => handleFieldChange(name, e.target.value)} // Update the field if needed
                InputProps={{
                  readOnly: true,
                  shrink: true,
                }}
                sx={{ ...commonSx }}
              />
            );
          }

          if (name === "DOB") {
            const handleDOBChange = (date) => {
              const formattedDate = date ? format(date, "MM/dd/yyyy") : null;
              handleFieldChange(name, formattedDate); // Update with formatted date
            };

            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{ ...commonSx }}
                  label={label}
                  inputFormat="MM/dd/yyyy" // Display format
                  value={recordData[name] ? new Date(recordData[name]) : null}
                  onChange={handleDOBChange}
                  readOnly={employeeStatus === "Inactive"}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ ...commonSx }} />
                  )}
                />
              </LocalizationProvider>
            );
          }

          if (name === "AdState") {
            return (
              <FormControl fullWidth sx={{ ...commonSx }}>
                <InputLabel>{label}</InputLabel>
                <Select
                  value={recordData[name]}
                  onChange={(e) => handleFieldChange(name, e.target.value)}
                >
                  {US_STATES.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }

          if (name === "AdPhone") {
            const handlePhoneChange = (values) => {
              let { value } = values;

              // Remove non-numeric characters
              value = value.replace(/[^0-9]/g, "");

              // Ensure it starts with a "1" and is followed by exactly 10 digits
              if (value.length > 0 && !value.startsWith("1")) {
                value = "1" + value;
              }

              if (value.length > 11) {
                value = value.slice(0, 11);
              }

              handleFieldChange(name, value);
            };

            // Ensure the phone number is formatted correctly
            let formattedPhoneNumber = recordData[name];
            if (formattedPhoneNumber) {
              formattedPhoneNumber = formattedPhoneNumber.replace(
                /[^0-9]/g,
                ""
              );
              if (!formattedPhoneNumber.startsWith("1")) {
                formattedPhoneNumber = "1" + formattedPhoneNumber;
              }
              if (formattedPhoneNumber.length > 11) {
                formattedPhoneNumber.slice(0, 11);
              }
            }

            return (
              <NumericFormat
                customInput={TextField}
                format="1##########"
                mask="_"
                fullWidth
                label={label}
                value={recordData[name]}
                onValueChange={handlePhoneChange}
                sx={{ ...commonSx }}
                InputProps={{
                  readOnly: recordData.Status !== "Terminated",
                }}
              />
            );
          }

          if (name === "SS4") {
            const formatSSN = (ssn) => {
              if (ssn.length === 4) return ssn;
              if (ssn.length === 9) return `*****${ssn.slice(-4)}`;
              if (ssn.length === 11 && ssn.includes("-"))
                return `*****-${ssn.slice(-4)}`;
              return ssn;
            };

            return (
              <TextField
                fullWidth
                label={label}
                value={formatSSN(recordData[name] || "")}
                onChange={(e) => handleFieldChange(name, e.target.value)}
                InputProps={{}}
                sx={{ ...commonSx }}
              />
            );
          }

          if (
            name === "PayD1" ||
            name === "PayD2" ||
            name === "PayD1_Type" ||
            name === "PayD2_Type"
          ) {
            const options =
              name === "PayD1"
                ? payTypes.filter((type) => type !== "HOUSEKEEPING /HR")
                : name === "PayD2"
                ? payTypes.filter((type) => type !== "HOUSEKEEPING")
                : name === "PayD1_Type"
                ? payD1Types
                : payD2Types;

            const initialValue = recordData[name] || "";

            return (
              <FormControl fullWidth sx={{ ...commonSx }}>
                <InputLabel>{label}</InputLabel>
                <Select
                  value={initialValue}
                  onChange={(e) => handleFieldChange(name, e.target.value)}
                >
                  {options.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }

          if (["PayD1_Rate", "PayD2_Rate", "PR2", "PR3"].includes(name)) {
            return (
              <NumericFormat
                customInput={TextField}
                thousandSeparator
                prefix="$"
                fullWidth
                label={label}
                value={recordData[name]}
                onValueChange={(values) => {
                  const { value } = values;
                  handleFieldChange(name, value);
                }}
                sx={{ ...commonSx }}
              />
            );
          }

          if (name === "PRR") {
            const formattedDate = recordData[name]
              ? format(new Date(recordData[name]), "MM-dd-yyyy")
              : "";

            return (
              <TextField
                fullWidth
                label={label}
                variant="outlined"
                name={name}
                value={formattedDate}
                InputProps={{ readOnly: true }}
                sx={{ ...commonSx }}
              />
            );
          }

          const isInactiveEmployee =
            formType === "Employee" && employeeStatus === "Inactive";
          const isRehireButton =
            name === "Status" && recordData.Status === "Terminated";

          if (sectionName === "OTHER" && name === "Notes") {
            return null;
          }

          return (
            <TextField
              fullWidth
              label={label}
              variant="outlined"
              name={name}
              value={recordData[name] || ""}
              onChange={(e) => handleFieldChange(name, e.target.value)}
              InputProps={{
                readOnly:
                  (isInactiveEmployee && !isRehireButton) ||
                  readOnly ||
                  (formType === "Employee" && readOnlyFields.includes(name)),
                multiline: multiline || false,
                rows: rows || 1,
              }}
              sx={{ ...commonSx }}
            />
          );
        };

        return (
          <Grid item xs={12} sm={4} key={name}>
            {renderField()}
          </Grid>
        );
      });
    };

    return (
      <Grid container spacing={2} paddingTop={4.5} paddingLeft={4.5}>
        {formType === "Employee" && (
          <>
            <Grid
              item
              xs={12}
              container
              sx={{
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  marginLeft: 1,
                  letterSpacing: "0.3rem",
                  fontWeight: "200",
                  fontSize: "0.9rem",
                  color: "#101010",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                PERSONAL
              </Typography>
              <Grid item xs={12} container justifyContent="left">
                {renderCustomSection(
                  firstSection,
                  "PERSONAL",
                  recordData.Status === "Terminated"
                )}
              </Grid>
            </Grid>

            {canViewPay && (
              <>
                <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{
                      padding: "1rem",
                      borderRadius: "36px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#101010",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      PAY DEPARTMENT 1
                    </Typography>

                    <Grid item xs={12} container>
                      {renderCustomSection(
                        payD1Section,
                        "PAY D1",
                        recordData.Status === "Terminated"
                      )}
                    </Grid>

                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#101010",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      PAY DEPARTMENT 2
                    </Typography>

                    <Grid item xs={12} container>
                      {renderCustomSection(
                        payD2Section,
                        "PAY D2",
                        recordData.Status === "Terminated"
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* UPLOAD FILE */}

            <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    letterSpacing: "0.3rem",
                    fontWeight: "200",
                    fontSize: "0.9rem",
                    color: "#101010",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  UPLOAD FILE
                </Typography>

                <Grid container spacing={12}>
                  {/* Checkboxes and Upload Functions */}
                  <Grid item xs={12}>
                    <Grid container>
                      {fileCategories.map((category) => (
                        <Grid item xs={6} key={category}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedCategories.includes(category)}
                                onChange={() => handleCategoryChange(category)}
                              />
                            }
                            label={category}
                          />
                        </Grid>
                      ))}
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      paddingTop={3}
                    >
                      <Grid item>
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          onChange={handleFileChange}
                          id="file-upload"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="file-upload">
                          <Button
                            component="span"
                            variant="contained"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "9px",
                              padding: "9px 9px 9px 9px",
                              color: "#101010",
                              fontWeight: "400",
                              fontSize: "0.72rem",
                              border: "2px solid #101010",

                              "&:hover": {
                                backgroundColor: "#101010",
                                opacity: "0.6425339366515838",
                                color: "#f9f9f9",
                              },
                            }}
                          >
                            CHOOSE FILE
                          </Button>
                        </label>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            marginLEFT: 9,
                            fontWeight: "400",
                            fontSize: "0.8rem",
                            color: "#101010",
                          }}
                        >
                          {fileName ||
                            "Select file categories above, then upload file"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleFileUpload}
                          disabled={!file || selectedCategories.length === 0}
                          sx={{
                            backgroundColor: "transparent",
                            borderRadius: "9px",
                            padding: "9px 9px 9px 9px",
                            color: "#101010",
                            fontWeight: "400",
                            fontSize: "0.99rem",
                          }}
                        >
                          <FaFileUpload style={{ marginRight: "9px" }} />
                          UPLOAD
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* FILING CABINET */}

            <FilingCabinet
              employeeFiles={employeeFiles}
              canViewPay={canViewPay}
              user={user}
            />

            {/* Notes Section */}

            <Grid
              container
              spacing={2}
              paddingTop={9}
              paddingLeft={2}
              paddingBottom={3}
            >
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Grid item xs={12} container>
                  {renderCustomSection(
                    thirdSection,
                    "OTHER",
                    recordData.Status === "Terminated"
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    letterSpacing: "0.3rem",
                    fontWeight: "200",
                    fontSize: "0.9rem",
                    color: "#101010",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  EMPLOYEE NOTES
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Add Note"
                      variant="outlined"
                      name="newNote"
                      value={newNote}
                      onChange={(e) => setNewNote(e.target.value)}
                      multiline
                      rows={4}
                      borderRadius={5}
                    />
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      onClick={handleAddNote}
                      disabled={!newNote}
                      sx={{
                        backgroundColor: "transparent",
                        borderRadius: "9px",
                        padding: "9px 9px 9px 9px",
                        color: "#101010",
                        fontWeight: "400",
                        fontSize: "0.72rem",
                        border: "2px solid #101010",

                        "&:hover": {
                          backgroundColor: "#101010",
                          opacity: "0.6425339366515838",
                          color: "#f9f9f9",
                        },
                      }}
                    >
                      ADD NOTE
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#101010",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      EXISTING NOTES
                    </Typography>
                    {recordData.Notes && (
                      <ul>
                        {recordData.Notes.map((note, index) => (
                          <li key={index}>{note}</li>
                        ))}
                      </ul>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              paddingTop={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => setDialogOpen(true)}
                  disabled={
                    Object.keys(changes).length === 0 ||
                    recordData.Status === "Terminated" ||
                    emailClaimsChanged // Disable if only email claims changed
                  }
                  sx={{
                    backgroundColor: "#c7d57f",
                    borderRadius: "27px",
                    padding: "9px 9px 9px 9px",
                    color: "#101010",
                    fontWeight: "400",
                    fontSize: "0.9rem",
                    border: "2px solid #f9f9f9",

                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#010101",
                    },
                  }}
                >
                  SAVE CHANGES
                </Button>
              </Grid>
              <Grid item>
                {recordData.Status !== "Terminated" ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setConfirmationType("Terminate");
                      setConfirmDialogOpen(true);
                    }}
                    sx={{
                      backgroundColor: "#F98B85",
                      borderRadius: "27px",
                      padding: "9px 9px 9px 9px",
                      color: "#101010",
                      fontWeight: "400",
                      fontSize: "0.9rem",
                      border: "2px solid #f9f9f9",

                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#101010",
                      },
                    }}
                  >
                    TERMINATE
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setConfirmationType("Rehire");
                      setConfirmDialogOpen(true);
                    }}
                    sx={{
                      backgroundColor: "#c7d57f",
                      borderRadius: "27px",
                      padding: "9px 9px 9px 9px",
                      color: "#101010",
                      fontWeight: "400",
                      fontSize: "0.9rem",
                      border: "2px solid #101010",

                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#010101",
                      },
                    }}
                  >
                    RE-HIRE EMPLOYEE
                  </Button>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {formType === "Property" && (
          <>
            <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    letterSpacing: "0.3rem",
                    fontWeight: "200",
                    fontSize: "0.9rem",
                    color: "#101010",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  PREPERTY INFO
                </Typography>
                <Grid container spacing={2}>
                  {renderCustomSection(
                    propertySectionReadOnly,
                    "PROPERTY INFO",
                    true
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} paddingTop={9} paddingLeft={2}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    letterSpacing: "0.3rem",
                    fontWeight: "200",
                    fontSize: "0.9rem",
                    color: "#101010",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  PROPERTY ADMIN
                </Typography>

                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    padding: "1rem",
                    borderRadius: "36px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  {renderAdminEmailFields()}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {formType === "Property" && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(true)}
              disabled={
                Object.keys(changes).length === 0 ||
                recordData.Status === "Terminated" ||
                emailClaimsChanged // Disable if only email claims changed
              }
              sx={{
                backgroundColor: "#c7d57f",
                borderRadius: "27px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "400",
                fontSize: "0.9rem",
                border: "2px solid #f9f9f9",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#010101",
                },
              }}
            >
              SAVE CHANGES
            </Button>
          </Grid>
        )}

        <Dialog
          open={dialogOpen}
          onClose={() => handleDialogClose(false)}
          PaperProps={{
            sx: {
              padding: "1rem",
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <DialogTitle
            sx={{
              letterSpacing: "0.2rem",
              fontWeight: "400",
              fontSize: "1.2rem",
              color: "#ffffff",
              backgroundColor: "#4a6741",
              borderRadius: "18px",
            }}
          >
            CONFIRM CHANGES
          </DialogTitle>
          <DialogContent
            sx={{
              letterSpacing: "0.4rem",
              fontWeight: "400",
              fontSize: "1rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} container>
                {formType === "Employee" && (
                  <>
                    <Grid
                      item
                      sx={{
                        marginLEFT: 9,
                        letterSpacing: "0.3rem",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                        color: "#101010",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      {Object.keys(changes).map((field) => {
                        const { oldValue, newValue, fieldName } =
                          changes[field];
                        return (
                          <Typography variant="body2" key={field}>
                            {`${fieldName}: ${oldValue} -> ${newValue} (Section: ${getSectionForField(
                              field
                            )})`}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        marginLEFT: 9,
                        letterSpacing: "0.1rem",
                        fontWeight: "400",
                        fontSize: "0.9rem",
                        color: "#101010",
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      WHICH PERIOD DOES THIS CHANGE BELONG TO?
                    </Typography>

                    <FormControl fullWidth>
                      <InputLabel
                        sx={{
                          marginLEFT: 9,
                          letterSpacing: "0.1rem",
                          fontWeight: "800",
                          fontSize: "0.9rem",
                          color: "#101010",
                        }}
                      >
                        PAY PERIOD
                      </InputLabel>
                      <Select
                        value={selectedPayPeriod}
                        onChange={(e) => setSelectedPayPeriod(e.target.value)}
                      >
                        <MenuItem
                          value="current"
                          sx={{
                            marginLEFT: 9,
                            letterSpacing: "0.1rem",
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            color: "#101010",
                          }}
                        >
                          {`${payPeriodStart?.toLocaleDateString()} - ${
                            payPeriodEnd
                              ? subtractOneDay(
                                  payPeriodEnd.toLocaleDateString()
                                )
                              : ""
                          }`}
                        </MenuItem>
                        <MenuItem
                          value="previous"
                          sx={{
                            marginLEFT: 9,
                            letterSpacing: "0.1rem",
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            color: "#101010",
                          }}
                        >
                          {`${previousPayPeriodStart?.toLocaleDateString()} - ${
                            previousPayPeriodEnd
                              ? subtractOneDay(
                                  previousPayPeriodEnd.toLocaleDateString()
                                )
                              : ""
                          }`}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleDialogClose(false)}
              color="primary"
              sx={{
                backgroundColor: "#F98B85",
                borderRadius: "27px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "400",
                fontSize: "0.81rem",
                border: "2px solid #010101",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#101010",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDialogClose(true)}
              color="primary"
              disabled={formType === "Employee" && !selectedPayPeriod}
              sx={{
                backgroundColor: "#c7d57f",
                borderRadius: "27px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "400",
                fontSize: "0.81rem",
                border: "2px solid #101010",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#010101",
                },
              }}
            >
              CONFIRM
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          PaperProps={{
            sx: {
              padding: "1rem",
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <DialogTitle
            sx={{
              letterSpacing: "0.2rem",
              fontWeight: "400",
              fontSize: "1.2rem",
              color: "#ffffff",
              backgroundColor: "#4a6741",
              borderRadius: "18px",
            }}
          >
            {confirmationType === "Terminate"
              ? "CONFIRM TERMINATION"
              : "CONFIRM RE-HIRE"}
          </DialogTitle>
          <DialogContent
            sx={{
              letterSpacing: "0.4rem",
              fontWeight: "400",
              fontSize: "1rem",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginLEFT: 9,
                fontWeight: "400",
                fontSize: "1rem",
                color: "#101010",
                paddingBottom: "1rem",
                paddingTop: "1.8rem",
              }}
            >
              Are you sure you want to{" "}
              {confirmationType === "Terminate" ? "terminate" : "re-hire"} this
              employee?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDialogOpen(false)}
              color="primary"
              sx={{
                backgroundColor: "#F98B85",
                borderRadius: "27px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "400",
                fontSize: "0.81rem",
                border: "2px solid #010101",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#101010",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (confirmationType === "Terminate") {
                  handleTerminate();
                } else {
                  handleReHire();
                }
                setConfirmDialogOpen(false);
              }}
              color="primary"
              sx={{
                backgroundColor: "#c7d57f",
                borderRadius: "27px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "400",
                fontSize: "0.81rem",
                border: "2px solid #101010",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#010101",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={phoneDialogOpen}
          onClose={() => handlePhoneDialogClose(false)}
          PaperProps={{
            sx: {
              padding: "1rem",
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <DialogTitle
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: "800",
              fontSize: "1.2rem",
              color: "#010101",
            }}
          >
            CONFIRM PHONE NUMBER CHANGE
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "1.17rem",
              }}
            >
              Are you sure you want to change the phone number to{" "}
              {newPhoneNumber}{" "}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handlePhoneDialogClose(false)}
              color="primary"
              sx={{
                backgroundColor: "transparent",
                borderRadius: "9px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "400",
                fontSize: "0.72rem",
                border: "2px solid #101010",

                "&:hover": {
                  backgroundColor: "#101010",
                  opacity: "0.6425339366515838",
                  color: "#f9f9f9",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handlePhoneDialogClose(true)}
              color="primary"
              sx={{
                backgroundColor: "#c7d57f",
                borderRadius: "9px",
                padding: "9px 9px 9px 9px",
                color: "#101010",
                fontWeight: "800",
                fontSize: "0.72rem",
                border: "2px solid #101010",

                "&:hover": {
                  backgroundColor: "#4a6741",
                  color: "#f9f9f9",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };

  const handleTerminate = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        propCode: propertyCode,
        EmpID: recordData.EmpID,
        faceid: recordData.faceid,
      };

      await axios.post(
        "https://prod-21.westus.logic.azure.com:443/workflows/642890dd951a4d17acb90bbd69409e63/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ZWLbvMgupY4-O4_MXpLOTcSre6f0P4vAHgeBnj-QrO8",
        payload
      );

      handleFieldChange("Status", "Terminated");
      handleFieldChange("Notes", [
        ...recordData.Notes,
        `Terminated - ${new Date().toLocaleDateString()}`,
      ]);

      await updateDoc(doc(db, "empRegister", selectedEmployee), {
        Status: "Terminated",
        terminated: "TERMINATED",
        Notes: [
          ...recordData.Notes,
          `Terminated - ${new Date().toLocaleDateString()}`,
        ],
        // clearedBy: [],
        DateUpdated: new Date().toISOString(),
        timeclock: "no",
      });

      const payrollChangesRef = collection(db, "payrollChanges");
      await addDoc(payrollChangesRef, {
        EmpID: recordData.EmpID,
        NameFirst: recordData.NameFirst,
        NameLast: recordData.NameLast,
        changes: ["Status: Terminated"],
        payPeriod: subtractOneDay(payPeriodEnd?.toLocaleDateString()),
        ChangedBy: user.email,
        propCode: propertyCode,
      });

      alert("Employee terminated!");
      setChanges({});
    } catch (error) {
      console.error("Error during termination:", error);
      alert("Failed to terminate employee: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReHire = async () => {
    setIsSubmitting(true);
    try {
      const originalDateHired = recordData.DateHired
        ? new Date(recordData.DateHired)
        : null;

      const formattedOriginalDate = originalDateHired
        ? format(originalDateHired, "MM/dd/yyyy")
        : "";

      const newDateHired = new Date();
      const formattedNewDateHired = format(newDateHired, "MM-dd-yyyy");

      handleFieldChange("Status", "Updated");
      handleFieldChange("DateHired", formattedNewDateHired);
      handleFieldChange("Notes", [
        ...recordData.Notes,
        `Rehired - ${formattedNewDateHired} (Originally Hired: ${formattedOriginalDate})`,
      ]);

      await updateDoc(doc(db, "empRegister", selectedEmployee), {
        Status: "REHIRED",
        DateHired: formattedNewDateHired,
        Notes: [
          ...recordData.Notes,
          `Rehired - ${formattedNewDateHired} (Originally Hired: ${formattedOriginalDate})`,
        ],
        // clearedBy: [],
        DateUpdated: new Date().toISOString(),
        terminated: "",
      });

      const payrollChangesRef = collection(db, "payrollChanges");
      await addDoc(payrollChangesRef, {
        EmpID: recordData.EmpID,
        NameFirst: recordData.NameFirst,
        NameLast: recordData.NameLast,
        changes: [`Status: Rehired, DateHired: ${formattedNewDateHired}`],
        payPeriod: subtractOneDay(payPeriodEnd?.toLocaleDateString()),
        ChangedBy: user.email,
        propCode: propertyCode,
      });

      alert("Employee rehired!");
      setChanges({});
    } catch (error) {
      console.error("Error during re-hire:", error);
      alert("Failed to re-hire employee: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
      <>

        {/* Switch to toggle between Update and Add New */}
        {actionType && (
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "0.9rem",
                  paddingBottom: "0.9rem",
                  marginBottom: "1rem",
                }}
            >
              <Grid container alignItems="center" justifyContent="center" spacing={2} style={{ margin: '20px 0' }}>
                {/* ADD NEW on the left */}
                <Grid item>
                  <Typography
                      sx={{
                        fontFamily: "'Nunito', sans-serif",
                        fontWeight: "400",
                        letterSpacing: "0.09rem",
                        fontSize: "1rem",
                        color: actionType === 'addNew' ? "#010101" : "#a0a0a0", // Highlight active label
                      }}
                  >
                    UPDATE EMP
                  </Typography>
                </Grid>

                {/* Switch in the middle */}
                <Grid item>
                  <Switch
                      checked={actionType === 'addNew'}
                      onChange={handleMainSwitch}
                      name="actionTypeSwitch"
                      color="primary"
                      sx={{
                        "& .MuiSwitch-switchBase": {
                          "&.Mui-checked": {
                            color: "#FFC107",
                            "& + .MuiSwitch-track": {
                              backgroundColor: "#FFC107",
                            },
                          },
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "#74c365",
                        },
                      }}
                  />
                </Grid>

                {/* UPDATE on the right */}
                <Grid item>
                  <Typography
                      sx={{
                        fontFamily: "'Nunito', sans-serif",
                        fontWeight: "400",
                        letterSpacing: "0.09rem",
                        fontSize: "1rem",
                        color: actionType === 'update' ? "#010101" : "#a0a0a0", // Highlight active label
                      }}
                  >
                    ADD EMP
                  </Typography>
                </Grid>
              </Grid>

            </Box>
        )}

        {/* Conditionally render form based on actionType */}
        {actionType === 'update' && (
            <div>

    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={2}>
          {/*<Grid item>*/}
          {/*  <Typography*/}
          {/*    sx={{*/}
          {/*      ...sharedTextStyles,*/}
          {/*      marginRight: 4.5,*/}
          {/*      marginLeft: 1.8,*/}
          {/*      letterSpacing: "0.45rem",*/}
          {/*      fontWeight: "200",*/}
          {/*      fontSize: "1.71rem",*/}
          {/*      color: "#202426",*/}
          {/*    }}*/}
          {/*    variant="body1"*/}
          {/*    align="left"*/}
          {/*  >*/}
          {/*    UPDATE*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}

          {/* Property Code Selector */}
          <Grid item>
            <FormControl
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": {
                  minWidth: "9rem",
                },
              }}
            >
              <InputLabel
                htmlFor="property-code"
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 800,
                  color: "#000",
                  marginBottom: "0.2rem",
                  transition: "0.3s",
                }}
              >
                PROPERTY CODE
              </InputLabel>
              <Select
                value={propertyCode}
                onChange={handlePropertyCodeChange}
                inputProps={{
                  name: "property-code",
                  id: "property-code",
                }}
                sx={{
                  fontSize: "0.9rem",
                  backgroundColor: "#fcfcfc",
                  minWidth: "10em",
                  borderRadius: "27px",
                  fontWeight: "800",
                }}
              >
                <MenuItem sx={{ fontSize: "0.9rem" }} value="">
                  {" "}
                </MenuItem>
                {propertyCodes.map((code) => (
                  <MenuItem
                    key={code}
                    value={code}
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 400,
                      color: "#010101",
                      transition: "0.3s",
                    }}
                  >
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Select Record Type */}
          <Grid item>{renderSelectRecordType()}</Grid>

          {/* ACTIVE/INACTIVE USER TOGGLE */}

          {formType === "Employee" && (
            <>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={employeeStatus === "Active"}
                      onChange={handleSwitchChange}
                      name="employeeStatusSwitch"
                      color="default"
                      sx={{
                        "& .MuiSwitch-switchBase": {
                          "&.Mui-checked": {
                            color: "#74c365",
                            "& + .MuiSwitch-track": {
                              backgroundColor: "#74c365",
                            },
                          },
                          "&.Mui-checked:hover": {
                            backgroundColor: "rgba(249, 139, 133, 0.08)",
                          },
                          "&.Mui-checked .MuiSwitch-thumb": {
                            backgroundColor: "#74c365",
                          },
                          "& .MuiSwitch-thumb": {
                            backgroundColor: "#F98B85",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              backgroundSize: "cover",
                            },
                          },
                        },
                        "& .MuiSwitch-thumb": {
                          backgroundColor: "#F98B85",
                          "&::before": {
                            content: '""',
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            backgroundSize: "cover",
                          },
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "#F98B85",
                        },
                      }}
                    />
                  }
                  label={
                    employeeStatus === "Active"
                      ? "ACTIVE EMPLOYEES"
                      : "INACTIVE"
                  }
                  labelPlacement="top"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.9rem",
                      fontWeight: 400,
                      color: "#010101",
                    },
                  }}
                />
              </Grid>

              {/* Employee Selector */}
              <Grid item>
                <FormControl
                  sx={{
                    minWidth: "15rem",
                    "@media (max-width: 600px)": {
                      minWidth: "9rem",
                      fontWeight: "800",
                    },
                  }}
                >
                  <InputLabel
                    htmlFor="employee-selector"
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: 800,
                      color: "#010101",
                      transition: "0.3s",
                    }}
                  >
                    EMPLOYEE
                  </InputLabel>
                  <Select
                    value={selectedEmployee}
                    onChange={(event) => handleEmployeeChange(event)}
                    label="Employee"
                    sx={{
                      fontSize: "0.9rem",
                      backgroundColor: "#fcfcfc",
                      minWidth: "10em",
                      borderRadius: "27px",
                      fontWeight: "800",
                    }}
                  >
                    <MenuItem sx={{ fontSize: "0.8rem" }} value="">
                      {" "}
                    </MenuItem>
                    {filteredEmployees.map((emp) => (
                      <MenuItem
                        key={emp.EmpID}
                        value={emp.EmpID}
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 400,
                          color: "#101010",
                          transition: "0.3s",
                        }}
                      >
                        {emp.NameFirst} {emp.NameLast}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={confirmApproveDocDialogOpen}
        onClose={() => handleApproveDialogClose(false)}
        PaperProps={{
          sx: {
            padding: "1rem",
            borderRadius: "36px",
            backgroundColor: "#f5f5f5",
            position: "relative",
          },
        }}
      >
        <IconButton
          onClick={() => handleApproveDialogClose(false)}
          sx={{
            position: "absolute",
            top: 9,
            right: 9,
            color: "#101010",
          }}
        >
          <TfiClose />
        </IconButton>
        <DialogTitle
          sx={{
            letterSpacing: "0.2rem",
            fontWeight: "400",
            fontSize: "0.9rem",
            color: "#101010",
          }}
        >
          APPROVE DOCUMENT
        </DialogTitle>
        <DialogContent
          sx={{
            letterSpacing: "0.1rem",
            fontWeight: "400",
            fontSize: "0.9rem",
            color: "#101010",
          }}
        >
          <DialogContentText>
            Are you sure you want to approve the {fileToApprove} document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleApproveDialogClose(true)}
            color="primary"
            autoFocus
            fullWidth
            sx={{
              backgroundColor: "#c7d57f",
              borderRadius: "9px",
              padding: "9.9px 18px 9.9px 18px",
              color: "#010101",
              letterSpacing: "0.1rem",
              fontWeight: "800",
              fontSize: "0.72rem",
              border: "2px solid #f9f9f9",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#010101",
              },
            }}
          >
            APPROVE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmRejectDocDialogOpen}
        onClose={() => handleRejectDialogClose(false)}
        PaperProps={{
          sx: {
            padding: "1rem",
            borderRadius: "36px",
            backgroundColor: "#f5f5f5",
            position: "relative",
          },
        }}
      >
        <IconButton
          onClick={() => handleRejectDialogClose(false)}
          sx={{
            position: "absolute",
            top: 9,
            right: 9,
            color: "#101010",
          }}
        >
          <TfiClose />
        </IconButton>
        <DialogTitle
          sx={{
            letterSpacing: "0.2rem",
            fontWeight: "400",
            fontSize: "0.9rem",
            color: "#101010",
          }}
        >
          REJECT DOCUMENT
        </DialogTitle>
        <DialogContent
          sx={{
            letterSpacing: "0.1rem",
            fontWeight: "400",
            fontSize: "0.9rem",
            color: "#101010",
          }}
        >
          <DialogContentText>
            Are you sure you want to reject the {fileToReject} file? Please
            provide a reason for rejection.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Rejection Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleRejectDialogClose(true)}
            color="primary"
            autoFocus
            fullWidth
            sx={{
              backgroundColor: "#f98b85",
              borderRadius: "9px",
              padding: "9.9px 18px 9.9px 18px",
              color: "#010101",
              letterSpacing: "0.1rem",
              fontWeight: "800",
              fontSize: "0.72rem",
              border: "2px solid #f9f9f9",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#010101",
              },
            }}
          >
            REJECT
          </Button>
        </DialogActions>
      </Dialog>

      {renderForm()}

      {/* Dialog for updating email and claims */}
      <Dialog
        open={emailDialogOpen}
        onClose={() => handleEmailDialogClose(false)}
        PaperProps={{
          sx: {
            padding: "1rem",
            borderRadius: "36px",
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <DialogTitle
          sx={{
            letterSpacing: "0.2rem",
            fontWeight: "400",
            fontSize: "1.2rem",
            color: "#ffffff",
            backgroundColor: "#4a6741",
            borderRadius: "18px",
          }}
        >
          EDIT PERMISSIONS
        </DialogTitle>
        <DialogContent
          sx={{
            letterSpacing: "0.4rem",
            fontWeight: "400",
            fontSize: "1rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3} container>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  marginLEFT: 9,
                  letterSpacing: "0.1rem",
                  fontWeight: "400",
                  fontSize: "1rem",
                  color: "#101010",
                  paddingBottom: "1rem",
                  paddingTop: "1.8rem",
                }}
              >
                USER: {selectedEmailValue}
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAdmin}
                    onChange={(e) => {
                      setSelectedAdmin(e.target.checked);
                      handleFieldChange(
                        selectedEmailField,
                        e.target.checked,
                        true,
                        "admin"
                      );
                    }}
                  />
                }
                label="Employee Admin"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedViewPay}
                    onChange={(e) => {
                      setSelectedViewPay(e.target.checked);
                      handleFieldChange(
                        selectedEmailField,
                        e.target.checked,
                        true,
                        "viewPay"
                      );
                    }}
                  />
                }
                label="View Pay Rates"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAckEmpStatus}
                    onChange={(e) => {
                      setSelectedAckEmpStatus(e.target.checked);
                      handleFieldChange(
                        selectedEmailField,
                        e.target.checked,
                        true,
                        "ackEmpStatus"
                      );
                    }}
                  />
                }
                label="Ack Emp Status"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleEmailDialogClose(false)}
            color="primary"
            sx={{
              backgroundColor: "#F98B85",
              borderRadius: "27px",
              padding: "9px 9px 9px 9px",
              color: "#101010",
              fontWeight: "400",
              fontSize: "0.81rem",
              border: "2px solid #010101",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#101010",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleEmailDialogClose(true)}
            color="primary"
            disabled={!selectedEmailValue}
            sx={{
              backgroundColor: "#c7d57f",
              borderRadius: "27px",
              padding: "9px 9px 9px 9px",
              color: "#101010",
              fontWeight: "400",
              fontSize: "0.81rem",
              border: "2px solid #101010",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#010101",
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        sx={{
          width: 900,
          color: "secondary",
          "& .MuiSnackbarContent-root": {
            backgroundColor: "grey",
            fontSize: "0.9rem",
            fontWeight: "800",
          },
          "& .MuiButton-root": {
            color: "black",
            fontSize: "0.9rem",
            fontWeight: "800",
          },
        }}
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: false }}
        autoHideDuration={3699}
        onClose={handleSnackbarClose}
        message={
          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "200",
              letterSpacing: "0.09rem",
            }}
          >
            {snackbarMessage}
          </span>
        }
      ></Snackbar>

      {/* Submission Dialog */}
      <SubmissionDialog
        open={isSubmitting}
        onClose={() => {}}
        isSubmitting={isSubmitting}
      />
    </Grid>

            </div>
        )}

        {actionType === 'addNew' && (
            <div>
              <AddProperty />

            </div>
        )}
      </>
  );
}

const commonSx = {
  marginBottom: "0.9rem",
  marginTop: "0.9rem",
  transition: "0.3s",
  maxWidth: "18rem",
  ".MuiInputLabel-root": {
    fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
    fontWeight: 200,
    color: "#101010",
  },
  ".MuiInputBase-input": {
    fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "18px",
  },
};

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};
