import React, { useState, useEffect } from "react";
import "../../components/App.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase/firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { RxReset } from "react-icons/rx";

export default function AddProperty() {
  const { user } = useAuth();
  const [formType, setFormType] = useState("");
  const [propertyCode, setPropertyCode] = useState("");
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [showBlankPropertyCode, setShowBlankPropertyCode] = useState(true);
  const [propertyData, setPropertyData] = useState([]);
  const [formReloadKey, setFormReloadKey] = useState(0);
  const [shouldReloadForm, setShouldReloadForm] = useState(true);

  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(
        propertiesRef,
        where("AdminEmail", "array-contains", email)
      );

      const codes = [];
      const properties = [];

      // Fetch based on roleEmail
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => {
        const property = doc.data();
        codes.push(property.propCode);
        properties.push({
          propCode: property.propCode,
          propertyName: property.propertyName,
          city: property.city,
          passcode: property.passcode,
        });
      });

      // Fetch based on AdminEmail
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        const property = doc.data();
        const propCode = property.propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
          properties.push({
            propCode: property.propCode,
            propertyName: property.propertyName,
            city: property.city,
            passcode: property.passcode,
          });
        }
      });

      setPropertyCodes(codes);
      setPropertyData(properties);
      if (!selectedPropertyCode && codes.length > 0) {
        setSelectedPropertyCode(codes[0]);
        setPropertyCode(codes[0]);
      }
    };

    fetchPropertyCodes();
  }, [user, selectedPropertyCode]);

  useEffect(() => {
    if (!formType || !propertyCode) return;

    loadForm();
    setShouldReloadForm(false);
  }, [formType, propertyCode, user, propertyData, formReloadKey]);

  const handleReset = () => {
    window.location.reload();
  };

  const loadForm = () => {
    const script = document.createElement("script");
    script.src = "https://www.cognitoforms.com/f/seamless.js";
    script.setAttribute("data-key", "ruq8UUqaOkCpQ8VqZa8eaQ");
    script.setAttribute("data-form", formType === "Employee" ? "52" : "51");

    const formContainer = document.getElementById("form-container");

    if (formContainer) {
      formContainer.innerHTML = "";
      formContainer.appendChild(script);

      script.addEventListener("load", () => {
        const selectedProperty = propertyData.find(
          (property) => property.propCode === propertyCode
        );

        if (formType === "Property") {
          window.Cognito.prefill({ REQUESTORNAME: user.displayName });
        } else if (formType === "Employee" && selectedProperty) {
          window.Cognito.prefill({
            PROP: `${selectedProperty.propCode}`,
          });
        }
      });
    }
  };

  const handleFormTypeChange = (event) => {
    setFormType(event.target.value);
    if (event.target.value === "Property") {
      setPropertyCode(propertyCodes[0]);
      setShowBlankPropertyCode(true);
    } else {
      setPropertyCode("");
      setShowBlankPropertyCode(false);
    }
  };

  const handlePropertyCodeChange = (event) => {
    setPropertyCode(event.target.value);
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={2}>
          {/*<Grid item>*/}
          {/*  <Typography*/}
          {/*      sx={{*/}
          {/*        ...sharedTextStyles,*/}
          {/*        marginRight: 4.5,*/}
          {/*        marginLeft: 1.8,*/}
          {/*        letterSpacing: "0.45rem",*/}
          {/*        fontWeight: "200",*/}
          {/*        fontSize: "1.71rem",*/}
          {/*        color: "#202426",*/}
          {/*      }}*/}
          {/*      variant="body1"*/}
          {/*      align="left"*/}
          {/*  >*/}
          {/*    ADD NEW*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <Grid item>
            <FormControl
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": {
                  minWidth: "9rem",
                },
              }}
            >
              <InputLabel
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 800,
                  color: "#000",
                  marginBottom: "0.2rem",
                  transition: "0.3s",
                }}
                htmlFor="form-type"
              >
                SELECT RECORD - ADD
              </InputLabel>
              <Select
                value={formType}
                onChange={handleFormTypeChange}
                inputProps={{ name: "form-type", id: "form-type" }}
                readOnly={!!formType}
                sx={{
                  fontSize: "0.9rem",
                  backgroundColor: "#fcfcfc",
                  minWidth: "10em",
                  borderRadius: "27px",
                }}
              >
                <MenuItem sx={{ fontSize: "0.9rem" }} value="" disabled>
                  SELECT RECORD TYPE
                </MenuItem>
                <MenuItem sx={{ fontSize: "0.9rem" }} value="Employee">
                  Employee
                </MenuItem>
                {user.photoURL !== "Manager" && (
                  <MenuItem sx={{ fontSize: "0.9rem" }} value="Property">
                    Property
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": {
                  minWidth: "9rem",
                },
              }}
            >
              <InputLabel
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 600,
                  color: "#000",
                  marginBottom: "0.2rem",
                  transition: "0.3s",
                }}
                htmlFor="property-code"
              >
                PROPERTY CODE
              </InputLabel>
              <Select
                sx={{
                  fontSize: "0.9rem",
                  backgroundColor: "#fcfcfc",
                  minWidth: "10em",
                  borderRadius: "27px",
                }}
                value={showBlankPropertyCode ? "" : propertyCode}
                onChange={handlePropertyCodeChange}
                inputProps={{ name: "property-code", id: "property-code" }}
                readOnly={!!propertyCode}
              >
                {showBlankPropertyCode && (
                  <MenuItem sx={{ fontSize: "0.9rem" }} value="" disabled>
                    Property Code
                  </MenuItem>
                )}
                {propertyCodes.map((code) => (
                  <MenuItem sx={{ fontSize: "0.9rem" }} key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Tooltip title="Reset">
              <IconButton
                sx={{
                  fontSize: "1.9rem",
                  color: "#000000",
                }}
                onClick={handleReset}
                label="Reset"
              >
                <RxReset />
              </IconButton>
            </Tooltip>
          </Grid>

          {formType && (formType === "Property" || propertyCode) && (
            <Grid
              item
              sx={{
                color: "#fcfcfc",
                minWidth: "75vw",
                borderRadius: "27px",
              }}
            >
              <div id="form-container" className="form-container"></div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};
