import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  CardHeader,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  TextField,
} from "@mui/material";

import { IoMdBatteryCharging } from "react-icons/io";
import {
  MdClose,
  MdCheck,
  MdOutlineKingBed,
  MdOutlineSentimentNeutral,
} from "react-icons/md";
import {
  BsEmojiSunglasses,
  BsCalendarWeek,
  BsFillPatchCheckFill,
} from "react-icons/bs";
import { FcPlus } from "react-icons/fc";
import { BiCoffee } from "react-icons/bi";
import { CiCirclePlus } from "react-icons/ci";
import { TbClockHour10, TbMoodSad2, TbMoodAngry } from "react-icons/tb";
import { FaBusinessTime, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { FcUnlock, FcPrivacy, FcDisclaimer, FcExpired } from "react-icons/fc";
import { RiEmotionHappyLine } from "react-icons/ri";

const MyTime = () => {
  const { user } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [tableTitle, setTableTitle] = useState("");
  const [selectedPayRoll, setSelectedPayRoll] = useState(null);
  const [value, setValue] = useState(0);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [payCycle, setPayCycle] = useState("");
  //   const [allEmployees, setAllEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handlePropertyCodeChange = (event) => {
    setSelectedPropertyCode(event.target.value);
  };
  const [empIds, setEmpIds] = useState({});
  const [punches, setPunches] = useState([]);
  const [closedPayPeriods, setClosedPayPeriods] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      const email = user.email;

      if (!email) return;

      const empQuery = query(
        collection(db, "empRegister"),
        where("AdEmail", "==", email)
      );
      const empSnapshot = await getDocs(empQuery);
      if (!empSnapshot.empty) {
        const propCodes = [];
        const empIds = {};

        empSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const propCode = data.PropID;
          if (propCode && !propCodes.includes(propCode)) {
            propCodes.push(propCode);
          }
          empIds[propCode] = data.EmpID; // Store EmpID for each property
        });

        setPropertyCodes(propCodes);
        setEmpIds(empIds); // Store all EmpIDs
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (selectedPropertyCode && empIds[selectedPropertyCode]) {
      setSelectedEmployeeId(empIds[selectedPropertyCode]);
    }
  }, [selectedPropertyCode, empIds]);

  const fetchEmployeePunches = async () => {
    if (!selectedEmployeeId || !selectedPropertyCode) return;

    const punchesQuery = query(
      collection(db, "PUNCHES"),
      where("EmployeeId", "==", selectedEmployeeId),
      where("PropCode", "==", selectedPropertyCode)
    );
    const punchesSnapshot = await getDocs(punchesQuery);
    const punchesData = punchesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const filteredPunches = punchesData.filter((punch) => {
      const inTime = punch.InTime.toDate();
      return inTime >= payPeriodStart && inTime <= payPeriodEnd;
    });

    setPunches(filteredPunches);
    setEmployees(
      filteredPunches.map((punch) => ({
        ...punch,
        isEditable:
          punch.Status === "Open" ||
          punch.Status === "Manual" ||
          punch.Status === "Modified",
      }))
    );
  };

  // Use useEffect to refetch punches when necessary
  useEffect(() => {
    fetchEmployeePunches();
  }, [selectedEmployeeId, selectedPropertyCode, payPeriodStart, payPeriodEnd]);

  const handleClose = () => {
    setOpen(false);
    fetchEmployeePunches(); // Refetch punches data to update the table
  };

  useEffect(() => {
    if (selectedPropertyCode && empIds[selectedPropertyCode]) {
      setSelectedEmployeeId(empIds[selectedPropertyCode]);
    }
  }, [selectedPropertyCode, empIds]);

  const getUniqueDates = (data) => {
    const uniqueDates = new Set();
    data.forEach((item) => {
      let endDate = new Date(item.PeriodEndDate.toDate());
      endDate.setDate(endDate.getDate() - 1); // Subtract one day from the end date
      uniqueDates.add(endDate.toISOString().split("T")[0]);
    });
    return Array.from(uniqueDates).sort((a, b) => new Date(b) - new Date(a)); // Sort descending
  };

  const [selectedDate, setSelectedDate] = useState("");
  const uniqueDates = getUniqueDates(payRollData);

  useEffect(() => {
    const dates = getUniqueDates(payRollData);
    if (dates.length > 0) {
      setSelectedDate(dates[0]);
    }
  }, [payRollData]);

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  // useEffect(() => {
  //   let formattedDate = "";
  //   if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
  //     let periodEndDate = new Date(
  //       payRollData[currentPayRollIndex].PeriodEndDate?.toDate()
  //     );
  //     periodEndDate.setDate(periodEndDate.getDate() - 1); // subtract one day
  //     formattedDate = periodEndDate.toLocaleDateString("en-US", {
  //       month: "2-digit",
  //       day: "2-digit",
  //       year: "numeric",
  //     });
  //   }
  //   setTableTitle(`${selectedPropertyCode} PAYROLL - ${formattedDate}`);
  // }, [selectedPropertyCode, currentPayRollIndex, payRollData]);

  useEffect(() => {
    if (
      payRollData.length > 0 &&
      currentPayRollIndex >= 0 &&
      currentPayRollIndex < payRollData.length
    ) {
      setSelectedPayRoll(payRollData[currentPayRollIndex]);
    }
  }, [currentPayRollIndex, payRollData]);

  function formatDate(dateString) {
    // Parse the date and add one day
    const date = new Date(dateString);
    date.setDate(date.getDate()); // Add one day

    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear().toString().slice(-2); // Get last two digits of year

    // Format month and day to be two digits
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

  const checkIfPayPeriodClosed = useCallback(
    (employeeId) => {
      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId
      );

      return employeePunches.every(
        (punch) =>
          punch.Status === "Closed" ||
          punch.Status === "Mod/Closed" ||
          punch.Status === "Man/Closed"
      );
    },
    [employees]
  );

  // Updates the "closedPayPeriods" state based on the pay period closure status of employees.
  useEffect(() => {
    const updatedClosedPayPeriods = { ...closedPayPeriods };
    employees.forEach((employee) => {
      updatedClosedPayPeriods[employee.EmployeeId] = checkIfPayPeriodClosed(
        employee.EmployeeId
      );
    });
    setClosedPayPeriods(updatedClosedPayPeriods);
  }, [selectedEmployeeId, employees, checkIfPayPeriodClosed, closedPayPeriods]);

  // *** TIMECARD FUNCTIONS ***

  const handleClickOpen = () => {
    setOpen(true);
  };

  //Function changes the pay period based on the given direction ("left" or "right")
  //and updates the pay period start and end dates accordingly.
  const changePayPeriod = (direction) => {
    let newPayPeriodStart = new Date(payPeriodStart);
    let newPayPeriodEnd = new Date(payPeriodEnd);

    switch (payCycle) {
      case "Monthly":
        if (direction === "left") {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() - 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() - 1);
        } else {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() + 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() + 1);
        }
        break;
      case "Bi-Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 14);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 14);
        }
        break;
      case "Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 7);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 7);
        }
        break;
      default:
        break;
    }

    setPayPeriodStart(newPayPeriodStart);
    setPayPeriodEnd(newPayPeriodEnd);
  };

  const currentDate = new Date();

  const isPayPeriodEndGreaterThanCurrent = new Date(payPeriodEnd) > currentDate;

  useEffect(() => {
    const fetchProperties = async () => {
      if (!selectedPropertyCode) return;

      const propertiesDoc = await getDoc(
        doc(db, "Properties", selectedPropertyCode)
      );
      const { payPeriod, payCycle } = propertiesDoc.data();
      const currentDate = new Date();
      let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
      let payPeriodEndDate;

      setPayCycle(payCycle);

      switch (payCycle) {
        case "Monthly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setMonth(payPeriodEndDate.getMonth() + 1);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Bi-Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 14);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 7);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        default:
          break;
      }

      setPayPeriodStart(payPeriodStartDate);
      setPayPeriodEnd(payPeriodEndDate);
    };

    fetchProperties();
  }, [selectedPropertyCode]);

  const handleClosePayPeriod = () => {
    // Open the confirm dialog
    setConfirmDialogOpen(true);
  };

  // Function to handle confirming the close pay period action
  const handleConfirmClosePayPeriod = () => {
    // Close the confirm dialog
    setConfirmDialogOpen(false);

    // Perform the actual close pay period action
    closePayPeriod();
  };

  // Function to handle canceling the close pay period action
  const handleCancelClosePayPeriod = () => {
    // Close the confirm dialog
    setConfirmDialogOpen(false);
  };

  //Variable filters the employees to only include the employee with the selected ID.
  const selectedEmployeeFiltered = employees.filter(
    (employee) => employee.EmployeeId === selectedEmployeeId
  );

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const groupByWeek = (data, startDate) => {
    return data.reduce((acc, item) => {
      const weekNumber =
        moment(item.InTime.toDate()).diff(moment(startDate), "weeks") + 1;
      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }
      acc[weekNumber].push(item);
      return acc;
    }, {});
  };

  //Variable is an object that groups the employees by week number, based on the payPeriodStart value.
  const groupedEmployeesByWeeks = groupByWeek(punches, payPeriodStart);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Convert the date string back to a Date object
    const dateObject = new Date(selectedDate);

    // Log the state values
    console.log("Selected Employee ID:", selectedEmployeeId);
    console.log("Employees:", employees);

    const result = await submitMissedPunch(dateObject);

    if (result) {
      alert("SUBMITTED - PRESENTADO");
      handleClose();
    } else {
      alert("Submission failed. Please try again");
    }
  };
  const submitMissedPunch = async (date) => {
    const selectedEmployee = employees.find(
      (employee) => employee.EmployeeId === selectedEmployeeId
    );
    if (!selectedEmployee) {
      console.error(`No employee found with id ${selectedEmployeeId}`);
      return false;
    }

    // Add one day to the selected date
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() + 1);
    const adjustedTimestamp = Timestamp.fromDate(adjustedDate);

    // Construct the new document for missedPunches collection
    const newMissedPunch = {
      FirstName: selectedEmployee.FirstName,
      LastName: selectedEmployee.LastName,
      Department: "MISSING DAY",
      PropCode: selectedPropertyCode,
      InTime: adjustedTimestamp,
      OutTime: adjustedTimestamp,
    };

    // Construct the new document for PUNCHES collection
    const newPunch = {
      InTime: adjustedDate,
      OutTime: adjustedDate,
      Tips: 0,
      Rooms: 0,
      A: 0,
      B: 0,
      C: 0,
      MoodIn: "Missing",
      MoodOut: "Missing",
      Status: "Missing",
      Department: "VERIFY",
      PTO: 0,
      PropCode: selectedPropertyCode,
      FirstName: selectedEmployee.FirstName,
      LastName: selectedEmployee.LastName,
      EmployeeId: selectedEmployee.EmployeeId,
    };

    try {
      // Add document to missedPunches collection
      const missedPunchesCollection = collection(db, "missedPunches");
      const docRef = await addDoc(missedPunchesCollection, newMissedPunch);
      console.log(`New missed punch added with id ${docRef.id}`);

      // Use the same document ID to add document to PUNCHES collection
      const punchDocRef = doc(db, "PUNCHES", docRef.id);
      await setDoc(punchDocRef, newPunch);

      console.log(`New missed punch and punch added with id ${docRef.id}`);
      return true; // return true on successful submission
    } catch (error) {
      console.error("Error adding document: ", error);
      return false; // return false if an error occurred
    }
  };

  const areAllPunchesApproved = useCallback(
    (employeeId) => {
      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId
      );

      return employeePunches.every((punch) => punch.Approved === "yes");
    },
    [employees]
  );

  const canClosePayPeriod = () => {
    return (
      !closedPayPeriods[selectedEmployeeId] &&
      !isAnyPunchNeedsFix(selectedEmployeeId) &&
      !areAllPunchesApproved(selectedEmployeeId)
    );
  };

  const canClosePayPeriodBasedOnDate = () => {
    // This function returns true if the pay period has not yet ended
    return !hasPayPeriodEnded();
  };

  const hasPayPeriodEnded = () => {
    // Assuming payPeriodEnd is a Date object
    const currentDate = new Date();
    return currentDate > payPeriodEnd;
  };

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  const isAnyPunchNeedsFix = useCallback(
    (employeeId) => {
      // Define the list of restricted departments
      const restrictedDepartments = ["FIX", "N/A", "NO-SHOW", "0", "VERIFY"]; // Add other departments as needed

      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId
      );

      // Check if any punch is in a restricted department
      return employeePunches.some((punch) =>
        restrictedDepartments.includes(punch.Department)
      );
    },
    [employees]
  );

  const closePayPeriod = async () => {
    setIsLoading(true);

    const updatedEmployees = punches.filter((punch) => {
      const punchInTime = punch.InTime.toDate();
      return (
        punch.EmployeeId === selectedEmployeeId &&
        punchInTime >= payPeriodStart &&
        punchInTime <= payPeriodEnd
      );
    });

    for (const punch of updatedEmployees) {
      const punchDocRef = doc(db, "PUNCHES", punch.id);

      const punchData = {
        Approved: "yes",
      };

      try {
        await updateDoc(punchDocRef, punchData);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }

    setIsLoading(false);
    await fetchEmployeePunches(); // Refresh punches data after closing the pay period
  };

  //Function sorts a list of employees by their "InTime" property in ascending order.
  const sortEmployeesByInTime = (employeesList) => {
    return employeesList.sort(
      (a, b) => a.InTime.toDate().getTime() - b.InTime.toDate().getTime()
    );
  };

  const getTimeDifference = (inTime, outTime) => {
    const difference = outTime - inTime;
    return difference;
  };

  //Function converts the time difference (in milliseconds) into a decimal representation of hours
  const differenceToDecimal = (difference) => {
    const hours = difference / (1000 * 60 * 60);
    return hours.toFixed(2);
  };

  const findPayPeriodStartDateIndex = (punches, payPeriodStart) => {
    const payPeriodStartDate = moment(payPeriodStart);

    for (let i = 0; i < punches.length; i++) {
      const punchDate = moment(punches[i].InTime.toDate());
      if (punchDate.isSameOrAfter(payPeriodStartDate)) {
        return i;
      }
    }

    return -1;
  };

  const getMoodIcon = (mood) => {
    switch (mood) {
      case "Chill":
        return <BsEmojiSunglasses />;
      case "Happy":
        return <RiEmotionHappyLine />;
      case "Neutral":
        return <MdOutlineSentimentNeutral />;
      case "Unhappy":
        return <TbMoodSad2 />;
      case "Angry":
        return <TbMoodAngry />;
      case "Break":
        return <BiCoffee />;
      case "PTO":
        return <IoMdBatteryCharging />;
      case "+":
        return <CiCirclePlus />;
      case "Manual":
        return <MdClose />;
      case "Actual":
        return <MdCheck />;
      case "Missing":
        return <MdClose />;
      default:
        return null;
    }
  };

  const formatDateTime = (date) => {
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    // return `${month}/${dayOfMonth} ${hours}:${minutes}`;
    return `${hours}:${minutes}`;
  };

  //Function formats the time difference (in milliseconds) into a readable format with hours and minutes
  const formatTimeDifference = (difference) => {
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  //Function calculates the total decimal hours worked by an employee.
  const calculateEmployeeTotalDecimal = (punches) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce(
        (weekTotal, punch) =>
          punch.PayType === "PER HOUR"
            ? weekTotal +
              Number(
                differenceToDecimal(
                  getTimeDifference(
                    punch.InTime.toDate(),
                    punch.OutTime.toDate()
                  )
                )
              )
            : weekTotal,
        0
      );
      return weeklyTotal + weeklyHours;
    }, 0);
  };

  // Helper function to convert a value to a Date
  const toDate = (value) => {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    // If value is not a Firebase Timestamp, assume it's a Date
    return value;
  };
  const calculateOvertimeHours = (decimalHoursWorked, holidayHours) => {
    const standardWorkWeekHours = 40;
    decimalHoursWorked =
      typeof decimalHoursWorked === "number" ? decimalHoursWorked : 0;
    holidayHours = typeof holidayHours === "number" ? holidayHours : 0;
    const effectiveWorkHours =
      decimalHoursWorked > 40
        ? decimalHoursWorked - holidayHours
        : decimalHoursWorked;

    const overtimeHours = Math.max(
      0,
      effectiveWorkHours - standardWorkWeekHours
    );

    return overtimeHours;
  };

  const calculateTotalDecimalWithoutPayTypeRestriction = (
    punches,
    payPeriodStart
  ) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce((weekTotal, punch) => {
        if (!punch.InTime || !punch.OutTime) {
          console.error("InTime or OutTime is null or undefined:", punch);
          return weekTotal;
        }
        const inTime = toDate(punch.InTime);
        const outTime = toDate(punch.OutTime);
        const hours = Number(
          differenceToDecimal(getTimeDifference(inTime, outTime))
        );
        return weekTotal + hours;
      }, 0);
      return weeklyTotal + weeklyHours;
    }, 0);
  };

  const calculateOvertimeForEmployees = (employees, payPeriodStart) => {
    const employeeIds = [
      ...new Set(employees.map((employee) => employee.EmployeeId)),
    ];

    return employeeIds.reduce((totalOvertime, employeeId) => {
      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId
      );

      // Group the punches by week
      const punchesByWeek = groupByWeek(employeePunches, payPeriodStart);

      // Calculate the total overtime for each week
      const totalOvertimeForEmployee = Object.values(punchesByWeek).reduce(
        (total, weekPunches) => {
          const weeklyTotalDecimal =
            calculateTotalDecimalWithoutPayTypeRestriction(
              weekPunches,
              payPeriodStart
            );
          const weeklyHolidayHours =
            calculateTotalHolidayHoursForEmployees(weekPunches);
          const weeklyOvertime = calculateOvertimeHours(
            weeklyTotalDecimal,
            weeklyHolidayHours
          );
          return total + weeklyOvertime;
        },
        0
      );

      return totalOvertime + totalOvertimeForEmployee;
    }, 0);
  };

  //Function calculates the total PTO (Paid Time Off) for all employees by reducing the PTO values of each employee.
  const calculateTotalPTOForEmployees = (employees) => {
    return employees.reduce(
      (total, employee) =>
        total + (employee.PTO ? parseFloat(employee.PTO) : 0),
      0
    );
  };

  //Function calculates the total holiday hours for all employees by reducing the holiday hours of each employee.
  function calculateTotalHolidayHoursForEmployees(employees) {
    return employees.reduce(
      (total, employee) =>
        total + (employee.HolidayHours ? parseFloat(employee.HolidayHours) : 0),
      0
    );
  }

  const renderWeekTotal = (weekNumber, selectedEmployeeFilteredInWeek) => {
    // Filter punches for the current week
    const punchesForCurrentWeek = selectedEmployeeFilteredInWeek.filter(
      (punch) => {
        const punchDate = punch.InTime.toDate();
        const punchMoment = moment(punchDate);
        const weeksDifference = punchMoment.diff(
          moment(payPeriodStart),
          "weeks"
        );
        const currentWeekNumber = 1 + weeksDifference;
        return currentWeekNumber === parseInt(weekNumber);
      }
    );

    const sumA = punchesForCurrentWeek.reduce(
      (total, employee) => total + employee.A,
      0
    );
    const sumB = punchesForCurrentWeek.reduce(
      (total, employee) => total + employee.B,
      0
    );
    const sumC = punchesForCurrentWeek.reduce(
      (total, employee) => total + employee.C,
      0
    );

    return (
      <TableFooter sx={{ width: "100%" }}>
        <TableRow>
          <TableCell
            sx={{
              letterSpacing: "0.3rem",
              backgroundColor: "#ebefe3",
              color: "#020202",
              fontWeight: 200,
              fontSize: "0.9rem",
            }}
            align="left"
            size="small"
          >{`WK:${weekNumber}`}</TableCell>
          <TableCell
            align="center"
            size="small"
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: 600,
              fontSize: "0.7rem",
              backgroundColor: "#ebefe3",
              color: "020202",
            }}
          >
            TIME
          </TableCell>
          <TableCell
            align="center"
            size="small"
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: 600,
              fontSize: "0.7rem",
              backgroundColor: "#ebefe3",
              color: "020202",
            }}
          >
            VAC
          </TableCell>
          <TableCell
            align="center"
            size="small"
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: 600,
              fontSize: "0.7rem",
              backgroundColor: "#ebefe3",
              color: "020202",
            }}
          >
            HOL
          </TableCell>
          <TableCell
            align="center"
            size="small"
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: 600,
              fontSize: "0.7rem",
              backgroundColor: "#ebefe3",
              color: "020202",
            }}
          >
            TIPS
          </TableCell>

          <TableCell
            align="center"
            size="small"
            sx={{
              letterSpacing: "0.1rem",
              fontWeight: 600,
              fontSize: "0.7rem",
              backgroundColor: "#ebefe3",
              color: "020202",
            }}
          >
            RMS
          </TableCell>
          {sumA !== 0 && (
            <TableCell
              align="center"
              size="small"
              sx={{
                letterSpacing: "0.1rem",
                fontWeight: 600,
                fontSize: "0.7rem",
                backgroundColor: "#ebefe3",
                color: "020202",
              }}
            ></TableCell>
          )}
          {sumB !== 0 && (
            <TableCell
              align="center"
              size="small"
              sx={{
                letterSpacing: "0.1rem",
                fontWeight: 600,
                fontSize: "0.7rem",
                backgroundColor: "#ebefe3",
                color: "020202",
              }}
            ></TableCell>
          )}
          {sumC !== 0 && (
            <TableCell
              align="center"
              size="small"
              sx={{
                letterSpacing: "0.1rem",
                fontWeight: 600,
                fontSize: "0.7rem",
                backgroundColor: "#ebefe3",
                color: "020202",
              }}
            ></TableCell>
          )}
        </TableRow>
        <TableRow>
          {/* SPACE */}
          <TableCell
            align="center"
            size="small"
            sx={{
              fontWeight: 400,
              fontSize: "0.7rem",
              backgroundColor: "#fcfcfc",
            }}
          ></TableCell>
          {/* TIME */}
          <TableCell
            align="center"
            size="small"
            sx={{
              ...weeklytotals,
              fontWeight: 600,
              fontSize: "0.8rem",
              maxWidth: "3.6rem",

              backgroundColor:
                punchesForCurrentWeek.reduce(
                  (total, employee) =>
                    total +
                    getTimeDifference(
                      employee.InTime.toDate(),
                      employee.OutTime.toDate()
                    ),
                  0
                ) >
                40 * 60 * 60 * 1000 // Convert 40 hours to milliseconds
                  ? "#F98B85"
                  : "inherit",
              color:
                punchesForCurrentWeek.reduce(
                  (total, employee) =>
                    total +
                    getTimeDifference(
                      employee.InTime.toDate(),
                      employee.OutTime.toDate()
                    ),
                  0
                ) >
                40 * 60 * 60 * 1000 // Convert 40 hours to milliseconds
                  ? "#fcfcfc"
                  : "inherit",
            }}
          >
            {formatTimeDifference(
              punchesForCurrentWeek.reduce(
                (total, employee) =>
                  total +
                  getTimeDifference(
                    employee.InTime.toDate(),
                    employee.OutTime.toDate()
                  ),
                0
              )
            )}
          </TableCell>
          {/* VAC */}
          <TableCell align="center" size="small" sx={weeklytotals}>
            {calculateTotalPTOForEmployees(punchesForCurrentWeek)}
          </TableCell>
          {/* HOLIDAY */}
          <TableCell align="center" size="small" sx={weeklytotals}>
            {parseFloat(
              calculateTotalHolidayHoursForEmployees(punchesForCurrentWeek)
            ).toFixed(2)}
          </TableCell>
          {/* TIPS */}
          <TableCell align="center" size="small" sx={weeklytotals}>
            {punchesForCurrentWeek
              .reduce((total, employee) => total + employee.Tips, 0)
              .toFixed(2)}
          </TableCell>
          {/* RMS */}
          <TableCell align="center" size="small" sx={weeklytotals}>
            {punchesForCurrentWeek.reduce(
              (total, employee) => total + employee.Rooms,
              0
            )}
          </TableCell>
          {sumA !== 0 && (
            <TableCell
              align="center"
              size="small"
              sx={{
                fontWeight: 400,
                fontSize: "0.8rem",
                minWidth: "2.7rem",
                backgroundColor: "#fcfcfc",
              }}
            >
              {sumA}
            </TableCell>
          )}
          {sumB !== 0 && (
            <TableCell
              align="center"
              size="small"
              sx={{
                fontWeight: 400,
                fontSize: "0.8rem",
                minWidth: "2.7rem",
                backgroundColor: "#fcfcfc",
              }}
            >
              {sumB}
            </TableCell>
          )}
          {sumC !== 0 && (
            <TableCell
              align="center"
              size="small"
              sx={{
                fontWeight: 400,
                fontSize: "0.8rem",
                minWidth: "2.7rem",
                backgroundColor: "#fcfcfc",
              }}
            >
              {sumC}
            </TableCell>
          )}
        </TableRow>
      </TableFooter>
    );
  };

  return (
    <div>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={4}>
          <Typography
            sx={{
              marginRight: 9,
              letterSpacing: "0.5rem",
              fontWeight: "200",
              fontSize: "1.2rem",
              color: "#101010",
            }}
            variant="body1"
            align="left"
          >
            TIMECARD
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormControl
            sx={{
              minWidth: "15rem",
              "@media (max-width: 600px)": { minWidth: "9rem" },
            }}
          >
            <InputLabel
              htmlFor="property-code"
              sx={{
                fontSize: "0.7rem",
                fontWeight: 400,
                color: "#020202",
                transition: "0.3s",
              }}
            >
              PROPERTY CODE
            </InputLabel>
            <Select
              value={selectedPropertyCode}
              onChange={handlePropertyCodeChange}
              inputProps={{ name: "property-code", id: "property-code" }}
              sx={{
                fontSize: "0.9rem",
                backgroundColor: "#ffffff",
                fontWeight: 800,
                borderRadius: "27px",
                letterSpacing: "0.09rem",
              }}
            >
              {propertyCodes.map((code) => (
                <MenuItem key={code} value={code} sx={{ fontSize: "0.9rem" }}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormControl
            sx={{
              minWidth: "15rem",
              "@media (max-width: 600px)": { minWidth: "9rem" },
            }}
          >
            <InputLabel
              htmlFor="employee-id"
              sx={{
                fontSize: "0.7rem",
                fontWeight: 400,
                color: "#020202",
                transition: "0.3s",
              }}
            >
              EMPLOYEE ID
            </InputLabel>
            <Select
              value={selectedEmployeeId}
              onChange={(event) => setSelectedEmployeeId(event.target.value)}
              inputProps={{ name: "employee-id", id: "employee-id" }}
              sx={{
                fontSize: "0.9rem",
                backgroundColor: "#ffffff",
                fontWeight: 800,
                borderRadius: "27px",
                letterSpacing: "0.09rem",
              }}
            >
              {Object.entries(empIds)
                .filter(([propCode]) => propCode === selectedPropertyCode)
                .map(([propCode, empId]) => (
                  <MenuItem
                    key={empId}
                    value={empId}
                    sx={{ fontSize: "0.9rem" }}
                  >
                    {closedPayPeriods[empId] ? (
                      <BsFillPatchCheckFill color="#4a6741" />
                    ) : areAllPunchesApproved(empId) ? (
                      <BsFillPatchCheckFill color="#FFC107" />
                    ) : null}{" "}
                    {empId}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box height={16} />

      <Card sx={{ borderRadius: "18px" }}>
        <div>
          <Card
            sx={{
              borderRadius: "18px",
            }}
          >
            {/* GRID CONTAINS SELECTORS AND ACTIONS */}
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={3}
              paddingBottom="1rem"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  marginTop="0.81rem"
                  marginBottom="0.81rem"
                >
                  {payPeriodStart && payPeriodEnd && (
                    <Grid item xs={12} sm={4}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                      >
                        <Grid item>
                          <IconButton
                            onClick={() => changePayPeriod("left")}
                            sx={{
                              color: "#101010",
                              transition: "0.3s",
                              fontSize: "0.99rem",
                            }}
                          >
                            <FaChevronLeft />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            align="center"
                            sx={{
                              fontWeight: 600,
                              letterSpacing: "0.1rem",
                              fontSize: "0.9rem",
                              textAlign: "center",
                            }}
                          >
                            {payPeriodStart.toLocaleDateString()} -{" "}
                            {new Date(
                              payPeriodEnd.getTime() - 86400000
                            ).toLocaleDateString()}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => changePayPeriod("right")}
                            disabled={isPayPeriodEndGreaterThanCurrent}
                            sx={{
                              color: "#101010",
                              transition: "0.3s",
                              fontSize: "0.99rem",
                            }}
                          >
                            <FaChevronRight />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={4}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Tooltip title="APPROVE">
                        <IconButton
                          onClick={handleClosePayPeriod}
                          sx={{ fontSize: "1.8rem", color: "#FFC107" }}
                          disabled={
                            !canClosePayPeriod() ||
                            canClosePayPeriodBasedOnDate()
                          }
                        >
                          <BsFillPatchCheckFill />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          letterSpacing: "0.1rem",
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        APPROVE TIMECARD
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 200,
                          letterSpacing: "0.1rem",
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        Aprobar la hoja de horas
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Tooltip title="Add Missing Punch">
                        <IconButton
                          onClick={handleClickOpen}
                          sx={{ fontSize: "2rem", color: "#4a6741" }}
                        >
                          <FcPlus />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          letterSpacing: "0.1rem",
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        MISSING A PUNCH?
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 200,
                          letterSpacing: "0.1rem",
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        Olvidaste marcar tu entrada o salida?
                      </Typography>

                      <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          sx: {
                            padding: "1rem",
                            borderRadius: "36px",
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <DialogTitle
                          sx={{
                            letterSpacing: "0.2rem",
                            fontWeight: "400",
                            fontSize: "1.2rem",
                            color: "#fcfcfc",
                            backgroundColor: "#4a6741",
                            borderRadius: "18px",
                          }}
                        >
                          SUBMIT MISSING PUNCH
                        </DialogTitle>
                        <DialogContent
                          sx={{
                            letterSpacing: "0.4rem",
                            fontWeight: "400",
                            fontSize: "1rem",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  letterSpacing: "0.1rem",
                                  marginTop: 3,
                                }}
                              >
                                WHICH DAY DID YOU MISS?
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: 200,
                                  letterSpacing: "0.1rem",
                                }}
                              >
                                Qué día faltaste?
                              </Typography>
                              <Typography
                                sx={{ fontWeight: 600, marginTop: 2 }}
                              >
                                Once submitted, it will appear on your timecard
                                after it is reviewed.
                              </Typography>
                              <Typography sx={{ fontWeight: 200 }}>
                                Una vez que lo envíes, aparecerá en tu hoja de
                                horas una vez que haya sido aprobado.
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Date"
                                type="date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  min: new Date(
                                    Date.now() - 27 * 24 * 60 * 60 * 1000
                                  )
                                    .toISOString()
                                    .slice(0, 10),
                                  max: new Date().toISOString().slice(0, 10),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleSubmit}
                            color="secondary"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "27px",
                              padding: "9px 9px 9px 9px",
                              color: "#101010",
                              fontWeight: "400",
                              fontSize: "1rem",
                              border: "2px solid #101010",

                              "&:hover": {
                                backgroundColor: "#101010",
                                opacity: "0.6425339366515838",
                                color: "#fcfcfc",
                              },
                            }}
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
                <Table
                  id="PAYROLL"
                  sx={{
                    width: "100%",
                    borderRadius: "27px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="left"
                        sx={{
                          fontWeight: 400,
                          fontSize: "1rem",
                          backgroundColor: "#f5f5f5",
                          color: "#020202",
                          maxWidth: "3.6rem",
                          letterSpacing: "0.1rem",
                        }}
                      >
                        {selectedEmployeeId && payPeriodEnd
                          ? (() => {
                              // Find the employee with the selectedEmployeeId
                              const employee = punches.find(
                                (emp) => emp.EmployeeId === selectedEmployeeId
                              );
                              // If no such employee exists, return an empty string
                              if (!employee) {
                                return "";
                              }
                              // If the employee does exist, return the formatted string
                              const payPeriodEndDisplay = new Date(
                                payPeriodEnd
                              );
                              payPeriodEndDisplay.setDate(
                                payPeriodEndDisplay.getDate() - 1
                              );
                              return `${employee.FirstName} ${
                                employee.LastName
                              } ${payPeriodEndDisplay.toLocaleDateString()}`;
                            })()
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={maintitle200header}
                        align="center"
                      ></TableCell>
                      <TableCell sx={maintitle200header} align="left">
                        IN
                      </TableCell>
                      <TableCell sx={maintitle200header} align="right">
                        OUT
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 200,
                          fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
                          backgroundColor: "#f5f5f5",
                          color: "#020202",
                          maxWidth: "3.6rem",
                          letterSpacing: "0.1rem",
                        }}
                        align="left"
                      ></TableCell>
                      <TableCell sx={maintitle200header} align="left">
                        RMS
                      </TableCell>
                      <TableCell sx={maintitle200header} align="center">
                        TIME
                      </TableCell>
                      <TableCell sx={maintitle200header} align="left">
                        TIPS
                      </TableCell>
                      <TableCell sx={maintitle200header} align="left">
                        VAC
                      </TableCell>
                      <TableCell sx={maintitle200header} align="center">
                        HOL
                      </TableCell>
                      {/* CUMULATIVE */}
                      <TableCell sx={maintitle200header} align="center">
                        TOTAL
                      </TableCell>
                      <TableCell sx={maintitle200header} align="center">
                        STATUS
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {selectedEmployeeId && (
                    <React.Fragment>
                      {Object.entries(groupedEmployeesByWeeks)
                        .sort((a, b) => new Date(a[0]) - new Date(b[0]))
                        .map(([weekNumber, employeesInWeek]) => {
                          const selectedEmployeeFilteredInWeek =
                            employeesInWeek.filter(
                              (employee) =>
                                employee.EmployeeId === selectedEmployeeId
                            );

                          return (
                            <React.Fragment key={`week-${weekNumber}`}>
                              <TableBody>
                                {sortEmployeesByInTime(employeesInWeek)
                                  .filter(
                                    (employee) =>
                                      employee.EmployeeId === selectedEmployeeId
                                  )
                                  .map((employee, index) => {
                                    const {
                                      InTime,
                                      Department,
                                      PTO,
                                      Rooms,
                                      Tips,
                                      PayType,
                                      Status,
                                      PayRate,
                                    } = employee;
                                    const inTime = employee.InTime.toDate();
                                    const outTime = employee.OutTime.toDate();
                                    const timeDifference = getTimeDifference(
                                      inTime,
                                      outTime
                                    );
                                    const ptoValue = employee.PTO
                                      ? parseInt(employee.PTO, 10)
                                      : null;
                                    const totalTime = ptoValue
                                      ? ptoValue
                                      : timeDifference;

                                    // Calculate rate per hour
                                    const cumulativeTotalHours =
                                      selectedEmployeeFilteredInWeek
                                        .slice(0, index + 1)
                                        .reduce(
                                          (totalHours, currentEmployee) => {
                                            const inTime =
                                              currentEmployee.InTime.toDate();
                                            const outTime =
                                              currentEmployee.OutTime.toDate();
                                            const timeDifference =
                                              getTimeDifference(
                                                inTime,
                                                outTime
                                              );
                                            const decimalHours = parseFloat(
                                              differenceToDecimal(
                                                timeDifference
                                              )
                                            );

                                            const newTotalHours =
                                              parseFloat(totalHours) +
                                              (decimalHours || 0);

                                            return newTotalHours;
                                          },
                                          0
                                        );

                                    const payPeriodStartDateIndex =
                                      findPayPeriodStartDateIndex(
                                        sortEmployeesByInTime(
                                          employeesInWeek
                                        ).filter(
                                          (employee) =>
                                            employee.EmployeeId ===
                                            selectedEmployeeId
                                        ),
                                        payPeriodStart
                                      );

                                    return (
                                      <React.Fragment
                                        key={`employee-${employee.id}`}
                                      >
                                        {payPeriodStartDateIndex === index && (
                                          <TableRow>
                                            <TableCell
                                              colSpan={18}
                                              size="small"
                                              sx={{
                                                fontWeight: "200",
                                                fontSize: "1rem",
                                                letterSpacing: "0.2rem",
                                                color: "#fcfcfc",
                                                textAlign: "left",
                                                backgroundColor: "#4a6741",
                                              }}
                                            >
                                              <BsCalendarWeek /> WK:{" "}
                                              {weekNumber}
                                            </TableCell>
                                          </TableRow>
                                        )}
                                        <TableRow hover key={employee.id}>
                                          <TableCell
                                            sx={{
                                              letterSpacing: "0.1rem",
                                              fontWeight: "600",
                                              fontSize: "0.8rem",
                                              color: "#020202",
                                            }}
                                            size="small"
                                            align="left"
                                          >
                                            {formatDate(
                                              employee.InTime.toDate()
                                            )}
                                          </TableCell>
                                          <TableCell
                                            sx={maintable}
                                            align="center"
                                            size="small"
                                          >
                                            <Tooltip
                                              className="nav-icon"
                                              title={employee.PayType}
                                            >
                                              <Box sx={{ fontSize: "1.2rem" }}>
                                                {employee.PayType ===
                                                "PER ROOM" ? (
                                                  <MdOutlineKingBed />
                                                ) : employee.PayType ===
                                                  "PER HOUR" ? (
                                                  <TbClockHour10 />
                                                ) : employee.PayType ===
                                                  "SALARY" ? (
                                                  <FaBusinessTime />
                                                ) : employee.PayType ===
                                                  "error" ? (
                                                  <MdClose />
                                                ) : null}
                                              </Box>
                                            </Tooltip>
                                          </TableCell>
                                          {/* DEPT */}
                                          <TableCell
                                            sx={maintable}
                                            size="small"
                                            align="left"
                                          >
                                            <Tooltip className="nav-icon">
                                              <Box>
                                                <Typography
                                                  sx={{
                                                    letterSpacing: "0.1rem",
                                                    fontWeight: "600",
                                                    fontSize: "0.6rem",
                                                    color: "#020202",
                                                  }}
                                                >
                                                  {Department}
                                                </Typography>
                                              </Box>
                                            </Tooltip>
                                          </TableCell>
                                          {/* MOOD IN */}
                                          <TableCell
                                            sx={maintable}
                                            align="right"
                                            size="small"
                                          >
                                            <Tooltip
                                              className="nav-icon"
                                              title={employee.MoodIn}
                                            >
                                              <Box sx={{ fontSize: "1.2rem" }}>
                                                {getMoodIcon(employee.MoodIn)}
                                              </Box>
                                            </Tooltip>
                                          </TableCell>
                                          {/* IN TIME */}
                                          <TableCell
                                            align="left"
                                            size="small"
                                            sx={maintable}
                                          >
                                            {formatDateTime(
                                              employee.InTime.toDate()
                                            )}
                                          </TableCell>
                                          {/* OUT TIME */}
                                          <TableCell
                                            align="right"
                                            size="small"
                                            sx={maintable}
                                          >
                                            {formatDateTime(
                                              employee.OutTime.toDate()
                                            )}
                                          </TableCell>
                                          {/* MOOD OUT */}
                                          <TableCell
                                            sx={maintable}
                                            align="left"
                                            size="small"
                                          >
                                            <Tooltip
                                              className="nav-icon"
                                              title={employee.MoodOut}
                                            >
                                              <Box sx={{ fontSize: "1.2rem" }}>
                                                {getMoodIcon(employee.MoodOut)}
                                              </Box>
                                            </Tooltip>
                                          </TableCell>
                                          {/* ROOMS */}
                                          <TableCell
                                            align="center"
                                            size="small"
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: {
                                                xs: "0.6rem",
                                                sm: "0.7rem",
                                                md: "0.8rem",
                                              },
                                              maxWidth: "3.6rem",
                                              backgroundColor:
                                                Rooms > 0
                                                  ? "#f5df4d"
                                                  : "inherit",
                                            }}
                                          >
                                            {Rooms !== 0 ? Rooms : ""}
                                          </TableCell>
                                          {/* TOTAL TIME WORKED */}
                                          <TableCell
                                            align="center"
                                            size="small"
                                            sx={{
                                              fontWeight: 400,
                                              fontSize: "0.8rem",
                                              backgroundColor: "#fcfcfc",
                                              color: "#101010",
                                              minWidth: "2.7rem",
                                            }}
                                          >
                                            {formatTimeDifference(
                                              timeDifference
                                            )}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            size="small"
                                            sx={maintable}
                                          >
                                            {Tips !== 0 ? Tips.toFixed(2) : ""}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            size="small"
                                            sx={maintable}
                                          >
                                            {PTO !== 0 ? PTO : ""}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            size="small"
                                          >
                                            <Checkbox
                                              checked={employee.Holiday}
                                              disabled={true} // Always disabled
                                              sx={{
                                                height: "0.5rem",
                                                width: "0.5rem",
                                              }}
                                            />
                                          </TableCell>
                                          {/* CUMULATIVE TIME */}
                                          <TableCell
                                            align="center"
                                            size="small"
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "0.8rem",
                                              maxWidth: "3.6rem",
                                              backgroundColor:
                                                cumulativeTotalHours > 40
                                                  ? "#F98B85"
                                                  : "inherit",
                                              color:
                                                cumulativeTotalHours > 40
                                                  ? "white"
                                                  : "inherit",
                                            }}
                                          >
                                            {cumulativeTotalHours.toFixed(2)}
                                          </TableCell>
                                          {/* STATUS */}
                                          <TableCell
                                            sx={{
                                              fontWeight: 400,
                                              fontSize: "0.8rem",
                                              maxWidth: "3.6rem",
                                            }}
                                            align="center"
                                            size="small"
                                          >
                                            <Tooltip
                                              title={Status}
                                              className="nav-icon"
                                            >
                                              <Box sx={{ fontSize: "1.2rem" }}>
                                                {Status === "Open" ? (
                                                  <FcUnlock />
                                                ) : Status === "Modified" ? (
                                                  <FcDisclaimer />
                                                ) : Status === "Manual" ? (
                                                  <FcDisclaimer />
                                                ) : Status === "Closed" ? (
                                                  <FcPrivacy />
                                                ) : Status === "Mod/Closed" ? (
                                                  <FcExpired />
                                                ) : Status === "Man/Closed" ? (
                                                  <FcExpired />
                                                ) : null}
                                              </Box>
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    );
                                  })}
                              </TableBody>
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Card>
          <Box height={16} />
          <Card
            sx={{
              borderRadius: "18px",
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Grid item>
                <CardHeader
                  title="TOTALS"
                  sx={{
                    color: "#101010",
                    fontWeight: 200,
                    fontSize: "0.9rem",
                  }}
                />
              </Grid>
              <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
                {/* RENDER WEEK TOTAL TABLE */}
                <Table sx={{ width: "100%" }}>
                  <TableHead>{/* ... */}</TableHead>
                  {selectedEmployeeId && (
                    <React.Fragment>
                      {Object.entries(groupedEmployeesByWeeks)
                        .sort((b, a) => new Date(a[0]) - new Date(b[0]))
                        .map(([weekNumber, employeesInWeek]) => {
                          const selectedEmployeeFilteredInWeek =
                            employeesInWeek.filter(
                              (employee) =>
                                employee.EmployeeId === selectedEmployeeId
                            );

                          return (
                            <React.Fragment key={`week-${weekNumber}`}>
                              <TableBody>{/* ... */}</TableBody>
                              {renderWeekTotal(
                                weekNumber,
                                selectedEmployeeFilteredInWeek
                              )}
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  )}
                </Table>
              </TableContainer>

              <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
                {/* TOTALS TABLE */}
                <Table id="TOTAL" sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={maintitle} align="center" size="small">
                        TIME
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        OT
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        VAC
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        HOL
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        TIPS
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        RMS
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        A
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        B
                      </TableCell>
                      <TableCell sx={maintitle} align="center" size="small">
                        C
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {/* TIME */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          fontWeight: 600,
                          fontSize: "1rem",
                          color: "#101010",
                          backgroundColor: "#fcfcfc",
                        }}
                      >
                        {formatTimeDifference(
                          punches.reduce(
                            (total, employee) =>
                              total +
                              getTimeDifference(
                                employee.InTime.toDate(),
                                employee.OutTime.toDate()
                              ),
                            0
                          )
                        )}
                      </TableCell>
                      {/* OVERTIME */}
                      <TableCell align="center" size="small" sx={mainSx}>
                        {calculateOvertimeForEmployees(
                          punches,
                          payPeriodStart
                        ).toFixed(2)}
                      </TableCell>

                      {/* PTO */}
                      <TableCell align="center" size="small" sx={mainSx}>
                        {calculateTotalPTOForEmployees(punches)}
                      </TableCell>
                      {/* HOLIDAY */}
                      <TableCell align="center" size="small" sx={mainSx}>
                        {parseFloat(
                          calculateTotalHolidayHoursForEmployees(punches)
                        ).toFixed(2)}
                      </TableCell>
                      <TableCell align="center" size="small" sx={mainSx}>
                        {punches
                          .reduce((total, employee) => total + employee.Tips, 0)
                          .toFixed(2)}
                      </TableCell>
                      {/* RMS */}
                      <TableCell align="center" size="small" sx={mainSx}>
                        {punches.reduce(
                          (total, employee) => total + employee.Rooms,
                          0
                        )}
                      </TableCell>
                      {/* A */}
                      <TableCell align="center" size="small" sx={mainSx}>
                        {punches.reduce(
                          (total, employee) => total + employee.A,
                          0
                        )}
                      </TableCell>
                      {/* B */}
                      <TableCell align="center" size="small" sx={mainSx}>
                        {punches.reduce(
                          (total, employee) => total + employee.B,
                          0
                        )}
                      </TableCell>
                      {/* C */}
                      <TableCell align="center" size="small" sx={mainSx}>
                        {punches.reduce(
                          (total, employee) => total + employee.C,
                          0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Dialog
              open={confirmDialogOpen}
              onClose={handleCancelClosePayPeriod}
              PaperProps={{
                sx: {
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              <DialogTitle
                id="confirm-dialog-title"
                sx={{
                  letterSpacing: "0.2rem",
                  fontWeight: "400",
                  fontSize: "1.2rem",
                  color: "#fcfcfc",
                  backgroundColor: "#4a6741",
                  borderRadius: "18px",
                }}
              >
                APPROVE TIMECARD
              </DialogTitle>
              <DialogContent
                sx={{
                  letterSpacing: "0.4rem",
                  fontWeight: "400",
                  fontSize: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    marginTop: 2,
                    letterSpacing: "0.2rem",
                  }}
                >
                  APROBAR LA HOJA DE HORAS
                </Typography>
                <Typography
                  id="confirm-dialog-description"
                  sx={{
                    fontWeight: 400,
                    marginTop: 2,
                  }}
                >
                  Are you sure you have read all punches and want to approve the
                  time card for this pay period?
                </Typography>
                <Typography
                  id="confirm-dialog-description"
                  sx={{
                    fontWeight: 200,
                    marginTop: 1,
                  }}
                >
                  Estás seguro de haber leído todos los registros de entrada y
                  salida y de querer aprobar la hoja de tiempo para este período
                  de pago?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelClosePayPeriod} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmClosePayPeriod}
                  color="secondary"
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "27px",
                    padding: "9px 9px 9px 9px",
                    color: "#101010",
                    fontWeight: "400",
                    fontSize: "1rem",
                    border: "2px solid #101010",

                    "&:hover": {
                      backgroundColor: "#101010",
                      opacity: "0.6425339366515838",
                      color: "#fcfcfc",
                    },
                  }}
                >
                  CONFIRM
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </div>
      </Card>
    </div>
  );
};

export default MyTime;

const maintitle200header = {
  fontWeight: 800,
  fontSize: "0.9rem",
  backgroundColor: "#f5f5f5",
  color: "#020202",
  maxWidth: "3.6rem",
};
const maintable = {
  letterSpacing: "0.1rem",
  fontWeight: 400,
  fontSize: "0.81rem",
  backgroundColor: "#ffffff",
  color: "#101010",
  minWidth: "2.7rem",
};
const mainSx = {
  letterSpacing: "0.1rem",
  fontWeight: "400",
  fontSize: "0.8rem",
  color: "#020202",
  maxWidth: "3.6rem",
};
const maintitle = {
  letterSpacing: "0.1rem",
  fontWeight: 200,
  fontSize: "0.9rem",
  backgroundColor: "#4a6741",
  color: "#fcfcfc",
  minWidth: "2.7rem",
};
const weeklytotals = {
  fontWeight: 400,
  fontSize: "0.8rem",
  minWidth: "2.7rem",
  backgroundColor: "#fcfcfc",
};
