import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import { db } from "../../firebase/firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@mui/material";
import Lottie from "lottie-react";
import animationData from "../../assets/tricube.json";
import "../../components/App.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [role, setRole] = useState("");
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPropertyCodesAndRole = async () => {
      if (!email) return;

      const propertiesRef = collection(db, "Properties");
      const foundRoles = [];

      for (const role of [
        "Regional",
        "Accounting",
        "Manager",
        "AGM",
        "OtherAdmin",
        "Sales",
        "Property",
      ]) {
        const emailField = `${role}Email`;
        const q = query(propertiesRef, where(emailField, "==", email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          foundRoles.push(role);
        }
      }

      const highestRole = getHighestRole(foundRoles);
      setRole(highestRole);

      const propCodes = [];
      if (foundRoles.length > 0) {
        const emailField = `${highestRole}Email`;
        const q = query(propertiesRef, where(emailField, "==", email));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          propCodes.push(doc.data().propCode);
        });
      } else {
        const empRegisterRef = collection(db, "empRegister");
        const q = query(empRegisterRef, where("AdEmail", "==", email));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          propCodes.push(doc.data().PropID);
        });
      }

      setPropertyCodes(propCodes);
    };

    fetchPropertyCodesAndRole();
  }, [email]);

  const getHighestRole = (roles) => {
    const rolePriority = [
      "Global",
      "Regional",
      "Accounting",
      "Manager",
      "AGM",
      "OtherAdmin",
      "Sales",
      "Property",
    ];
    return rolePriority.find((role) => roles.includes(role)) || "Staff";
  };

  const signup = async (email, password) => {
    setIsPending(true);
    setError(null);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (!userCredential) {
        throw new Error("Unable to register. Please try again later.");
      }

      await updateProfile(auth.currentUser, {
        displayName: name,
        photoURL: role,
      });

      await sendEmailVerification(userCredential.user);

      setIsPending(false);
      setOpenDialog(true);
      setError(null);
    } catch (error) {
      console.log("error", error);
      setError(error.message);
      setIsPending(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmedPassword) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setIsPending(true);
      await signup(email, password);
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      setError(err.message);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "69vh",
      }}
    >
      <Grid item xs={12} sm={9} md={9}>
        <Grid item xs={12} container justifyContent="center">
          <Card
            sx={{
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",
              marginTop: "1.8rem",
            }}
          >
            <Lottie animationData={animationData} style={{ height: "9rem" }} />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "3rem 4.5rem 3rem 4.5rem",
                      width: "100%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Email Address"
                          type="email"
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                          required
                          fullWidth
                          sx={{
                            ...commonSx,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "54px",
                            },
                            ".MuiInputBase-input": {
                              fontSize: "0.9rem",
                            },
                            ".MuiInputLabel-root": {
                              fontSize: "0.9rem",
                              fontWeight: 200,
                              color: "#020202",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Name (First Last)"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                          fullWidth
                          sx={{
                            ...commonSx,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "54px",
                            },
                            ".MuiInputBase-input": {
                              fontSize: "0.9rem",
                            },
                            ".MuiInputLabel-root": {
                              fontSize: "0.9rem",
                              fontWeight: 200,
                              color: "#020202",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          fullWidth
                          sx={{
                            ...commonSx,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "54px",
                            },
                            ".MuiInputBase-input": {
                              fontSize: "0.9rem",
                            },
                            ".MuiInputLabel-root": {
                              fontSize: "0.9rem",
                              fontWeight: 200,
                              color: "#020202",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Confirm Password"
                          type="password"
                          value={confirmedPassword}
                          onChange={(e) => setConfirmedPassword(e.target.value)}
                          required
                          fullWidth
                          sx={{
                            ...commonSx,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "54px",
                            },
                            ".MuiInputBase-input": {
                              fontSize: "0.9rem",
                            },
                            ".MuiInputLabel-root": {
                              fontSize: "0.9rem",
                              fontWeight: 200,
                              color: "#020202",
                            },
                          }}
                        />
                      </Grid>

                      {role && (
                        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                          <CheckCircleIcon
                            style={{
                              color: "#4a6741",
                              marginRight: "0.5rem",
                              verticalAlign: "middle",
                            }}
                          />
                          <Typography>{role}</Typography>
                        </Box>
                      )}

                      {propertyCodes.length > 0 && (
                        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                          <CheckCircleIcon
                            style={{
                              color: "#4a6741",
                              marginRight: "0.5rem",
                              verticalAlign: "middle",
                            }}
                          />
                          <Typography>{propertyCodes.join(", ")}</Typography>
                        </Box>
                      )}

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isPending || propertyCodes.length === 0}
                          fullWidth
                          sx={{
                            backgroundColor: "#4a6741",
                            borderRadius: "27px",
                            justifyContent: "center",
                            color: "#fcfcfc",
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            letterSpacing: "0.1rem",
                            padding: "0.81rem 1rem 0.81rem 1rem",
                            "&:hover": {
                              backgroundColor: "#4a6741",
                            },
                          }}
                        >
                          REGISTER
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          navigate("/");
        }}
      >
        <DialogTitle>Registration Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please check your email to verify your account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              navigate("/");
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const commonSx = {
  marginBottom: "0.2rem",
  transition: "0.3s",
  maxWidth: "99rem",
  ".MuiInputLabel-root": {
    fontSize: "0.9rem",
    fontWeight: 200,
    color: "#020202",
  },
  ".MuiInputBase-input": {
    fontSize: "0.9rem",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "27px",
  },
};
