import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import Lottie from "lottie-react";
import animationData from "../../assets/tricube.json";
import "../../components/App.css";
import { NumericFormat } from "react-number-format";
import InputMask from "react-input-mask";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { updateProfile } from "firebase/auth";

const SubmissionDialog = ({ open, onClose, isSubmitting, message }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        padding: "1rem",
        borderRadius: "27px",
        backgroundColor: "#f9f9f9",
      },
    }}
  >
    <DialogContent>
      {isSubmitting ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundColor: "#f9f9f9",
          }}
        >
          <CircularProgress style={{ color: "#101010" }} />
        </div>
      ) : (
        <p
          style={{
            textAlign: "center",
            color: "#101010",
            fontSize: "1rem",
            fontWeight: "800",
            letterSpacing: "0.1rem",
          }}
        >
          {message}
        </p>
      )}
    </DialogContent>
  </Dialog>
);

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const [userType, setUserType] = useState("Hotel Staff");
  const [userInput, setUserInput] = useState("");
  const [identificationType, setIdentificationType] = useState("STAFF");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(""));
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [recaptchaInitialized, setRecaptchaInitialized] = useState(false);
  const [language, setLanguage] = useState("en");
  const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const recaptchaVerifierRef = useRef(null);
  const getErrorMessage = (key) => translations[language].errors[key];
  const inputRef = useRef(null);

  const sendResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent. Check your inbox.");
    } catch (error) {
      setError("Failed to send password reset email. Please try again later.");
    }
  };

  const login = async (email, password) => {
    setIsPending(true);
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (!userCredential) {
        throw new Error("Could not login. Try again later.");
      }

      if (!userCredential.user.emailVerified) {
        setError(
          "Please VERIFY YOUR EMAIL before proceeding. Check your inbox or spam folder for the verification email. Alternatively, press the RESET PASSWORD button below and follow instructions in the RESET EMAIL."
        );
        handleClickLogOut();
        return;
      }

      dispatch({ type: "LOGIN", payload: userCredential.user });

      console.log(userCredential);
      setIsPending(false);
      setError(null);
      navigate("/");
    } catch (error) {
      console.log("error", error);

      if (error.code === "auth/wrong-password") {
        setError("Incorrect password. Please try again.");
      } else if (error.code === "auth/user-not-found") {
        setError("User not found.");
      } else {
        setError(error.message);
      }

      setIsPending(false);
    }
  };

  const handleClickLogOut = async () => {
    await signOut(auth);
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login(email, password);
  };

  const handleCellPhoneSubmit = async (user, phoneNumber) => {
    try {
      console.log("Starting handleCellPhoneSubmit...");

      const empRegisterRef = collection(db, "empRegister");
      const phoneAuthUsersRef = collection(db, "phoneAuthUsers");

      const empQuery = query(
        empRegisterRef,
        where("AdPhone", "==", phoneNumber)
      );
      const empSnapshot = await getDocs(empQuery);

      if (!empSnapshot.empty) {
        let propIDs = [];
        let empIDs = [];
        let empFirstName = "";
        let empLastName = "";
        let empEmail = "";
        let profilePictureURL = ""; // Variable to store profile picture URL
        let phoneChangeDate = null; // Variable to store phone change date

        empSnapshot.docs.forEach((doc, index) => {
          const empData = doc.data();
          propIDs.push(empData.PropID);
          empIDs.push(empData.EmpID);
          if (index === 0) {
            // Use data from the first document for name and email
            empFirstName = empData.NameFirst || "";
            empLastName = empData.NameLast || "";
            empEmail = `${user.uid.toLowerCase()}@back-house.com`;
          }
        });

        console.log(
          `Found employee: ${empFirstName} ${empLastName}, Email: ${empEmail}`
        );

        // Fetch existing user data to retain profilePictureURL and phoneChangeDate
        const userDocRef = doc(phoneAuthUsersRef, phoneNumber);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          profilePictureURL = userData.profilePictureURL || "";
          phoneChangeDate = userData.phoneChangeDate || null;
        }

        // Update auth current user
        await updateProfile(auth.currentUser, {
          displayName: `${empFirstName} ${empLastName}`,
          photoURL: "Staff",
        });
        console.log("Updated current user profile.");

        // Add user to the phoneAuthUsers collection
        await setDoc(userDocRef, {
          uid: auth.currentUser.uid,
          isPhoneNumberAuth: true,
          displayName: `${empFirstName} ${empLastName}`,
          email: empEmail,
          propID: propIDs,
          empID: empIDs,
          profilePictureURL: profilePictureURL, // Retain the profile picture URL
          phoneChangeDate: phoneChangeDate, // Retain the phone change date
        });
        console.log("Added user to phoneAuthUsers collection.");

        // Update AdEmail field in empRegister, skip if Status is "Terminated"
        for (const empDoc of empSnapshot.docs) {
          const empData = empDoc.data();
          if (empData.Status !== "Terminated") {
            await updateDoc(doc(empRegisterRef, empDoc.id), {
              AdEmail: empEmail,
            });
            console.log(
              `Updated AdEmail field in empRegister for document ID: ${empDoc.id}`
            );
          } else {
            console.log(
              `Skipped updating AdEmail for document ID: ${empDoc.id} due to Status: Terminated`
            );
          }
        }

        // Call Firebase function to mark the email as verified
        await fetch(
          "https://us-central1-doorman-1985.cloudfunctions.net/markEmailVerifiedAndUpdateUser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: auth.currentUser.uid,
              email: empEmail.toLowerCase(),
            }),
          }
        );

        setSubmissionMessage("Registration complete");
        setIsSubmitting(false);
        setSubmissionDialogOpen(true);

        setTimeout(() => {
          setSubmissionDialogOpen(false);
          dispatch({ type: "LOGIN", payload: user }); // Dispatch the login action
          navigate("/"); // Navigate to root
        }, 2000);
      } else {
        console.log("No employee found in empRegister with this phone number.");
        setError("No employee found with this phone number.");
        // await handleClickLogOut();
        throw new Error("No employee found with this phone number.");
      }
    } catch (error) {
      console.error("Error in handleCellPhoneSubmit:", error);
      setError(
        "An error occurred while processing your request. Please try again."
      );
      throw error; // Re-throw the error to stop further execution
    }
  };

  const handleVerifyCode = async () => {
    setIsPending(true);
    setError(null);
    setIsSubmitting(true);
    setSubmissionDialogOpen(true);
    try {
      const code = verificationCode.join("");
      const result = await window.confirmationResult.confirm(code);
      const user = result.user;

      console.log("User verified:", user);

      // Check if user exists in phoneAuthUsers
      const phoneAuthUsersRef = collection(db, "phoneAuthUsers");
      const q = query(phoneAuthUsersRef, where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log(
          "User not found in phoneAuthUsers. Fetching from empRegister..."
        );

        // If UID doesn't exist in phoneAuthUsers, find user details in empRegister and create user
        await handleCellPhoneSubmit(user, phoneNumber);
      } else {
        console.log("User already exists in phoneAuthUsers.");
        dispatch({ type: "LOGIN", payload: user }); // Dispatch the login action
        navigate("/", { state: { phoneNumber } }); // Pass phone number to the rest of the site
      }
    } catch (error) {
      console.error("Error during code verification", error);
      setError(getErrorMessage("tryAgain"));
      setSubmissionMessage("ERROR");
      setIsSubmitting(false);
      setSubmissionDialogOpen(true);
      setTimeout(async () => {
        await handleClickLogOut(); // Log out the user
      }, 2000);
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    // Initialize reCAPTCHA only once when the component mounts
    const initializeRecaptcha = () => {
      if (!window.recaptchaVerifier) {
        // Check if window.recaptchaVerifier exists
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              handleSendCode();
            },
          },
          auth
        );
      }

      // Assign the initialized window.recaptchaVerifier to the ref
      recaptchaVerifierRef.current = window.recaptchaVerifier;
    };

    initializeRecaptcha();

    return () => {
      // Correct cleanup for reCAPTCHA
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear(); // Clear reCAPTCHA verifier
        window.recaptchaVerifier = null; // Clear window.recaptchaVerifier
        recaptchaVerifierRef.current = null;
      }
    };
  }, []);

  const handlePhoneChange = (values) => {
    let { value } = values;

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, "");

    // Ensure it starts with a "1" and is followed by exactly 10 digits
    if (value.length > 0 && !value.startsWith("1")) {
      value = "1" + value;
    }

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    setPhoneNumber(value);
  };

  const handleSendCode = async () => {
    setIsPending(true);
    setError(null);

    try {
      const formattedPhoneNumber = `+${phoneNumber}`;
      console.log("Formatted Phone Number:", formattedPhoneNumber);

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhoneNumber,
        recaptchaVerifierRef.current
      );
      window.confirmationResult = confirmationResult;
      setIsCodeSent(true);
    } catch (error) {
      console.error("Error during signInWithPhoneNumber", error);

      if (error.code === "auth/invalid-phone-number") {
        setError(getErrorMessage("invalidPhoneNumber"));
      } else if (error.message.includes("reCAPTCHA")) {
        setError(getErrorMessage("recaptchaFailed"));
      } else {
        setError(getErrorMessage("sendCodeFailed"));
      }
    } finally {
      setIsPending(false);
    }
  };

  const handleCodeChange = (event) => {
    const value = event.target.value;
    const newCode = value.split("").map(Number);
    setVerificationCode(newCode);

    // Focus on the next input field
    const nextInput = document.getElementById(`code-input-${value.length}`);
    if (nextInput) {
      nextInput.focus();
    }
  };

  useEffect(() => {
    // Autofill detection (modern browsers)
    if (inputRef.current && inputRef.current.value) {
      handleCodeChange({ target: { value: inputRef.current.value } });
    }

    // Handle paste events
    const handlePaste = (event) => {
      const pastedData = event.clipboardData.getData("text/plain");
      if (/^\d{6}$/.test(pastedData)) {
        event.preventDefault();
        handleCodeChange({ target: { value: pastedData } });
      }
    };
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, []);

  const translations = {
    en: {
      verification: "LOGIN",
      enterCode: "VERIFICATION CODE",
      verifyCode: "VERIFY",
      errors: {
        tryAgain: "Please try again.",

        recaptchaFailed: "Please refresh the page and try again.",
        sendCodeFailed: "Failed to send verification code. Please try again.",
      },
    },
    es: {
      verification: "INICIAR SESIÓN",
      enterCode: "CÓDIGO DE VERIFICACIÓN",
      verifyCode: "VERIFICAR CÓDIGO",
      errors: {
        tryAgain: "Por favor, inténtelo de nuevo.",

        recaptchaFailed: "Por favor, actualice la página e inténtelo de nuevo.",
        sendCodeFailed:
          "Error al enviar el código de verificación. Por favor, inténtelo de nuevo.",
      },
    },
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "69vh",
      }}
    >
      <Grid item xs={12} sm={9} md={9}>
        <Grid item xs={12} container justifyContent="center">
          <ToggleButtonGroup
            sx={{ ...commonSx, marginTop: "0.9rem" }}
            color="success"
            value={identificationType}
            exclusive
            onChange={(e, newIdentificationType) => {
              if (newIdentificationType !== null) {
                setIdentificationType(newIdentificationType);
              }
            }}
          >
            <ToggleButton
              value="STAFF"
              style={{
                borderRadius: "54px 0px 0px 54px",
                padding: "1.08rem 1rem 1.08rem 1.9rem",
                color: identificationType === "STAFF" ? "#f5f5f5" : "#8C8C88",
                backgroundColor:
                  identificationType === "STAFF" ? "#c7d57f" : "#f5f5f5",
                borderTop: "6px solid #ffffff",
                borderRight: "0px solid #ffffff",
                borderBottom: "6px solid #ffffff",
                borderLeft: "6px solid #ffffff",
                fontSize: "11.7px",
                fontWeight: "400",
                textTransform: "uppercase",
                letterSpacing: "0.2rem",
              }}
            >
              STAFF
            </ToggleButton>

            <ToggleButton
              value="ADMIN"
              style={{
                borderRadius: "0px 54px 54px 0px",
                padding: "1.08rem 1.9rem 1.08rem 1rem",
                color: identificationType === "ADMIN" ? "#f5f5f5" : "grey",
                backgroundColor:
                  identificationType === "ADMIN" ? "#c7d57f" : "#f5f5f5",
                borderTop: "6px solid #ffffff",
                borderRight: "6px solid #ffffff",
                borderBottom: "6px solid #ffffff",
                borderLeft: "0px solid #ffffff",
                fontSize: "11.7px",
                fontWeight: "400",
                textTransform: "uppercase",
                letterSpacing: "0.2rem",
              }}
            >
              ADMIN
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <Card
            sx={{
              borderRadius: "36px",
              backgroundColor: "#f5f5f5",

              marginTop: "1.8rem",
            }}
          >
            <Lottie animationData={animationData} style={{ height: "9rem" }} />

            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  {identificationType === "STAFF" ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "1rem",
                        }}
                      >
                        <ToggleButtonGroup
                          value={language}
                          exclusive
                          onChange={(e, newLang) => {
                            if (newLang !== null) {
                              setLanguage(newLang);
                            }
                          }}
                          sx={{ ...commonSx, marginBottom: "1.8rem" }}
                        >
                          <ToggleButton
                            value="en"
                            sx={{
                              borderRadius: "54px 0px 0px 54px",
                              padding: "1.08rem 1rem 1.08rem 1.9rem",
                              borderTop: "6px solid #ffffff",
                              borderRight: "0px solid #ffffff",
                              borderBottom: "6px solid #ffffff",
                              borderLeft: "6px solid #ffffff",
                              fontSize: "11.7px",
                              fontWeight: "400",
                              textTransform: "uppercase",
                              letterSpacing: "0.2rem",
                            }}
                          >
                            ENGLISH
                          </ToggleButton>
                          <ToggleButton
                            value="es"
                            sx={{
                              borderRadius: "0px 54px 54px 0px",
                              padding: "1.08rem 1.9rem 1.08rem 1rem",
                              borderTop: "6px solid #ffffff",
                              borderRight: "6px solid #ffffff",
                              borderBottom: "6px solid #ffffff",
                              borderLeft: "0px solid #ffffff",
                              fontSize: "11.7px",
                              fontWeight: "400",
                              textTransform: "uppercase",
                              letterSpacing: "0.2rem",
                            }}
                          >
                            ESPAÑOL
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "1rem",
                        }}
                      >
                        {!isCodeSent ? (
                          <form onSubmit={(e) => e.preventDefault()}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <NumericFormat
                                  customInput={TextField}
                                  format="1##########"
                                  mask="_"
                                  fullWidth
                                  label="Phone Number"
                                  value={phoneNumber}
                                  onValueChange={handlePhoneChange}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "36px",
                                    },
                                    ".MuiInputBase-input": {
                                      fontSize: "0.9rem",
                                    },
                                    ".MuiInputLabel-root": {
                                      fontSize: "0.9rem",
                                      fontWeight: 200,
                                      color: "#020202",
                                    },
                                  }}
                                  InputProps={{
                                    inputMode: "tel",
                                    pattern: "[0-9]*",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    isPending || phoneNumber.length < 11
                                  }
                                  onClick={handleSendCode}
                                  fullWidth
                                  sx={{
                                    backgroundColor: "lightgrey",
                                    borderRadius: "36px",
                                    justifyContent: "center",
                                    color: "#202426",
                                    fontWeight: "800",
                                    fontSize: "0.9rem",
                                    letterSpacing: "0.2rem",
                                    padding: "0.81rem 1rem 0.81rem 1rem",
                                    borderTop: "2.7px solid transparent",
                                    borderRight: "2.7px solid transparent",
                                    borderBottom: "2.7px solid transparent",
                                    borderLeft: "2.7px solid transparent",

                                    "&:hover": {
                                      backgroundColor: "lightgrey",
                                      color: "#202426",
                                      borderTop: "2.7px solid #8C8C88",
                                      borderRight: "2.7px solid #8C8C88",
                                      borderBottom: "2.7px solid #8C8C88",
                                      borderLeft: "2.7px solid #8C8C88",
                                    },
                                    minWidth: "9rem",
                                  }}
                                >
                                  {translations[language].verification}
                                </Button>
                              </Grid>
                              {error && (
                                <Grid item xs={12}>
                                  <Typography
                                    color="error"
                                    sx={{
                                      color: "#010101",
                                      fontWeight: "200",
                                      fontSize: "1.17rem",
                                      paddingTop: "1rem",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {error ===
                                    "Firebase: Error (auth/invalid-email)."
                                      ? "INVALID EMAIL ADDRESS"
                                      : "ERROR"}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </form>
                        ) : (
                          <form onSubmit={(e) => e.preventDefault()}>
                            <Grid
                              container
                              spacing={2}
                              sx={{ textAlign: "center" }}
                            >
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "0.81rem",
                                  }}
                                >
                                  <InputMask
                                    mask="999999"
                                    value={verificationCode.join("")}
                                    onChange={handleCodeChange}
                                    ref={inputRef}
                                  >
                                    {() => (
                                      <TextField
                                        id="code-input"
                                        inputProps={{
                                          inputMode: "numeric",
                                          pattern: "[0-9]*",
                                          style: {
                                            textAlign: "center",
                                            fontSize: "1.53rem",
                                            fontWeight: "400",
                                            letterSpacing: "0.63rem",
                                          },
                                        }}
                                        sx={{
                                          paddingBottom: "0rem",
                                          width: "14.4rem",

                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              borderColor: "#8C8C88",
                                              borderRadius: "54px",
                                              borderWidth: "2.7px",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: "#8C8C88",
                                              borderRadius: "54px",
                                              borderWidth: "2.7px",
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: "black",
                                              borderRadius: "54px",
                                              borderWidth: "2.7px",
                                            },
                                          },
                                        }}
                                        fullWidth
                                      />
                                    )}
                                  </InputMask>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "0.81rem",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    fullwidth
                                    sx={{
                                      fontWeight: "400",
                                      fontSize: "0.9rem",
                                      color: "black",
                                      letterSpacing: "0.072rem",
                                      maxWidth: "19vh",
                                    }}
                                  >
                                    {translations[language].enterCode}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "2.7rem",
                                  }}
                                >
                                  <Button
                                    type="button"
                                    variant="contained"
                                    disabled={isPending}
                                    onClick={handleVerifyCode}
                                    sx={{
                                      backgroundColor: "lightgrey",
                                      borderRadius: "36px",
                                      justifyContent: "center",
                                      color: "#202426",
                                      fontWeight: "800",
                                      fontSize: "0.9rem",
                                      letterSpacing: "0.2rem",
                                      padding: "0.81rem 1rem 0.81rem 1rem",
                                      borderTop: "2.7px solid transparent",
                                      borderRight: "2.7px solid transparent",
                                      borderBottom: "2.7px solid transparent",
                                      borderLeft: "2.7px solid transparent",

                                      "&:hover": {
                                        backgroundColor: "lightgrey",
                                        color: "#202426",
                                        borderTop: "2.7px solid #8C8C88",
                                        borderRight: "2.7px solid #8C8C88",
                                        borderBottom: "2.7px solid #8C8C88",
                                        borderLeft: "2.7px solid #8C8C88",
                                      },
                                      minWidth: "9rem",
                                    }}
                                  >
                                    {translations[language].verifyCode}
                                  </Button>
                                </Box>
                              </Grid>
                              {error && (
                                <Grid item xs={12}>
                                  <Typography color="error">{error}</Typography>
                                </Grid>
                              )}
                            </Grid>
                          </form>
                        )}
                        <div id="recaptcha-container"></div>
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "1rem",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            sx={{
                              ...commonSx,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "36px",
                              },
                              ".MuiInputBase-input": {
                                fontSize: "0.9rem",
                              },
                              ".MuiInputLabel-root": {
                                fontSize: "0.9rem",
                                fontWeight: 200,
                                color: "#020202",
                              },
                            }}
                            label="Email Address"
                            type="text"
                            value={userInput}
                            autoComplete="username"
                            onChange={(e) => {
                              const input = e.target.value;
                              setUserInput(input);
                              setEmail(input.toLowerCase());
                            }}
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            sx={{
                              ...commonSx,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "36px",
                              },
                              ".MuiInputBase-input": {
                                fontSize: "0.9rem",
                              },
                              ".MuiInputLabel-root": {
                                fontSize: "0.9rem",
                                fontWeight: 200,
                                color: "#020202",
                              },
                            }}
                            label="Password"
                            type="password"
                            value={password}
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Button
                            component={Link}
                            to="/signup"
                            fullWidth
                            sx={{
                              backgroundColor: "lightgrey",
                              borderRadius: "36px",
                              justifyContent: "center",
                              color: "#202426",
                              fontWeight: "800",
                              fontSize: "0.9rem",
                              letterSpacing: "0.2rem",
                              padding: "0.81rem 1rem 0.81rem 1rem",
                              borderTop: "2.7px solid transparent",
                              borderRight: "2.7px solid transparent",
                              borderBottom: "2.7px solid transparent",
                              borderLeft: "2.7px solid transparent",

                              "&:hover": {
                                backgroundColor: "lightgrey",
                                color: "#202426",
                                borderTop: "2.7px solid #8C8C88",
                                borderRight: "2.7px solid #8C8C88",
                                borderBottom: "2.7px solid #8C8C88",
                                borderLeft: "2.7px solid #8C8C88",
                              },
                            }}
                          >
                            register
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Button
                            type="submit"
                            disabled={isPending}
                            fullWidth
                            sx={{
                              backgroundColor: "#202426",
                              borderRadius: "36px",
                              justifyContent: "center",
                              color: "#f2f2f2",
                              fontWeight: "800",
                              fontSize: "0.9rem",
                              letterSpacing: "0.2rem",
                              padding: "0.81rem 1rem 0.81rem 1rem",
                              borderTop: "2.7px solid transparent",
                              borderRight: "2.7px solid transparent",
                              borderBottom: "2.7px solid transparent",
                              borderLeft: "2.7px solid transparent",

                              "&:hover": {
                                backgroundColor: "#202426",
                                color: "#f2f2f2",
                                borderTop: "2.7px solid black",
                                borderRight: "2.7px solid black",
                                borderBottom: "2.7px solid black",
                                borderLeft: "2.7px solid black",
                              },
                            }}
                          >
                            login
                          </Button>
                        </Grid>
                        {error && (
                          <Grid item xs={12}>
                            <Typography
                              color="error"
                              sx={{
                                color: "#010101",
                                fontWeight: "200",
                                fontSize: "1.17rem",
                                paddingTop: "1rem",
                                justifyContent: "center",
                              }}
                            >
                              {error === "Firebase: Error (auth/invalid-email)."
                                ? "INVALID EMAIL ADDRESS"
                                : ""}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={2}></Grid>
                        {error && (
                          <Grid item xs={8}>
                            <Button
                              variant="contained"
                              onClick={sendResetEmail}
                              fullWidth
                              sx={{
                                backgroundColor: "transparent",
                                borderRadius: "36px",
                                color: "#010101",
                                fontWeight: "800",
                                fontSize: "0.9rem",
                                letterSpacing: "0.117rem",
                                padding: "0.81rem 1rem 0.81rem 1rem",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  color: "#101010",
                                },
                                borderTop: "6px solid #ffffff",
                                borderRight: "6px solid #ffffff",
                                borderBottom: "6px solid #ffffff",
                                borderLeft: "6px solid #ffffff",
                              }}
                            >
                              Reset Password
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SubmissionDialog
        open={submissionDialogOpen}
        onClose={() => setSubmissionDialogOpen(false)}
        isSubmitting={isSubmitting}
        message={submissionMessage}
      />
    </Grid>
  );
}

const commonSx = {
  marginBottom: "0.2rem",
  transition: "0.3s",
  maxWidth: "99rem",
  ".MuiInputLabel-root": {
    fontSize: "0.9rem",
    fontWeight: 200,
    color: "#020202",
  },
  ".MuiInputBase-input": {
    fontSize: "0.9rem",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "27px",
  },
};
