import React, { useEffect, useState, useCallback } from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/working-together.json";
import { db } from "../../firebase/firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from "../../contexts/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Typography,
  Box,
  IconButton,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip, TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { VscFileBinary } from "react-icons/vsc";
import { BsPersonUp, BsPersonX, BsPersonAdd, BsRobot } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import Slide from "@mui/material/Slide";
import { TfiClose } from "react-icons/tfi";
import { IoDocumentsOutline, IoRefreshOutline } from "react-icons/io5";

export default function Home() {
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [canViewPaySelection, setCanViewPaySelection] = useState(false);
  const [canViewNotifications, setCanViewNotifications] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastClearedDoc, setLastClearedDoc] = useState(null);
  const functions = getFunctions();
  // const fetchEmployeeData = httpsCallable(functions, "fetchEmployeeData");
  const [userClaims, setUserClaims] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentAction, setCurrentAction] = useState("");
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => {
    setRefresh(prev => !prev); // Toggle refresh state to trigger useEffect
  };

  const fetchUserClaims = useCallback(async (email) => {
    const getUserClaims = httpsCallable(functions, "getUserClaims");
    try {
      const result = await getUserClaims({ email });
      console.log("Fetched claims:", result.data);
      setUserClaims(result.data);
    } catch (error) {
      console.error("Error fetching user claims:", error);
    }
  }, []);

  useEffect(() => {
    if (user) {
      console.log("User photoURL:", user.photoURL);
      fetchUserClaims(user.email);
      setCanViewNotifications(
          user.photoURL.includes("Regional") ||
          user.photoURL.includes("Manager") ||
          user.photoURL.includes("Global") ||
          user.photoURL.includes("AGM") ||
          user.photoURL.includes("Accounting") ||
          user.photoURL.includes("OtherAdmin")
      );
      setCanViewPaySelection(
          user.photoURL.includes("Accounting") || user.photoURL.includes("Global")
      );
    }
  }, [user, fetchUserClaims]);

  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(propertiesRef, where("AdminEmail", "array-contains", email));

      const codes = [];
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => {
        codes.push(doc.data().propCode);
      });

      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        const propCode = doc.data().propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
        }
      });

      console.log("Fetched Property Codes:", codes);
      setPropertyCodes(codes);
    };

    fetchPropertyCodes();
  }, [user]);

  const handleIconClick = async (notificationDocId, status) => {
    console.log("handleIconClick called with notificationDocId:", notificationDocId, "status:", status);

    try {
      // Get the notification document to retrieve the EmpID
      const notificationRef = doc(db, "notifications", notificationDocId);
      const notificationDoc = await getDoc(notificationRef);

      if (notificationDoc.exists()) {
        const notificationData = notificationDoc.data();
        const empId = notificationData.EmpID; // Get EmpID from the notification document

        // Now get the corresponding document from empRegister using EmpID
        const entryRef = doc(db, "empRegister", empId);
        const entryDoc = await getDoc(entryRef);

        if (entryDoc.exists()) {
          const entryData = entryDoc.data();
          const currentStatus = status === "TERMINATED" ? entryData.terminated : entryData.Status;
          const currentDate = new Date().toLocaleDateString("en-US"); // Get current date in mm/dd/yyyy format
          const updatedNotes = [
            ...(entryData.Notes || []),
            `${currentDate} - ${currentStatus} employee acknowledged by ${user.displayName}`,
          ];

          // Update empRegister collection
          console.log("Updating empRegister for empId:", empId); // Log before updating empRegister
          await updateDoc(entryRef, {
            [status === "TERMINATED" ? "terminated" : "Status"]: "APPROVED", // Update Status or terminated
            Notes: updatedNotes,
          });
          console.log("empRegister updated successfully for empId:", empId); // Log successful update

          // Now update the notifications collection
          console.log("Updating notifications for notificationDocId:", notificationDocId); // Log before updating notifications
          // Update the 'Status' field to an empty string and other fields as blank
          await updateDoc(notificationRef, {
            Status: "", // Set Status field to a blank string
            paySelection: "",
            Banking: "",
            ID: "",
            SSN: "",
            ["W4-IRS"]: "",
            ["W4-LOCAL"]: "",
          });
          console.log("notifications updated successfully for notificationDocId:", notificationDocId); // Log successful update

          // Show snackbar notification
          setSnackbarOpen(true);

          // Update data state to reflect the change in the table
          setData((prevData) =>
              prevData.map((item) =>
                  item.id === notificationDocId
                      ? { ...item, Status: "APPROVED", Notes: updatedNotes }
                      : item
              )
          );

          // Optionally, remove the entry from the table if needed
          setData((prevData) => prevData.filter((item) => item.id !== notificationDocId));
        } else {
          console.log("No document found in empRegister with EmpID:", empId); // Log if document doesn't exist in empRegister
        }
      } else {
        console.log("No document found in notifications with docId:", notificationDocId); // Log if document doesn't exist in notifications
      }
    } catch (error) {
      console.error("Error updating document:", error); // Log error
    }
  };



// Open the confirmation dialog with docId and action
  const openDialog = (docId, status) => {
    console.log("openDialog called with docId:", docId, "status:", status); // Add logging to debug
    setCurrentItem(docId); // Ensure docId is stored
    setCurrentAction(status); // Ensure status is stored
    setDialogOpen(true);
  };

// Handle the confirmation from the dialog
  const handleConfirm = async () => {
    console.log("handleConfirm triggered. CurrentItem:", currentItem, "CurrentAction:", currentAction); // Add logging for debugging
    if (currentItem && currentAction) {
      await handleIconClick(currentItem, currentAction); // Pass stored docId and status to handleIconClick
    } else {
      console.log("handleConfirm: Missing docId or status");
    }
    setDialogOpen(false); // Close the dialog after confirmation
  };

  const handleClearEntry = async (docId) => {
    const notificationRef = doc(db, "notifications", docId);
    await updateDoc(notificationRef, {
      clearedBy: arrayUnion({
        userId: user.uid,
        date: new Date().toISOString(), // Store the current date and time
      }),
    });

    // Set the last cleared document to enable undo
    setLastClearedDoc(docId);

    // Show snackbar
    setSnackbarOpen(true);

    // Update data state to reflect the change
    setData((prevData) => prevData.filter((item) => item.id !== docId));
  };

  const handleUndoClear = async () => {
    if (lastClearedDoc) {
      const notificationRef = doc(db, "notifications", lastClearedDoc);
      const docSnapshot = await getDoc(notificationRef);
      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        const newClearedBy = docData.clearedBy.filter(
            (entry) => entry.userId !== user.uid
        );
        await updateDoc(notificationRef, {
          clearedBy: newClearedBy,
        });
        setLastClearedDoc(null);
        setSnackbarOpen(false);
      }
    }
  };

  useEffect(() => {
    if (propertyCodes.length === 0) {
      setLoading(false);
      return;
    }

    // Track unique document IDs to avoid duplicates
    const uniqueDocIds = new Set();
    const newDataMap = new Map(); // Track unique documents by ID
    const notificationsRef = collection(db, 'notifications');
    const chunkSize = 10;
    const propertyCodeChunks = [];

    // Split propertyCodes into chunks for Firestore query
    for (let i = 0; i < propertyCodes.length; i += chunkSize) {
      propertyCodeChunks.push(propertyCodes.slice(i, i + chunkSize));
    }

    const unsubscribeArr = [];

    // Iterate through chunks and listen to updates using onSnapshot
    propertyCodeChunks.forEach((chunk) => {
      const q = query(notificationsRef, where('PropID', 'in', chunk));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docs.forEach((doc) => {
          if (!uniqueDocIds.has(doc.id)) {
            uniqueDocIds.add(doc.id); // Track unique document ID
            const data = doc.data();
            const submittedFields = new Set();
            const clearedBy = data.clearedBy || [];
            const dateUpdated = data.DateUpdated ? data.DateUpdated.toDate() : null;
            const currentDate = new Date();

            // Skip cleared entries or those older than 14 days
            const userCleared = clearedBy.find((entry) =>
                entry.userId === user.uid && new Date(entry.date) >= dateUpdated
            );
            if (userCleared || !dateUpdated || (dateUpdated && currentDate - dateUpdated > 14 * 24 * 60 * 60 * 1000)) {
              return;
            }

          // Add field tracking logic (BANKING, ID, SSN, etc.)
          if (data["Status"] === "NEW")
            submittedFields.add("New Hire");
          if (data["Status"] === "REHIRED")
            submittedFields.add("Employee Rehired");
          if (data["Status"] === "Terminated")
            submittedFields.add("Employee Terminated");
          if (data.BANKING === "SUBMITTED") submittedFields.add("New Banking Doc");
          if (data.ID === "SUBMITTED") submittedFields.add("New ID Doc");
          if (data.SSN === "SUBMITTED") submittedFields.add("New SSN Doc");
          if (data["W4-IRS"] === "SUBMITTED") submittedFields.add("New W4_IRS Doc");
          if (data["W4-LOCAL"] === "SUBMITTED") submittedFields.add("New W4_LOC Doc");
          if (data.paySelection === "SUBMITTED")
            submittedFields.add("New Pay Doc");
          if (data.BANKING === "REJECTED")
            submittedFields.add("Rejected Banking Doc");
          if (data.ID === "REJECTED")
            submittedFields.add("Rejected ID Doc");
          if (data.SSN === "REJECTED")
            submittedFields.add("Rejected SSN Doc");
          if (data["W4-IRS"] === "REJECTED")
            submittedFields.add("Rejected W4_IRS Doc");
          if (data["W4-LOCAL"] === "REJECTED")
            submittedFields.add("Rejected W4_LOC Doc");
          if (data.paySelection === "REJECTED")
            submittedFields.add("Rejected Pay Doc");
          if (data.paySelection === "RESUBMITTED")
            submittedFields.add("Resubmitted Pay Doc");

          const hasSubmittedOrRejectedFields = [
            "Banking",
            "ID",
            "SSN",
            "W4-IRS",
            "W4-LOCAL",
            "paySelection"
          ].some((field) =>
              ["SUBMITTED", "REJECTED", "RESUBMITTED"].includes(data[field])
          );


          // Admin and action button logic
          const isAdmin = userClaims[`${data.PropID} Employee Admin`] === true;
          const ackEmployee = userClaims[`${data.PropID} Ack Emp Status`] === true;

            const entry = {
              id: doc.id,
              DateUpdated: dateUpdated ? dateUpdated.toLocaleDateString() : "N/A",
              EmpID: data.EmpID,
              NameFirst: data.NameFirst,
              NameLast: data.NameLast,
              PropID: data.PropID,
              SubmittedFields: Array.from(submittedFields),
              ActionButtons: (
                  <Box
                      display="flex"
                      justifyContent="left"
                      alignItems="center"
                      width="100%"
                      sx={{ borderRadius: "36px", overflow: "hidden" }}
                      bgcolor="#f2f2f2"
                  >
                    {data.Status === "NEW" && (canViewPaySelection || ackEmployee) && (
                        <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              maxWidth: "4.5rem",
                              textAlign: "center",
                            }}
                        >
                          <Tooltip title="ack new hire">
                            <IconButton
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0",
                                  color: "#202426",
                                  fontSize: "1.35rem",
                                }}
                                onClick={() => openDialog(doc.id, "NEW")} // Use doc.id instead of data.id
                            >
                              <BsPersonAdd />
                            </IconButton>
                          </Tooltip>
                        </Box>
                    )}
                    {data.Status === "REHIRED" && (canViewPaySelection || ackEmployee) && (
                        <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              maxWidth: "4.5rem",
                              textAlign: "center",
                            }}
                        >
                          <Tooltip title="ack re-hire">
                            <IconButton
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0",
                                  color: "#202426",
                                  fontSize: "1.35rem",
                                }}
                                onClick={() => openDialog(doc.id, "REHIRED")} // Use doc.id
                            >
                              <BsPersonUp />
                            </IconButton>
                          </Tooltip>
                        </Box>
                    )}
                    {data.Status === "Terminated" && (canViewPaySelection || ackEmployee) && (
                        <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              maxWidth: "4.5rem",
                              textAlign: "center",
                            }}
                        >
                          <Tooltip title="ack termination">
                            <IconButton
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0",
                                  color: "#202426",
                                  fontSize: "1.35rem",
                                }}
                                onClick={() => openDialog(doc.id, "TERMINATED")} // Use doc.id
                            >
                              <BsPersonX />
                            </IconButton>
                          </Tooltip>
                        </Box>
                    )}
                  {canViewPaySelection &&
                      (data.paySelection === "SUBMITTED" ||
                          data.paySelection === "REJECTED" ||
                          data.paySelection === "RESUBMITTED") && (
                          <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: "4.5rem",
                                textAlign: "center",
                              }}
                          >
                            <a
                                href={data.paySelectionApproval}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                              <Tooltip title="pay selection">
                                <IconButton
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "0",
                                      color:
                                          data.paySelection === "SUBMITTED"
                                              ? "#202426"
                                              : data.paySelection === "REJECTED"
                                                  ? "#F98B85"
                                                  : data.paySelection === "RESUBMITTED"
                                                      ? "#FFA500"
                                                      : "#202426",
                                      fontSize: "1.5rem",
                                    }}
                                >
                                  <VscFileBinary />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </Box>
                      )}
                  {isAdmin && hasSubmittedOrRejectedFields ? (
                      <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: "4.5rem",
                            textAlign: "center",
                          }}
                      >
                        <Tooltip title="review uploads">
                          <IconButton
                              style={{
                                backgroundColor: "transparent",
                                border: "0",
                                color: "#202426",
                                fontSize: "1.5rem",
                              }}
                              onClick={() =>
                                  navigate("/update-Record", {
                                    state: {
                                      propertyCode: data.PropID,
                                      selectedEmployee: data.EmpID,
                                      formType: "Employee",
                                    },
                                  })
                              }
                          >
                            <IoDocumentsOutline />
                          </IconButton>
                        </Tooltip>
                      </Box>
                  ) : (
                      <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: "4.5rem",
                            textAlign: "center",
                          }}
                      >
                        <Tooltip title="employee file">
                          <IconButton
                              style={{
                                backgroundColor: "transparent",
                                border: "0",
                                color: "#202426",
                                fontSize: "1.35rem",
                              }}
                              onClick={() =>
                                  navigate("/update-Record", {
                                    state: {
                                      propertyCode: data.PropID,
                                      selectedEmployee: data.EmpID,
                                      formType: "Employee",
                                    },
                                  })
                              }
                          >
                            <BsRobot />
                          </IconButton>
                        </Tooltip>
                      </Box>
                  )}
                </Box>
            ),
          };

            newDataMap.set(doc.id, entry);
          }
        });

        // Update the state with the unique entries from the Map
        setData(Array.from(newDataMap.values())); // Convert Map to array
        setLoading(false);
      });

      unsubscribeArr.push(unsubscribe);
    });

    // Cleanup function to unsubscribe from Firestore listeners
    return () => {
      unsubscribeArr.forEach((unsubscribe) => unsubscribe());
    };
  }, [propertyCodes, refresh]);

  useEffect(() => {
    console.log("Can View Notifications:", canViewNotifications); // Check if this is correctly set
    console.log("Fetched Data:", data); // Check if data is populated
  }, [canViewNotifications, data]);


  if (loading) {
    return (
        <div>
          <Lottie
              animationData={animationData}
              style={{ width: "100%", height: "100%" }}
          />
        </div>
    );
  }

  return (
      <div>
        {canViewNotifications ? (
            data.length > 0 ? (
                <Card
                    sx={{
                      borderRadius: "18px",
                      padding: "1rem",
                      backgroundColor: "#fcfcfc",
                    }}
                >
                  <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
                    <Table sx={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={maintitle200header}>
                            <Tooltip title="Refresh Data">
                              <IconButton onClick={handleRefresh} style={{
                                backgroundColor: "transparent",
                                border: "0",
                                color: "#202426",
                                fontSize: "1.53rem",
                              }}>
                                <IoRefreshOutline />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left" sx={maintitle200header} colSpan={4}>
                            <Typography
                                sx={{
                                  ...sharedTextStyles,
                                  letterSpacing: "0.45rem",
                                  fontWeight: "300",
                                  fontSize: "1.71rem",
                                  color: "#202426",
                                }}
                                variant="body1"
                                align="left"
                            >
                              PAYROLL
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={maintitle200header} colSpan={2}>
                            <Typography
                                sx={{
                                  ...sharedTextStyles,
                                  letterSpacing: "0.36rem",
                                  fontWeight: "300",
                                  fontSize: "1.18rem",
                                  color: "lightgrey",
                                }}
                                variant="body1"
                                align="right"
                            >
                              ACTIONS
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={maintitle200header}>
                            <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "absolute",
                                  top: 1.8,
                                  right: 1.8,

                                  padding: "0.9rem 1.8rem",

                                }}
                            >
                            <Typography sx={badge}>{data.length}</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                {item.DateUpdated}
                              </TableCell>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                {item.EmpID}
                              </TableCell>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                {item.NameFirst}
                              </TableCell>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                {item.NameLast}
                              </TableCell>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                {item.PropID}
                              </TableCell>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                {item.SubmittedFields.map((field, index) => (
                                    <div key={index}>{field}</div>
                                ))}
                              </TableCell>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                {item.ActionButtons}
                              </TableCell>
                              <TableCell align="left" size="small" sx={maintableBold}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                  <Tooltip title={"Clear Notification"}>
                                  <IconButton
                                      style={{ backgroundColor: 'transparent', border: '0', color: '#93A683', fontSize: '1.5rem' }}
                                      onClick={() => handleClearEntry(item.id)}
                                  >
                                    <MdClear />
                                  </IconButton>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Dialog
                      open={dialogOpen}
                      onClose={() => setDialogOpen(false)}
                      PaperProps={{
                        sx: {
                          padding: "1rem",
                          borderRadius: "36px",
                          backgroundColor: "#f5f5f5",
                          position: "relative",
                        },
                      }}
                  >
                    <IconButton
                        onClick={() => setDialogOpen(false)}
                        sx={{
                          position: "absolute",
                          top: 9,
                          right: 9,
                          color: "#101010",
                        }}
                    >
                      <TfiClose />
                    </IconButton>
                    <DialogTitle
                        sx={{
                          letterSpacing: "0.2rem",
                          fontWeight: "400",
                          fontSize: "0.9rem",
                          color: "#101010",
                        }}
                        id="alert-dialog-title"
                    >
                      {"EMPLOYEE CHANGE"}
                    </DialogTitle>
                    <DialogContent
                        sx={{
                          letterSpacing: "0.1rem",
                          fontWeight: "400",
                          fontSize: "0.9rem",
                          color: "#000000",
                        }}
                    >
                      <Typography
                          variant="body2"
                          sx={{
                            marginBottom: "1rem",
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            color: "#101010",
                          }}
                      >
                        Acknowledge employee change?
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={handleConfirm}
                          color="primary"
                          autoFocus
                          fullWidth
                          sx={{
                            backgroundColor: "#c7d57f",
                            borderRadius: "9px",
                            padding: "9.9px 18px 9.9px 18px",
                            color: "#010101",
                            letterSpacing: "0.1rem",
                            fontWeight: "800",
                            fontSize: "0.72rem",
                            border: "2px solid #f9f9f9",
                          }}
                      >
                        ACKNOWLEDGE
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Snackbar
                      sx={{
                        width: 900,
                        color: "secondary",
                        "& .MuiSnackbarContent-root": {
                          backgroundColor: "#93A683",
                          fontSize: "0.9rem",
                          fontWeight: "800",
                        },
                        "& .MuiButton-root": {
                          color: "#202426",
                          fontSize: "0.9rem",
                          fontWeight: "800",
                        },
                      }}
                      open={snackbarOpen}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      autoHideDuration={3699}
                      TransitionComponent={Slide}
                      TransitionProps={{ enter: true, exit: false }}
                      onClose={() => setSnackbarOpen(false)}
                      message={
                        <span
                            style={{
                              fontSize: "0.9rem",
                              fontWeight: "400",
                              letterSpacing: "0.09rem",
                            }}
                        >
                  ACKNOWLEDGED
                </span>
                      }
                      action={
                        <Button color="inherit" size="large" onClick={handleUndoClear}>
                          UNDO
                        </Button>
                      }
                  />
                </Card>
            ) : (
                <Lottie
                    animationData={animationData}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        ) : (
            <Lottie
                animationData={animationData}
                style={{ width: "100%", height: "100%" }}
            />
        )}
      </div>
  );
}

const badge = {
  fontFamily: "'Outfit', sans-serif",
  letterSpacing: "0.18rem",
  fontWeight: 400,
  fontSize: "0.9rem",
  backgroundColor: "#93A683",
  color: "#FFFDD0",
  width: "3.6rem",
  height: "3.6rem",
  borderRadius: "72%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "0.18rem",
};
const maintableBold = {
  fontWeight: 400,
  fontSize: "0.9rem",
  textTransform: "lowercase",
  backgroundColor: "#fcfcfc",
  color: "#202426",
  rowHeight: "0.1rem",
  letterSpacing: "0.018rem",
};
const maintitle200header = {
  fontFamily: "'Nunito', sans-serif",
  fontWeight: 600,
  fontSize: "0.9rem",
  backgroundColor: "#f5f5f5",
  color: "lightgrey",
  letterSpacing: "0.1rem",
};

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};



