import React, { useState, useEffect } from "react";
import "../../components/App.css";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase/firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { RxReset } from "react-icons/rx";

export default function Sales() {
  const { user } = useAuth();
  const [formType, setFormType] = useState("");
  const [propertyCode, setPropertyCode] = useState("");
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [propertyData, setPropertyData] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [requestType, setRequestType] = useState("");
  const [contactMethod, setContactMethod] = useState("");
  const [contactName, setContactName] = useState("");
  const [groupCompanySchool, setGroupCompanySchool] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [natureOfBusiness, setNatureOfBusiness] = useState("");
  const [bookingMethod, setBookingMethod] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [standardSingle, setStandardSingle] = useState("");
  const [standardDouble, setStandardDouble] = useState("");
  const [suiteSingle, setSuiteSingle] = useState("");
  const [suiteDouble, setSuiteDouble] = useState("");
  const [mtgSpace, setMtgSpace] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(
        propertiesRef,
        where("AdminEmail", "array-contains", email)
      );

      const codes = [];
      const properties = [];

      // Fetch based on roleEmail
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => {
        const property = doc.data();
        codes.push(property.propCode);
        properties.push({
          propCode: property.propCode,
          propertyName: property.propertyName,
          city: property.city,
          passcode: property.passcode,
        });
      });

      // Fetch based on AdminEmail
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        const property = doc.data();
        const propCode = property.propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
          properties.push({
            propCode: property.propCode,
            propertyName: property.propertyName,
            city: property.city,
            passcode: property.passcode,
          });
        }
      });

      setPropertyCodes(codes);
      setPropertyData(properties);
      if (!selectedPropertyCode && codes.length > 0) {
        setSelectedPropertyCode(codes[0]);
        setPropertyCode(codes[0]);
      }
    };

    fetchPropertyCodes();
  }, [user, selectedPropertyCode]);

  const handleReset = () => {
    window.location.reload();
  };

  const handlePropertyCodeChange = (event) => {
    setPropertyCode(event.target.value);
  };

  // Function to handle the change of Employee Name
  const handleEmployeeNameChange = (event) => {
    setEmployeeName(event.target.value);
  };

  // Function to handle the change of Request Type
  const handleRequestTypeChange = (event) => {
    setRequestType(event.target.value);
  };

  // Function to handle the change of Contact Method
  const handleContactMethodChange = (event) => {
    setContactMethod(event.target.value);
  };

  return (
    <Grid container direction="column" alignItems="left" spacing={2}>
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Typography
              sx={{
                marginRight: 9,
                letterSpacing: "0.5rem",
                fontWeight: "200",
                fontSize: "1.2rem",
                color: "#101010",
              }}
              variant="body1"
              align="left"
            >
              SALES
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": {
                  minWidth: "9rem",
                },
              }}
            >
              <InputLabel
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 600,
                  color: "#000",
                  marginBottom: "0.2rem",
                  transition: "0.3s",
                }}
                htmlFor="form-type"
              >
                SALES
              </InputLabel>
              <Select
                value={formType}
                onChange={(event) => setFormType(event.target.value)}
                inputProps={{ name: "form-type", id: "form-type" }}
                sx={{
                  fontSize: "0.9rem",
                  backgroundColor: "#fcfcfc",
                  minWidth: "10em",
                  borderRadius: "27px",
                }}
              >
                <MenuItem sx={{ fontSize: "0.9rem" }} value="NewSalesLead">
                  {" "}
                  {/* Updated value */}
                  NEW SALES LEAD
                </MenuItem>
                {user.photoURL !== "Manager" && (
                  <MenuItem sx={{ fontSize: "0.9rem" }} value="SalesLeadAdmin">
                    SALES LEAD ADMIN
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl
              sx={{
                minWidth: "15rem",
                "@media (max-width: 600px)": {
                  minWidth: "9rem",
                },
              }}
            >
              <InputLabel
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 600,
                  color: "#000",
                  marginBottom: "0.2rem",
                  transition: "0.3s",
                }}
                htmlFor="property-code"
              >
                PROPERTY CODE
              </InputLabel>
              <Select
                value={propertyCode}
                onChange={
                  handlePropertyCodeChange
                } /* Already exists, ensures the state is updated on change */
                inputProps={{ name: "property-code", id: "property-code" }}
                sx={{
                  fontSize: "0.9rem",
                  backgroundColor: "#fcfcfc",
                  minWidth: "10em",
                  borderRadius: "27px",
                }}
              >
                <MenuItem sx={{ fontSize: "0.9rem" }} value="" disabled>
                  Property Code
                </MenuItem>
                {propertyCodes.map((code) => (
                  <MenuItem sx={{ fontSize: "0.9rem" }} key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Tooltip title="Reset">
              <IconButton
                sx={{
                  fontSize: "1.9rem",
                  color: "#000000",
                }}
                onClick={handleReset}
                label="Reset"
              >
                <RxReset />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {/* NEW SALES REQUEST FORM */}
      {formType === "NewSalesLead" && propertyCode && (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            {/* INTRO CARD */}
            <Card
              sx={{
                borderRadius: "18px",
                marginTop: "3rem",
              }}
            >
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Typography
                  sx={{
                    letterSpacing: "0.1rem",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                    color: "#000000",
                    marginBottom: "9px",
                  }}
                  variant="body1"
                  align="left"
                >
                  INTRO
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  backgroundColor="#fcfcfc"
                  sx={{ borderBottom: "0.5px solid black" }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Grid item>
                  <FormControl component="fieldset">
                    <TextField
                      label="Employee Name"
                      value={employeeName}
                      onChange={handleEmployeeNameChange}
                    />
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="request-type-label">
                        Request Type
                      </InputLabel>
                      <Select
                        labelId="request-type-label"
                        id="request-type"
                        value={requestType}
                        label="Request Type"
                        onChange={handleRequestTypeChange}
                      >
                        <MenuItem value={"Future Group"}>Future Group</MenuItem>
                        <MenuItem value={"Negotiated Rate"}>
                          Negotiated Rate
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="contact-method"
                        name="contact-method"
                        value={contactMethod}
                        onChange={handleContactMethodChange}
                      >
                        <FormControlLabel
                          value="Phone"
                          control={<Radio />}
                          label="Phone"
                        />
                        <FormControlLabel
                          value="In-Person"
                          control={<Radio />}
                          label="In-Person"
                        />
                        <FormControlLabel
                          value="Regular Guest"
                          control={<Radio />}
                          label="Regular Guest"
                        />
                        <FormControlLabel
                          value="In-House Guest"
                          control={<Radio />}
                          label="In-House Guest"
                        />
                      </RadioGroup>
                    </FormControl>
                  </FormControl>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item>
            {/* CONTACT CARD */}
            <Card
              sx={{
                borderRadius: "18px",
              }}
            >
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Typography
                  sx={{
                    letterSpacing: "0.1rem",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                    color: "#000000",
                    marginBottom: "9px",
                  }}
                  variant="body1"
                  align="left"
                >
                  CONTACT
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  backgroundColor="#fcfcfc"
                  sx={{ borderBottom: "0.5px solid black" }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Grid item>
                  <FormControl component="fieldset">
                    <TextField
                      label="Contact Name"
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                    />
                    <TextField
                      label="Group/Company/School"
                      value={groupCompanySchool}
                      onChange={(e) => setGroupCompanySchool(e.target.value)}
                    />
                    <TextField
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <TextField
                      label="Email Address"
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item>
            {/* GROUP CARD */}
            <Card
              sx={{
                borderRadius: "18px",
              }}
            >
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Typography
                  sx={{
                    letterSpacing: "0.1rem",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                    color: "#000000",
                    marginBottom: "9px",
                  }}
                  variant="body1"
                  align="left"
                >
                  GROUP DET'S
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  backgroundColor="#fcfcfc"
                  sx={{ borderBottom: "0.5px solid black" }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Grid item>
                  <FormControl component="fieldset">
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="nature-of-business"
                        name="nature-of-business"
                        value={natureOfBusiness}
                        onChange={(e) => setNatureOfBusiness(e.target.value)}
                      >
                        <FormControlLabel
                          value="SPORTS"
                          control={<Radio />}
                          label="SPORTS"
                        />
                        <FormControlLabel
                          value="WEDREUNION"
                          control={<Radio />}
                          label="WEDDING /​ REUNION"
                        />
                        <FormControlLabel
                          value="CREW"
                          control={<Radio />}
                          label="CREW"
                        />
                        <FormControlLabel
                          value="SCHOOL"
                          control={<Radio />}
                          label="SCHOOL"
                        />
                        <FormControlLabel
                          value="CONVENTION"
                          control={<Radio />}
                          label="CONVENTION"
                        />
                        <FormControlLabel
                          value="LONGTERM"
                          control={<Radio />}
                          label="LONG-TERM STAY"
                        />
                        <FormControlLabel
                          value="WEATHER"
                          control={<Radio />}
                          label="WEATHER"
                        />
                        <FormControlLabel
                          value="GOVERNMENT"
                          control={<Radio />}
                          label="GOVERNMENT"
                        />
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      label="Check In Date"
                      type="date"
                      value={checkInDate}
                      onChange={(e) => setCheckInDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="Check Out Date"
                      type="date"
                      value={checkOutDate}
                      onChange={(e) => setCheckOutDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="booking-method-label">
                        Booking Method
                      </InputLabel>
                      <Select
                        labelId="booking-method-label"
                        id="booking-method"
                        value={bookingMethod}
                        label="Booking Method"
                        onChange={(e) => setBookingMethod(e.target.value)}
                      >
                        <MenuItem value={"Will provide rooming list"}>
                          Will provide rooming list
                        </MenuItem>
                        <MenuItem value={"Individual Callers"}>
                          Individual Callers
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="payment-method-label">
                        Payment Method
                      </InputLabel>
                      <Select
                        labelId="payment-method-label"
                        id="payment-method"
                        value={paymentMethod}
                        label="Payment Method"
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
                        {/* ... add other options similarly ... */}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item>
            {/* ROOM CARD */}
            <Card
              sx={{
                borderRadius: "18px",
              }}
            >
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Typography
                  sx={{
                    letterSpacing: "0.1rem",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                    color: "#000000",
                    marginBottom: "9px",
                  }}
                  variant="body1"
                  align="left"
                >
                  ROOMS
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  backgroundColor="#fcfcfc"
                  sx={{ borderBottom: "0.5px solid black" }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  padding: "1rem",
                  backgroundColor: "#fcfcfc",
                }}
              >
                <Grid item>
                  <FormControl component="fieldset">
                    <TextField
                      label="STANDARD SINGLE"
                      type="number"
                      value={standardSingle}
                      onChange={(e) => setStandardSingle(e.target.value)}
                    />
                    <TextField
                      label="STANDARD DOUBLE"
                      type="number"
                      value={standardDouble}
                      onChange={(e) => setStandardDouble(e.target.value)}
                    />
                    <TextField
                      label="SUITE SINGLE"
                      type="number"
                      value={suiteSingle}
                      onChange={(e) => setSuiteSingle(e.target.value)}
                    />
                    <TextField
                      label="SUITE DOUBLE"
                      type="number"
                      value={suiteDouble}
                      onChange={(e) => setSuiteDouble(e.target.value)}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mtgSpace === "Yes"}
                          onChange={(e) =>
                            setMtgSpace(e.target.checked ? "Yes" : "No")
                          }
                          name="mtgSpace"
                        />
                      }
                      label="MTG SPACE"
                    />
                    <TextField
                      label="NOTES"
                      multiline
                      rows={4}
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
