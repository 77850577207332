import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/working-together.json";
import { db } from "../../firebase/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";

function convertTimestampsToStrings(object) {
  const newObj = {};
  for (const [key, value] of Object.entries(object)) {
    if (value && typeof value === "object" && "seconds" in value) {
      newObj[key] = new Date(value.seconds * 1000).toLocaleString();
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
}

function getMonthlyMetrics(punches) {
  const monthlyCounts = {};
  punches.forEach((punch) => {
    if (punch.InTime && "seconds" in punch.InTime) {
      const date = new Date(punch.InTime.seconds * 1000);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
      monthlyCounts[monthYear] = (monthlyCounts[monthYear] || 0) + 1;
    }
  });
  return monthlyCounts;
}

function sortMonthsNewestToOldest(months) {
  return months.sort((a, b) => {
    const [monthA, yearA] = a.split("-").map(Number);
    const [monthB, yearB] = b.split("-").map(Number);
    return yearB - yearA || monthB - monthA;
  });
}

export default function Stats() {
  const { user } = useAuth();
  const userEmail = user ? user.email : null;
  const [devices, setDevices] = useState([]);
  const [punchesCount, setPunchesCount] = useState(0);
  const [monthlyMetrics, setMonthlyMetrics] = useState({});
  const [averageDAU, setAverageDAU] = useState({});

  function getDailyActiveUsers(punches) {
    const dailyActiveUsers = {};
    punches.forEach((punch) => {
      if (punch.InTime && "seconds" in punch.InTime && punch.EmployeeId) {
        const date = new Date(punch.InTime.seconds * 1000);
        const dayKey = `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}`;

        if (!dailyActiveUsers[dayKey]) {
          dailyActiveUsers[dayKey] = new Set();
        }
        dailyActiveUsers[dayKey].add(punch.EmployeeId);
      }
    });

    return dailyActiveUsers;
  }

  function calculateAverageDAU(dailyActiveUsers, monthlyCounts) {
    const averageDAUPerMonth = {};
    Object.entries(monthlyCounts).forEach(([monthYear, count]) => {
      const [month, year] = monthYear.split("-").map(Number);
      const totalDays = Object.keys(dailyActiveUsers).filter((dayKey) => {
        const [day, dayMonth, dayYear] = dayKey.split("-").map(Number);
        return dayMonth === month && dayYear === parseInt(year);
      }).length;
      const totalUniqueUsers = Object.entries(dailyActiveUsers).reduce(
        (sum, [key, usersSet]) => {
          const [day, dayMonth, dayYear] = key.split("-").map(Number);
          if (dayMonth === month && dayYear === parseInt(year)) {
            return sum + usersSet.size;
          }
          return sum;
        },
        0
      );

      averageDAUPerMonth[monthYear] =
        totalDays > 0 ? totalUniqueUsers / totalDays : 0;
    });

    return averageDAUPerMonth;
  }

  useEffect(() => {
    const fetchData = async () => {
      // Fetching device data
      const deviceCollection = collection(db, "deviceID");
      const deviceSnapshot = await getDocs(deviceCollection);
      const deviceList = deviceSnapshot.docs.map((doc) =>
        convertTimestampsToStrings({ ...doc.data(), id: doc.id })
      );
      setDevices(deviceList);

      // Fetching punches data
      const punchesCollection = collection(db, "PUNCHES");
      const punchesSnapshot = await getDocs(punchesCollection);
      const punches = punchesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Compute monthly metrics
      const unsortedMonthlyMetrics = getMonthlyMetrics(punches);

      // Sort the months from newest to oldest
      const sortedMonths = sortMonthsNewestToOldest(
        Object.keys(unsortedMonthlyMetrics)
      );
      const sortedMetrics = {};
      sortedMonths.forEach((month) => {
        sortedMetrics[month] = unsortedMonthlyMetrics[month];
      });

      // Compute daily active users and average DAU
      const dailyActiveUsers = getDailyActiveUsers(punches);
      const averageDAU = calculateAverageDAU(
        dailyActiveUsers,
        unsortedMonthlyMetrics
      );

      setPunchesCount(punches.length);
      setMonthlyMetrics(sortedMetrics);
      setAverageDAU(averageDAU);
    };

    fetchData();
  }, []);

  if (userEmail === "amishzaver@staychampion.com") {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Heartbeat</th>
              <th></th>
              <th>Version</th>
              <th>ID</th>
              <th>Android Version</th>
              <th>Installation Time</th>
              <th>Device Type</th>
              <th>Battery Level</th>
              <th>Network State</th>
              <th>IP Address</th>
              <th>SSID</th>
            </tr>
          </thead>
          <tbody>
            {devices.map((device) => {
              let now = new Date();
              let heartbeatDate = new Date(device.heartbeat);
              let minutesDifference = (now - heartbeatDate) / (1000 * 60); // difference in minutes
              let isStaleHeartbeat = minutesDifference > 15;

              return (
                <tr key={device.id}>
                  <td
                    style={{
                      backgroundColor: isStaleHeartbeat ? "red" : "transparent",
                    }}
                  >
                    {device.heartbeat}
                  </td>
                  <td style={{ fontWeight: "bold" }}>{device.property}</td>
                  <td>{device.nativeApplicationVersion}</td>
                  <td>{device.androidId}</td>
                  <td>{device.androidVersion}</td>
                  <td>{device.installationTime}</td>
                  <td>{device.deviceType}</td>
                  <td>{`${Math.round(device.batteryLevel * 100)}%`}</td>
                  <td>{device.networkState}</td>
                  <td>{device.ipAddress}</td>
                  <td>{device.ssid}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <h2>Punches Data</h2>
        <p>Total Punches Count: {punchesCount}</p>
        <h3>Monthly Metrics</h3>
        <table>
          <thead>
            <tr>
              <th>Month-Year</th>
              <th>Count</th>
              <th>Average DAU</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(monthlyMetrics).map(([monthYear, count]) => (
              <tr key={monthYear}>
                <td>{monthYear}</td>
                <td>{count}</td>
                <td>{averageDAU[monthYear]?.toFixed(2) || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div>
        <Lottie
          animationData={animationData}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }
}
