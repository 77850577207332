import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  onSnapshot,
  orderBy,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FaFilePdf } from "react-icons/fa";

const PunchTimecards = () => {
  const { user } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [selectedPayRoll, setSelectedPayRoll] = useState(null);
  const [missedPunches, setMissedPunches] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [payCycle, setPayCycle] = useState("");
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [value, setValue] = React.useState(0);
  const [averageRooms, setAverageRooms] = useState({});
  const [averageTimes, setAverageTimes] = useState({});
  const [payrollChanges, setPayrollChanges] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const departmentNames = [
    "Select All",
    "BARTENDER",
    "BREAKFAST",
    "CONVENTION",
    "F&B",
    "FRONT DESK",
    "HOUSEKEEPING",
    "LAUNDRY",
    "MANAGEMENT",
    "MAINTENANCE",
    "PUBLIC AREAS",
    "SALES",
    "SHUTTLE",
    "NO-SHOW",
    "IN-HOUSE",
    "FIX",
    "VERIFY",
  ];

  const [selectedDepartments, setSelectedDepartments] = useState(
    departmentNames.reduce((acc, dept) => {
      acc[dept] = true;
      return acc;
    }, {})
  );

  const handleDepartmentChange = (event, value) => {
    const newSelections = value.reduce((acc, dept) => {
      if (dept === "HOUSEKEEPING") {
        acc["HOUSEKEEPING"] = true;
        acc["HOUSEKEEPING /HR"] = true;
      } else {
        acc[dept] = true;
      }
      return acc;
    }, {});

    if (value.includes("Select All")) {
      const allSelected = !selectedDepartments["Select All"];
      setSelectedDepartments(
        departmentNames.reduce((acc, dept) => {
          acc[dept] = allSelected;
          return acc;
        }, {})
      );
    } else {
      setSelectedDepartments(
        departmentNames.reduce((acc, dept) => {
          acc[dept] = !!newSelections[dept];
          return acc;
        }, {})
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (
      payRollData.length > 0 &&
      currentPayRollIndex >= 0 &&
      currentPayRollIndex < payRollData.length
    ) {
      setSelectedPayRoll(payRollData[currentPayRollIndex]);
    }
  }, [currentPayRollIndex, payRollData]);

  // USE EFFECT TO FETCH PROPERTY CODE
  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(
        propertiesRef,
        where("AdminEmail", "array-contains", email)
      );

      // Fetch based on roleEmail
      const querySnapshot1 = await getDocs(q1);
      const codes = [];
      querySnapshot1.forEach((doc) => {
        codes.push(doc.data().propCode);
      });

      // Fetch based on AdminEmail
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        // Avoid duplicates
        const propCode = doc.data().propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
        }
      });

      setPropertyCodes(codes);
    };

    fetchPropertyCodes();
  }, [user]);

  useEffect(() => {
    const q = query(
      collection(db, "empRegister"),
      where("PropID", "==", selectedPropertyCode)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employees = snapshot.docs.map((doc) => ({
        id: doc.id,
        EmployeeId: doc.data().EmpID,
        FirstName: doc.data().NameFirst,
        LastName: doc.data().NameLast,
        Phone: doc.data().AdPhone,
        PayD1: doc.data().PayD1,
        PayD1_Rate: doc.data().PayD1_Rate,
        PayD1_Type: doc.data().PayD1_Type,
        PayD2: doc.data().PayD2,
        PayD2_Rate: doc.data().PayD2_Rate,
        PayD2_Type: doc.data().PayD2_Type,
        Status: doc.data().Status,
        PRR: doc.data().PRR,
        Email: doc.data().Email,
      }));

      setAllEmployees(employees);
    });

    return unsubscribe;
  }, [selectedPropertyCode]);

  const toDate = (value) => {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    // If value is not a Firebase Timestamp, assume it's a Date
    return value;
  };

  useEffect(() => {
    const fetchProperties = async () => {
      if (!selectedPropertyCode) return;

      const propertiesDoc = await getDoc(
        doc(db, "Properties", selectedPropertyCode)
      );
      const { payPeriod, payCycle } = propertiesDoc.data();
      const currentDate = new Date();
      let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
      let payPeriodEndDate;

      setPayCycle(payCycle);

      switch (payCycle) {
        case "Monthly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setMonth(payPeriodEndDate.getMonth() + 1);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Bi-Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 14);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 7);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        default:
          break;
      }

      setPayPeriodStart(payPeriodStartDate);
      setPayPeriodEnd(payPeriodEndDate);
    };

    fetchProperties();
  }, [selectedPropertyCode]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "empRegister"),
        where("PropID", "==", selectedPropertyCode)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const employees = snapshot.docs.map((doc) => ({
          id: doc.id,
          EmployeeId: doc.data().EmpID,
          FirstName: doc.data().NameFirst,
          LastName: doc.data().NameLast,
        }));

        setEmployeeList(employees);

        if (employees.length > 0) {
          setEmployeeName(`${employees[0].FirstName} ${employees[0].LastName}`);
        } else {
          setEmployeeName("");
        }
      });

      return unsubscribe;
    }
  }, [selectedPropertyCode]);

  useEffect(() => {
    if (payPeriodStart && payPeriodEnd) {
      const q = query(
        collection(db, "PUNCHES"),
        where("PropCode", "==", selectedPropertyCode)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setEmployees([]);

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          const inTime = data.InTime.toDate();
          if (inTime >= payPeriodStart && inTime <= payPeriodEnd) {
            setEmployees((prevEmployees) => {
              const existingEmployee = prevEmployees.find(
                (employee) => employee.id === doc.id
              );

              if (existingEmployee) {
                return prevEmployees.map((employee) =>
                  employee.id === doc.id
                    ? {
                        id: doc.id,
                        ...data,
                        isEditable:
                          data.Status === "Open" ||
                          data.Status === "Manual" ||
                          data.Status === "Modified",
                      }
                    : employee
                );
              } else {
                return [
                  ...prevEmployees,
                  {
                    id: doc.id,
                    ...data,
                    isEditable:
                      data.Status === "Open" ||
                      data.Status === "Manual" ||
                      data.Status === "Modified",
                  },
                ];
              }
            });
          }
        });
      });

      return unsubscribe;
    }
  }, [user, payPeriodStart, payPeriodEnd, selectedPropertyCode]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "payRoll"),
        where("PropertyCode", "==", selectedPropertyCode),
        orderBy("PeriodEndDate", "desc")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPayRollData = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        setPayRollData(fetchedPayRollData);

        // Calculate average "Time" value and "Rooms" value for each employee
        let averageTimes = {};
        let averageRooms = {};
        const currentYear = new Date().getFullYear();
        fetchedPayRollData.forEach((doc) => {
          if (
            doc.PeriodEndDate?.toDate().getFullYear() === currentYear &&
            doc.Status !== "Void"
          ) {
            // Calculate average time
            const timeParts = doc.Totals?.Time.split(" ");
            const hours = timeParts[0] ? parseInt(timeParts[0]) : 0;
            const minutes = timeParts[2] ? parseInt(timeParts[2]) : 0;
            const totalMinutes = hours * 60 + minutes;

            if (doc.EmployeeId in averageTimes) {
              averageTimes[doc.EmployeeId].push(totalMinutes);
            } else {
              averageTimes[doc.EmployeeId] = [totalMinutes];
            }

            // Calculate average rooms
            const rooms = doc.Totals?.Rooms;
            if (doc.EmployeeId in averageRooms) {
              averageRooms[doc.EmployeeId].push(rooms);
            } else {
              averageRooms[doc.EmployeeId] = [rooms];
            }
          }
        });

        // Compute the average and convert back to "Hh Mm" format for time
        for (let employeeId in averageTimes) {
          const averageMinutes =
            averageTimes[employeeId].reduce((a, b) => a + b, 0) /
            averageTimes[employeeId].length;
          const averageHours = Math.floor(averageMinutes / 60);
          const remainingMinutes = Math.round(averageMinutes % 60);
          averageTimes[employeeId] = `${averageHours}h ${remainingMinutes}m`;
        }

        // Compute the average for rooms
        for (let employeeId in averageRooms) {
          const average =
            averageRooms[employeeId].reduce((a, b) => a + b, 0) /
            averageRooms[employeeId].length;
          averageRooms[employeeId] = Math.round(average);
        }

        setAverageTimes(averageTimes);
        setAverageRooms(averageRooms);
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode]);

  const getTimeDifference = (inTime, outTime) => {
    const difference = outTime - inTime;
    return difference;
  };

  const formatTimeDifference = (difference) => {
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  const differenceToDecimal = (difference) => {
    const hours = difference / (1000 * 60 * 60);
    return hours.toFixed(2);
  };

  const calculateEarningsPerHour = (
    payType,
    payRate,
    employee,
    employeeData
  ) => {
    if (payType !== "PER ROOM" || !employeeData) {
      return 0;
    }

    const aEarnings = (employee.A || 0) * (employeeData.PayD1_Rate || 0);
    const bEarnings = (employee.B || 0) * (employeeData.PR2 || 0);
    const cEarnings = (employee.C || 0) * (employeeData.PR3 || 0);
    const totalEarnings = aEarnings + bEarnings + cEarnings;

    const inTime =
      employee.InTime instanceof Timestamp
        ? employee.InTime.toDate()
        : employee.InTime;
    const outTime =
      employee.OutTime instanceof Timestamp
        ? employee.OutTime.toDate()
        : employee.OutTime;

    const totalTimeDecimal = differenceToDecimal(
      getTimeDifference(inTime, outTime)
    );

    return totalTimeDecimal > 0 ? totalEarnings / totalTimeDecimal : 0;
  };

  const groupByWeek = (punchesList, payPeriodStart) => {
    const payPeriodStartMoment = moment(payPeriodStart);

    return punchesList.reduce((acc, punch) => {
      const punchDate = punch.InTime.toDate();
      const punchMoment = moment(punchDate);
      const weeksDifference = punchMoment.diff(payPeriodStartMoment, "weeks");
      const weekNumber = 1 + weeksDifference;

      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }
      acc[weekNumber].push(punch);
      return acc;
    }, {});
  };

  function groupEmployeesOfEmployeeByWeek(employeesOfEmployee, payPeriodStart) {
    return employeesOfEmployee.reduce((acc, employee) => {
      const inTimeMoment = moment(employee.InTime.toDate());
      const weekNumber = inTimeMoment.diff(payPeriodStart, "weeks");

      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }

      acc[weekNumber].push(employee);

      return acc;
    }, {});
  }

  const calculateTotalDecimalWithoutPayTypeRestriction = (
    punches,
    payPeriodStart
  ) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce((weekTotal, punch) => {
        if (!punch.InTime || !punch.OutTime) {
          console.error("InTime or OutTime is null or undefined:", punch);
          return weekTotal;
        }
        const inTime = toDate(punch.InTime);
        const outTime = toDate(punch.OutTime);
        const hours = Number(
          differenceToDecimal(getTimeDifference(inTime, outTime))
        );
        return weekTotal + hours;
      }, 0);
      return weeklyTotal + weeklyHours;
    }, 0);
  };

  const calculateOvertimeHours = (decimalHoursWorked, holidayHours) => {
    const standardWorkWeekHours = 40;
    decimalHoursWorked =
      typeof decimalHoursWorked === "number" ? decimalHoursWorked : 0;
    holidayHours = typeof holidayHours === "number" ? holidayHours : 0;
    const effectiveWorkHours =
      decimalHoursWorked > 40
        ? decimalHoursWorked - holidayHours
        : decimalHoursWorked;

    const overtimeHours = Math.max(
      0,
      effectiveWorkHours - standardWorkWeekHours
    );

    return overtimeHours;
  };

  const calculateOvertimeForEmployees = (employees, payPeriodStart) => {
    const employeeIds = [
      ...new Set(employees.map((employee) => employee.EmployeeId)),
    ];

    return employeeIds.reduce((totalOvertime, employeeId) => {
      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId
      );

      // Group the punches by week
      const punchesByWeek = groupByWeek(employeePunches, payPeriodStart);

      // Calculate the total overtime for each week
      const totalOvertimeForEmployee = Object.values(punchesByWeek).reduce(
        (total, weekPunches) => {
          const weeklyTotalDecimal =
            calculateTotalDecimalWithoutPayTypeRestriction(
              weekPunches,
              payPeriodStart
            );
          const weeklyHolidayHours =
            calculateTotalHolidayHoursForEmployees(weekPunches);
          const weeklyOvertime = calculateOvertimeHours(
            weeklyTotalDecimal,
            weeklyHolidayHours
          );
          return total + weeklyOvertime;
        },
        0
      );

      return totalOvertime + totalOvertimeForEmployee;
    }, 0);
  };

  const calculateRegularHours = (punches) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce((weekTotal, punch) => {
        if (punch.PayType === "PER HOUR") {
          let inTime =
            punch.InTime instanceof Date
              ? punch.InTime
              : punch.InTime?.toDate();
          let outTime =
            punch.OutTime instanceof Date
              ? punch.OutTime
              : punch.OutTime?.toDate();

          if (inTime && outTime) {
            return (
              weekTotal +
              Number(differenceToDecimal(getTimeDifference(inTime, outTime)))
            );
          }
        }
        return weekTotal;
      }, 0);

      // Always subtract holiday hours from weekly hours
      const weeklyHolidayHours =
        calculateTotalHolidayHoursForEmployees(punchesInWeek);
      const regularHours = weeklyHours - weeklyHolidayHours;

      return weeklyTotal + Math.min(40, Math.max(0, regularHours));
    }, 0);
  };

  const calculateRegularHoursForEmployees = (employees) => {
    const employeeIds = [
      ...new Set(employees.map((employee) => employee.EmployeeId)),
    ];

    return employeeIds.reduce((total, employeeId) => {
      const employeePunches = employees.filter(
        (employee) => employee.EmployeeId === employeeId
      );
      const employeeRegularHours = calculateRegularHours(employeePunches);

      return total + Number(employeeRegularHours);
    }, 0);
  };

  const changePayPeriod = (direction) => {
    let newPayPeriodStart = new Date(payPeriodStart);
    let newPayPeriodEnd = new Date(payPeriodEnd);

    switch (payCycle) {
      case "Monthly":
        if (direction === "left") {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() - 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() - 1);
        } else {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() + 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() + 1);
        }
        break;
      case "Bi-Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 14);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 14);
        }
        break;
      case "Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 7);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 7);
        }
        break;
      default:
        break;
    }

    setPayPeriodStart(newPayPeriodStart);
    setPayPeriodEnd(newPayPeriodEnd);
  };

  const formatDate = (date) => {
    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const day = dayNames[date.getDay()];
    const dayOfMonth = date.getDate();
    return `${dayOfMonth} ${day}`;
  };

  const formatDateTime = (date) => {
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const calculateTotalPTOForEmployees = (employees) => {
    return employees.reduce(
      (total, employee) =>
        total + (employee.PTO ? parseFloat(employee.PTO) : 0),
      0
    );
  };

  function calculateTotalHolidayHoursForEmployees(employees) {
    return employees.reduce(
      (total, employee) =>
        total + (employee.HolidayHours ? parseFloat(employee.HolidayHours) : 0),
      0
    );
  }

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const querySnapshot = await getDocs(collection(db, "empRegister"));
      const employeesData = [];

      querySnapshot.forEach((doc) => {
        employeesData.push({
          id: doc.id,
          ...doc.data(),
          PR2: doc.data().PR2,
          PR3: doc.data().PR3,
          PayD1_Rate: doc.data().PayD1_Rate,
        });
      });

      setEmployeeData(employeesData);

      const departments = employeesData.map((employee) => [
        employee.PayD1,
        employee.PayD2,
      ]);

      setDepartments(departments);
    };

    fetchEmployeeData();
  }, []);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const isPayPeriodEndGreaterThanCurrent = new Date(payPeriodEnd) > currentDate;

  const groupedEmployeesByEmployee = employees.reduce((groups, employee) => {
    const employeeId = employee.EmployeeId;
    if (!groups[employeeId]) {
      groups[employeeId] = [];
    }
    groups[employeeId].push(employee);
    return groups;
  }, {});

  // PDF STYLESHEET
  const styles = StyleSheet.create({
    page: { flexDirection: "row", backgroundColor: "#ffffff", padding: 9 },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 0.5,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableCol: { width: "7%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 3, fontSize: 9 },
    strikethrough: {
      textDecoration: "line-through",
    },
    tableHead: { margin: 3, fontSize: 11, fontWeight: "bold" },
  });

  // TIMECARD PUNCH EXPORT:
  const payPeriodEndDisplay = new Date(payPeriodEnd);
  payPeriodEndDisplay.setDate(payPeriodEndDisplay.getDate() - 1);
  const EmployeeDataDocument = ({
    groupedEmployeesByEmployee,
    employeeList,
  }) => (
    <Document>
      {Object.entries(groupedEmployeesByEmployee)
        .sort(([, employeesA], [, employeesB]) =>
          employeesA[0]?.FirstName.localeCompare(employeesB[0]?.FirstName)
        )

        .map(([employeeId, employeesOfEmployee]) => {
          const employeeDetails = employeeList.find(
            (emp) => emp.EmployeeId === employeeId
          );

          // Check if at least one department of the employee is selected
          const hasSelectedDepartment = employeesOfEmployee.some(
            (employee) =>
              selectedDepartments[employee.Department] ||
              (employee.Department === "HOUSEKEEPING /HR" &&
                selectedDepartments["HOUSEKEEPING"])
          );

          // If no selected department for this employee, skip rendering
          if (!hasSelectedDepartment) {
            return null;
          }

          // Identify missing departments
          const allDepartments = [
            ...new Set(
              employeesOfEmployee.map((employee) => employee.Department)
            ),
          ];
          const missingDepartments = allDepartments.filter(
            (dept) =>
              !selectedDepartments[dept] &&
              !(
                dept === "HOUSEKEEPING /HR" &&
                selectedDepartments["HOUSEKEEPING"]
              )
          );

          // Group employees of the employee by week using the original array
          const groupedEmployeesOfEmployeeByWeek =
            groupEmployeesOfEmployeeByWeek(employeesOfEmployee, payPeriodStart);

          return (
            <Page
              size="A4"
              orientation="landscape"
              style={styles.page}
              key={employeeId}
            >
              <View style={styles.section}>
                <Text>{`${employeeDetails.FirstName} ${
                  employeeDetails.LastName
                } - PAY PERIOD ENDING: ${payPeriodEndDisplay.toLocaleDateString()}`}</Text>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}></Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>DATE</Text>
                  </View>
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableHead}>PAY TYPE</Text>
                  </View>
                  <View style={styles.tableColWider}>
                    <Text style={styles.tableHead}>DEPARTMENT</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>IN</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>OUT</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>ROOMS</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>TIME</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>TIPS</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>VAC</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>HOLIDAY</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>TOTAL TIME</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>STATUS</Text>
                  </View>
                </View>

                {missingDepartments.length > 0 && (
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>
                      Filter + {missingDepartments.join(", ")}
                    </Text>
                  </View>
                )}

                {employeesOfEmployee
                  .sort((a, b) => toDate(a.InTime) - toDate(b.InTime))
                  .map((employee, index) => {
                    const {
                      InTime,
                      Department,
                      PTO,
                      Rooms,
                      Tips,
                      PayType,
                      Status,
                      PayRate,
                    } = employee;

                    // Get week number
                    const inTimeMoment = moment(toDate(employee.InTime));
                    const weekNumber = inTimeMoment.diff(
                      payPeriodStart,
                      "weeks"
                    );
                    // Get employees of this employee for the current week
                    const employeesOfEmployeeInWeek =
                      groupedEmployeesOfEmployeeByWeek[weekNumber] || [];
                    // Find the index of the current employee in this week
                    const weeklyIndex = employeesOfEmployeeInWeek.findIndex(
                      (e) => e.id === employee.id
                    );
                    // Calculate cumulative total hours in the context of the current week
                    const cumulativeTotalHours = calculateCumulativeHours(
                      employeesOfEmployeeInWeek,
                      weeklyIndex
                    );

                    function calculateCumulativeHours(employees, index) {
                      return employees
                        .slice(0, index + 1)
                        .reduce((totalHours, currentEmployee) => {
                          const inTime = toDate(currentEmployee.InTime);
                          const outTime = toDate(currentEmployee.OutTime);
                          const timeDifference = getTimeDifference(
                            inTime,
                            outTime
                          );
                          const decimalHours = parseFloat(
                            differenceToDecimal(timeDifference)
                          );

                          const newTotalHours =
                            parseFloat(totalHours) + (decimalHours || 0);

                          return newTotalHours;
                        }, 0);
                    }

                    const inTime = toDate(InTime);
                    const outTime = toDate(employee.OutTime);
                    const timeDifference = getTimeDifference(inTime, outTime);
                    const ptoValue = PTO ? parseInt(PTO, 10) : null;
                    const totalTime = ptoValue ? ptoValue : timeDifference;

                    const ratePerHour = calculateEarningsPerHour(
                      PayType,
                      PayRate,
                      employee,
                      employeeData
                    );

                    const employeesForId =
                      groupedEmployeesByEmployee[employee.EmployeeId];

                    const sortedEmployees = employeesForId.sort((a, b) => {
                      return toDate(a.InTime) - toDate(b.InTime);
                    });

                    return (
                      <View style={styles.tableRow} key={employee.id}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatDate(toDate(InTime))}
                          </Text>
                        </View>
                        <View style={styles.tableColWide}>
                          <Text style={styles.tableCell}>{PayType}</Text>
                        </View>
                        <View style={styles.tableColWider}>
                          <Text style={styles.tableCell}>{Department}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatDateTime(toDate(employee.InTime))}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatDateTime(toDate(employee.OutTime))}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {Rooms !== 0 ? Rooms : ""}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {formatTimeDifference(timeDifference)}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {Tips !== 0 ? Tips : ""}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {PTO !== 0 ? PTO : ""}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {employee.Holiday ? "Yes" : "No"}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {cumulativeTotalHours.toFixed(2)}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{Status}</Text>
                        </View>
                      </View>
                    );
                  })}

                {/* Totals header */}
                <View style={styles.tableRow}>
                  <View style={styles.tableColWider}>
                    <Text style={styles.tableHead}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Total Time</Text>
                  </View>
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableHead}>Decimal Hours</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Overtime</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Vacation Hours</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Holiday Pay</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Tips</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Total Rooms</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Rms A</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Rms B</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableHead}>Rms C</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColWider}>
                    <Text style={styles.tableCell}>TIMECARD TOTAL:</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {formatTimeDifference(
                        employeesOfEmployee.reduce(
                          (total, employee) =>
                            total +
                            getTimeDifference(
                              toDate(employee.InTime),
                              toDate(employee.OutTime)
                            ),
                          0
                        )
                      )}
                    </Text>
                  </View>
                  {/* DECIMAL */}
                  <View style={styles.tableColWide}>
                    <Text style={styles.tableCell}>
                      {parseFloat(
                        calculateRegularHoursForEmployees(employeesOfEmployee)
                      ).toFixed(2)}
                    </Text>
                  </View>
                  {/* OVERTIME */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {calculateOvertimeForEmployees(
                        employeesOfEmployee,
                        payPeriodStart
                      ).toFixed(2)}
                    </Text>
                  </View>
                  {/* PTO */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {calculateTotalPTOForEmployees(employeesOfEmployee)}
                    </Text>
                  </View>
                  {/* HOLIDAY */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {parseFloat(
                        calculateTotalHolidayHoursForEmployees(
                          employeesOfEmployee
                        )
                      ).toFixed(2)}
                    </Text>
                  </View>
                  {/* TIPS */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.Tips,
                        0
                      )}
                    </Text>
                  </View>
                  {/* RMS */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.Rooms,
                        0
                      )}
                    </Text>
                  </View>
                  {/* A */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.A,
                        0
                      )}
                    </Text>
                  </View>
                  {/* B */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.B,
                        0
                      )}
                    </Text>
                  </View>
                  {/* C */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {employeesOfEmployee.reduce(
                        (total, employee) => total + employee.C,
                        0
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          );
        })}
    </Document>
  );

  const handlePropertyCodeChange = (event) => {
    setSelectedPropertyCode(event.target.value);
  };

  const getMissedPunches = async (propertyCode) => {
    const missedPunchesQuery = query(
      collection(db, "missedPunches"),
      where("propCode", "==", propertyCode)
    );
    const missedPunchesSnapshot = await getDocs(missedPunchesQuery);
    let fetchedMissedPunches = missedPunchesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Sort missed punches by FirstName
    fetchedMissedPunches.sort((a, b) => {
      let nameA = a.FirstName.toUpperCase(); // Ignore upper and lowercase
      let nameB = b.FirstName.toUpperCase(); // Ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // Names must be equal
      return 0;
    });

    setMissedPunches(fetchedMissedPunches);
  };

  useEffect(() => {
    if (selectedPropertyCode) {
      getMissedPunches(selectedPropertyCode);
    }
  }, [selectedPropertyCode]);

  let calculatedDate;
  if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
    let endDate = payRollData[currentPayRollIndex].PeriodEndDate?.toDate();
    endDate.setDate(endDate.getDate() - 1); // subtract one day
    calculatedDate = endDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  const getUniqueDates = (data) => {
    const uniqueDates = new Set();
    data.forEach((item) => {
      let endDate = new Date(item.PeriodEndDate.toDate());
      endDate.setDate(endDate.getDate()); // This seems unnecessary unless you're adjusting the date
      uniqueDates.add(endDate.toISOString().split("T")[0]); // Store date as ISO string
    });
    return Array.from(uniqueDates).sort((a, b) => new Date(b) - new Date(a)); // Sort descending
  };

  const uniqueDates = getUniqueDates(payRollData);

  useEffect(() => {
    if (selectedDate && selectedPropertyCode) {
      const fetchPayrollChanges = async () => {
        // Log the initial selectedDate and selectedPropertyCode
        console.log("Selected Date:", selectedDate);
        console.log("Selected Property Code:", selectedPropertyCode);

        // Convert the selectedDate string to a Date object
        const dateObj = new Date(selectedDate);
        // Format the date to MM-dd-yyyy without changing the date
        const formattedDate = dateObj.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });

        // Log the transformed date
        console.log("Transformed Date:", formattedDate);

        const payrollChangesQuery = query(
          collection(db, "payrollChanges"),
          where("payPeriod", "==", formattedDate),
          where("propCode", "==", selectedPropertyCode)
        );
        const querySnapshot = await getDocs(payrollChangesQuery);
        const changes = [];
        querySnapshot.forEach((doc) => {
          console.log("Fetched Change:", doc.data());
          changes.push(doc.data());
        });
        setPayrollChanges(changes);
      };

      fetchPayrollChanges();
    }
  }, [selectedDate, selectedPropertyCode]);

  useEffect(() => {
    const dates = getUniqueDates(payRollData);
    if (dates.length > 0) {
      setSelectedDate(dates[0]); // Set to the most recent date
    }
  }, [payRollData]);

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  return (
    <div>
      {/* HEADER */}
      <Grid
        container
        alignItems="center"
        sx={{
          padding: "2rem",
          alignItems: "center",
        }}
      >
        {/*<Grid*/}
        {/*  item*/}
        {/*  xs={12}*/}
        {/*  sm={4}*/}
        {/*  sx={{*/}
        {/*    paddingTop: { xs: "1rem", sm: 0 },*/}
        {/*    paddingBottom: { xs: "1rem", sm: 0 },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    sx={{*/}
        {/*      ...sharedTextStyles,*/}
        {/*      marginRight: 4.5,*/}
        {/*      letterSpacing: "0.45rem",*/}
        {/*      fontWeight: "300",*/}
        {/*      fontSize: "1.71rem",*/}
        {/*      color: "#202426",*/}
        {/*    }}*/}
        {/*    variant="body1"*/}
        {/*    align="left"*/}
        {/*  >*/}
        {/*    TIMECARDS*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: { xs: "1rem", sm: 0 },
            paddingBottom: { xs: "1rem", sm: 0 },
          }}
        >
          <FormControl
            sx={{
              minWidth: "17.1rem",
              "@media (max-width: 100%)": {
                minWidth: "12rem",
              },
            }}
          >
            <InputLabel
              htmlFor="property-code"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 600,
                color: "#101010",
                marginBottom: "0.2rem",
                transition: "0.3s",
              }}
            >
              PROP CODE
            </InputLabel>
            <Select
              value={selectedPropertyCode}
              onChange={handlePropertyCodeChange}
              inputProps={{
                name: "property-code",
                id: "property-code",
              }}
              sx={{
                fontSize: "0.9rem",
                backgroundColor: "#fcfcfc",
                minWidth: "10em",
                borderRadius: "27px",
              }}
            >
              {propertyCodes.map((code) => (
                <MenuItem key={code} value={code} sx={{ fontSize: "0.9rem" }}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: { xs: "1rem", sm: 0 },
            paddingBottom: { xs: "1rem", sm: 0 },
          }}
        >
          {payPeriodStart && payPeriodEnd && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <IconButton
                  onClick={() => changePayPeriod("left")}
                  sx={{
                    fontSize: "1.2rem",
                    color: "#101010",
                    transition: "0.3s",
                    marginBottom: "9px",
                  }}
                >
                  <FaChevronLeft />
                </IconButton>
              </Grid>
              <Grid item>
                <header
                  className="pay-period"
                  style={{ color: "#101010", marginBottom: "9px" }}
                >
                  {(() => {
                    const payPeriodEndDisplay = new Date(payPeriodEnd);
                    payPeriodEndDisplay.setDate(
                      payPeriodEndDisplay.getDate() - 1
                    );
                    return `${payPeriodStart.toLocaleDateString()}  -  ${payPeriodEndDisplay.toLocaleDateString()}`;
                  })()}
                </header>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => changePayPeriod("right")}
                  disabled={isPayPeriodEndGreaterThanCurrent}
                  sx={{
                    fontSize: "1.2rem",
                    color: "#101010",
                    transition: "0.3s",
                    marginBottom: "9px",
                  }}
                >
                  <FaChevronRight />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Box height={16} />

      <Card
        sx={{
          borderRadius: "18px",
        }}
      >
        <Box height={16} />

        {/* TIMECARDS */}

        <Box>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
          >
            <Grid item xs={12}>
              <PDFDownloadLink
                document={
                  <EmployeeDataDocument
                    groupedEmployeesByEmployee={groupedEmployeesByEmployee}
                    employeeList={employeeList}
                  />
                }
                fileName={`${payPeriodEndDisplay.toLocaleDateString()}_TIMECARDS_${selectedPropertyCode}.pdf`}
              >
                {() => (
                  <IconButton
                    sx={{
                      fontSize: "1.5rem",
                      color: "#FF0000",
                      backgroundColor: "#fcfcfc",
                      padding: "6px",
                      margin: "0 9px",
                      "&:first-of-type": {
                        marginLeft: "27px",
                        marginBottom: "7.2px",
                      },
                    }}
                  >
                    <FaFilePdf />
                  </IconButton>
                )}
              </PDFDownloadLink>
              <Typography
                sx={{
                  fontWeight: "800",
                  fontSize: "0.72rem",
                  color: "#010101",
                }}
                variant="caption"
              >
                EXPORT TO PDF
              </Typography>
            </Grid>
          </Grid>

          <Autocomplete
            multiple
            options={departmentNames}
            value={Object.keys(selectedDepartments).filter(
              (key) => selectedDepartments[key]
            )}
            onChange={handleDepartmentChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    margin: "2px",
                    backgroundColor: "#74c365",
                    color: "white",
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Departments"
                sx={{ minWidth: "300px" }}
              />
            )}
            sx={{ margin: "20px" }}
          />

          <TableContainer
            sx={{
              overflowX: "auto",
            }}
            component={Paper}
          >
            {/* MAIN TABLE */}
            <Table id="PAYROLL" sx={maintable}>
              <React.Fragment>
                {Object.entries(groupedEmployeesByEmployee)

                  .sort(([, employeesA], [, employeesB]) =>
                    employeesA[0]?.FirstName.localeCompare(
                      employeesB[0]?.FirstName
                    )
                  )
                  .map(([employeeId, employeesOfEmployee]) => {
                    const employeeDetails = employeeList.find(
                      (emp) => emp.EmployeeId === employeeId
                    );

                    // Check if at least one department of the employee is selected
                    const hasSelectedDepartment = employeesOfEmployee.some(
                      (employee) =>
                        selectedDepartments[employee.Department] ||
                        (employee.Department === "HOUSEKEEPING /HR" &&
                          selectedDepartments["HOUSEKEEPING"])
                    );

                    // If no selected department for this employee, skip rendering
                    if (!hasSelectedDepartment) {
                      return null;
                    }

                    // Identify missing departments
                    const allDepartments = [
                      ...new Set(
                        employeesOfEmployee.map(
                          (employee) => employee.Department
                        )
                      ),
                    ];
                    const missingDepartments = allDepartments.filter(
                      (dept) =>
                        !selectedDepartments[dept] &&
                        !(
                          dept === "HOUSEKEEPING /HR" &&
                          selectedDepartments["HOUSEKEEPING"]
                        )
                    );

                    // Group employees of the employee by week using the original array
                    const groupedEmployeesOfEmployeeByWeek =
                      groupEmployeesOfEmployeeByWeek(
                        employeesOfEmployee,
                        payPeriodStart
                      );

                    return (
                      <React.Fragment key={`employee-${employeeId}`}>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{ fontWeight: "bold" }}
                            >
                              {employeeDetails
                                ? `${employeeDetails.FirstName} ${employeeDetails.LastName}`
                                : ""}
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              IN
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              OUT
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              RMS
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              TIME
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              TIPS
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              VAC
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              HOL
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              TOTAL
                            </TableCell>
                            <TableCell sx={maintable} align="left">
                              STATUS
                            </TableCell>
                          </TableRow>

                          {missingDepartments.length > 0 && (
                            <TableRow>
                              <TableCell colSpan={12} style={{ color: "red" }}>
                                Filter + {missingDepartments.join(", ")}
                              </TableCell>
                            </TableRow>
                          )}

                          {employeesOfEmployee
                            .sort((a, b) => toDate(a.InTime) - toDate(b.InTime))
                            .map((employee, index) => {
                              const {
                                InTime,
                                Department,
                                PTO,
                                Rooms,
                                Tips,
                                PayType,
                                Status,
                                PayRate,
                              } = employee;

                              // Get week number
                              const inTimeMoment = moment(
                                toDate(employee.InTime)
                              );
                              const weekNumber = inTimeMoment.diff(
                                payPeriodStart,
                                "weeks"
                              );
                              // Get employees of this employee for the current week
                              const employeesOfEmployeeInWeek =
                                groupedEmployeesOfEmployeeByWeek[weekNumber] ||
                                [];
                              // Find the index of the current employee in this week
                              const weeklyIndex =
                                employeesOfEmployeeInWeek.findIndex(
                                  (e) => e.id === employee.id
                                );
                              // Calculate cumulative total hours in the context of the current week
                              const cumulativeTotalHours =
                                calculateCumulativeHours(
                                  employeesOfEmployeeInWeek,
                                  weeklyIndex
                                );

                              function calculateCumulativeHours(
                                employees,
                                index
                              ) {
                                return employees
                                  .slice(0, index + 1)
                                  .reduce((totalHours, currentEmployee) => {
                                    const inTime = toDate(
                                      currentEmployee.InTime
                                    );
                                    const outTime = toDate(
                                      currentEmployee.OutTime
                                    );
                                    const timeDifference = getTimeDifference(
                                      inTime,
                                      outTime
                                    );
                                    const decimalHours = parseFloat(
                                      differenceToDecimal(timeDifference)
                                    );

                                    const newTotalHours =
                                      parseFloat(totalHours) +
                                      (decimalHours || 0);

                                    return newTotalHours;
                                  }, 0);
                              }

                              const inTime = toDate(InTime);
                              const outTime = toDate(employee.OutTime);
                              const timeDifference = getTimeDifference(
                                inTime,
                                outTime
                              );
                              const ptoValue = PTO ? parseInt(PTO, 10) : null;
                              const totalTime = ptoValue
                                ? ptoValue
                                : timeDifference;

                              const ratePerHour = calculateEarningsPerHour(
                                PayType,
                                PayRate,
                                employee,
                                employeeData
                              );

                              const employeesForId =
                                groupedEmployeesByEmployee[employee.EmployeeId];

                              const sortedEmployees = employeesForId.sort(
                                (a, b) => {
                                  return toDate(a.InTime) - toDate(b.InTime);
                                }
                              );

                              return (
                                <React.Fragment key={`employee-${employee.id}`}>
                                  <TableRow hover key={employee.id}>
                                    <TableCell
                                      sx={maintable}
                                      size="small"
                                      align="left"
                                    >
                                      {formatDate(toDate(InTime))}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {PayType !== "error" ? PayType : ""}
                                    </TableCell>
                                    {/* DEPT */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {Department}
                                    </TableCell>
                                    {/* IN TIME */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {formatDateTime(toDate(employee.InTime))}
                                    </TableCell>
                                    {/* OUT TIME */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {formatDateTime(toDate(employee.OutTime))}
                                    </TableCell>
                                    {/* ROOMS */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {Rooms !== 0 ? Rooms : ""}
                                    </TableCell>
                                    {/* TOTAL TIME WORKED */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {formatTimeDifference(timeDifference)}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {Tips !== 0 ? Tips : ""}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {PTO !== 0 ? PTO : ""}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={maintable}
                                    >
                                      {employee.Holiday ? "Yes" : "No"}
                                    </TableCell>
                                    {/* CUMULATIVE TIME */}
                                    <TableCell
                                      align="left"
                                      size="small"
                                      sx={{
                                        ...maintable,
                                        backgroundColor:
                                          cumulativeTotalHours > 40
                                            ? "#EF3340"
                                            : cumulativeTotalHours > 35
                                            ? "#FFCCCB"
                                            : "inherit",
                                        color:
                                          cumulativeTotalHours > 35
                                            ? "black"
                                            : "inherit",
                                      }}
                                    >
                                      {cumulativeTotalHours.toFixed(2)}
                                    </TableCell>
                                    {/* STATUS */}
                                    <TableCell
                                      sx={maintable}
                                      align="left"
                                      size="small"
                                    >
                                      {Status}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })}
                          {/* TOTALS */}
                          <TableRow>
                            <TableCell sx={totals} align="left" size="small">
                              TIME
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              HRS
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              OT
                            </TableCell>

                            <TableCell sx={totals} align="left" size="small">
                              VAC
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              HOL
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              TIPS
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              RMS
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              A
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              B
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              C
                            </TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            {/* TIME */}
                            <TableCell sx={totals} align="left" size="small">
                              {formatTimeDifference(
                                employeesOfEmployee.reduce(
                                  (total, employee) =>
                                    total +
                                    getTimeDifference(
                                      toDate(employee.InTime),
                                      toDate(employee.OutTime)
                                    ),
                                  0
                                )
                              )}
                            </TableCell>
                            {/* DECIMAL */}
                            <TableCell sx={totals} align="left" size="small">
                              {parseFloat(
                                calculateRegularHoursForEmployees(
                                  employeesOfEmployee
                                )
                              ).toFixed(2)}
                            </TableCell>
                            {/* OVERTIME */}
                            <TableCell sx={totals} align="left" size="small">
                              {calculateOvertimeForEmployees(
                                employeesOfEmployee,
                                payPeriodStart
                              ).toFixed(2)}
                            </TableCell>

                            {/* PTO */}
                            <TableCell sx={totals} align="left" size="small">
                              {calculateTotalPTOForEmployees(
                                employeesOfEmployee
                              )}
                            </TableCell>
                            {/* HOLIDAY */}
                            <TableCell sx={totals} align="left" size="small">
                              {parseFloat(
                                calculateTotalHolidayHoursForEmployees(
                                  employeesOfEmployee
                                )
                              ).toFixed(2)}
                            </TableCell>
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.Tips,
                                0
                              )}
                            </TableCell>
                            {/* RMS */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.Rooms,
                                0
                              )}
                            </TableCell>
                            {/* A */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.A,
                                0
                              )}
                            </TableCell>
                            {/* B */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.B,
                                0
                              )}
                            </TableCell>
                            {/* C */}
                            <TableCell sx={totals} align="left" size="small">
                              {employeesOfEmployee.reduce(
                                (total, employee) => total + employee.C,
                                0
                              )}
                            </TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                            <TableCell
                              sx={totals}
                              align="left"
                              size="small"
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            {" "}
                            <TableCell
                              sx={{ backgroundColor: "#ebefe3" }}
                              align="left"
                              size="small"
                              colSpan={12}
                            ></TableCell>
                          </TableRow>
                        </TableBody>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            </Table>
          </TableContainer>
        </Box>
      </Card>
    </div>
  );
};

export default PunchTimecards;

const maintable = {
  fontWeight: 400,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
  rowHeight: "0.1rem",
};
const totals = {
  fontWeight: 600,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
};

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};
