import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import "./components/App.css";
import Home from "./pages/home/Home";
import NightShift from "./pages/nightShift/punch";
import NsSummary from "./pages/nsSummary/summary";
import Roster from "./pages/roster/roster";
import AddRecord from "./pages/addRecord/AddRecord";
import UpdateRecord from "./pages/updateRecord/UpdateRecord";
import Nav from "./components/Nav";
import SignUp from "./pages/signup/SignUp";
import { useAuth } from "./contexts/AuthContext";
import Login from "./pages/login/Login";
import MyTime from "./pages/mytime/mytime";
import MyStubs from "./pages/mytime/myStubs";
import Apps from "./pages/apps/apps";
import Sales from "./pages/sales/sales";
import Stubs from "./pages/stubs/stubs";
import ASL from "./pages/mytime/myProfile";
import Stats from "./pages/home/stats";
import PunchTimecards from "./pages/nsSummary/timecards";

function App() {
  const { user, authStateChecked } = useAuth();

  return (
    <>
      {authStateChecked && (
        <Router>
          <Nav />
          <div className="app-container">
            <Routes>
              <Route
                path="/Stats"
                element={
                  user && user.emailVerified ? (
                    <Stats />
                  ) : (
                    <Navigate to="/Stats" replace />
                  )
                }
              />
              <Route
                path="/asl"
                element={
                  user && user.emailVerified ? (
                    <ASL />
                  ) : (
                    <Navigate to="/mytime/myProfile" replace />
                  )
                }
              />
              <Route
                path="/myStubs"
                element={
                  user && user.emailVerified ? (
                    <MyStubs />
                  ) : (
                    <Navigate to="/stubs/myStubs" replace />
                  )
                }
              />

              <Route
                path="/stubs"
                element={
                  user && user.emailVerified ? (
                    <Stubs />
                  ) : (
                    <Navigate to="/stubs" replace />
                  )
                }
              />
              <Route
                path="/punch"
                element={
                  user && user.emailVerified ? (
                    <NightShift />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/timecards"
                element={
                  user && user.emailVerified ? (
                    <PunchTimecards />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/summary"
                element={
                  user && user.emailVerified ? (
                    <NsSummary />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/Roster"
                element={
                  user && user.emailVerified ? (
                    <Roster />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/add-Record"
                element={
                  user && user.emailVerified ? (
                    <AddRecord />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/update-Record"
                element={
                  user && user.emailVerified ? (
                    <UpdateRecord />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/sales"
                element={
                  user && user.emailVerified ? (
                    <Sales />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/apps"
                element={
                  user && user.emailVerified ? (
                    <Apps />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/mytime"
                element={
                  user && user.emailVerified ? (
                    <MyTime />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/signup"
                element={!user ? <SignUp /> : <Navigate to="/" replace />}
              />
              <Route
                path="/login"
                element={!user ? <Login /> : <Navigate to="/" replace />}
              />
              <Route
                exact
                path="/"
                element={
                  user && user.emailVerified ? (
                    <Home />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            </Routes>
          </div>
        </Router>
      )}
    </>
  );
}

export default App;
